<template>
    <div>
        <nav-bar></nav-bar>
            <div class="container-fluid page-body-wrapper">
                <side-bar></side-bar>
                <!-- partial -->
                <div class="main-panel">
                    <div class="content-wrapper">
                        <div class="row align-items-center">
                            <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
                                <div class="page-header">
                                    <h4 class="page-title">Purchase Orders</h4>
                                </div>
                            </div>
                            <div class="col-lg-3 ms-auto">
                                <div class="d-md-flex d-grid gap-3 justify-content-md-end">
                                    <router-link :to="{name:'add-purchase-order'}">
                                        <button class="col-lg-12 btn btn-gradient-primary btn-fw">Create Purchase Order</button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        </br>
                        <div class="row">
                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body nw-opd">
                                        <div class="row me-1">
                                            <div class="col-lg-4">
                                                <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="search" id="search" autocomplete="off" v-on:keyup="getInventoryPurchaseOrderAPI(1)">
                                            </div>
                                            <h4 class="col-lg-3"></h4>
                                            <date-range-picker
                                                    class="col-lg-3 mb-lg-0 mb-2"
                                                    ref="picker"
                                                    :max-date="MAXDATE"
                                                    :opens="left"
                                                    :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                                    :single-date-picker="false"
                                                    :showDropdowns="showDropdowns"
                                                    :show-week-numbers= "false"
                                                    :time-picker="false"
                                                    :autoApply="true"
                                                    v-model="dateRange"
                                                    :linked-calendars="true"
                                                    :dateFormat="dd-mmm-yyyy"
                                                    @finish-selection=getInventoryPurchaseOrderAPI(1)
                                                    @update=getInventoryPurchaseOrderAPI(1)
                                            />
                                            <div class="col-lg-2 mb-lg-0 mb-2">
                                                  <multiselect
                                                      v-model="filter_status"
                                                      :options="filter_status_list"
                                                      :multiple="false"
                                                      :close-on-select="true"
                                                      :clear-on-select="false"
                                                      :preserve-search="true" placeholder=""
                                                      :preselect-first="true"
                                                      :showLabels="false"
                                                      :allowEmpty="false"
                                                      label="name"
                                                      @input=getInventoryPurchaseOrderAPI(1)
                                                  /></multiselect>
                                            </div>
                                        </div>

                                        <div class="table-responsive">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Order no.</th>
                                                        <th>Date</th>
                                                        <th>Items</th>
                                                        <th>Supplier</th>
                                                        <th>Cost (<span v-html="currency_data.currency_symbol"></span>)</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr v-for="(purchase_order,i) in purchase_orders" :key="i">
                                                        <td>{{purchase_order.order_number}}</td>

                                                        <td v-if="purchase_order && purchase_order.order_date">{{purchase_order.order_date}}</td>
                                                        <td v-else>-</td>

                                                        <td>{{getItemText(purchase_order.items)}}</td>

                                                        <td v-if="purchase_order && purchase_order.supplier">{{purchase_order.supplier.name}}</td>
                                                        <td v-else>-</td>

                                                        <td><span v-html="currency_data.currency_symbol"></span> {{purchase_order.total_cost}}</td>

                                                        <td><div v-bind:class="{
                                                                 'badge badge-info': purchase_order.status === 'pending',
                                                                 'badge badge-warning': purchase_order.status === 'ordered',
                                                                 'badge badge-success': purchase_order.status === 'received',
                                                                 'badge badge-danger': purchase_order.status === 'cancelled',
                                                                 }">{{getStatusText(purchase_order.status)}}</div></td>
                                                        <td>
                                                            <router-link :to="{name:'add-purchase-order',params:{'id':purchase_order.id}}">
                                                                <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-pencil"></i></button>
                                                            </router-link>  &nbsp;&nbsp;
                                                            <button type="button" class="btn btn-outline-secondary btn-rounded btn-icon" @click="deleteInventoryPurchaseOrderAPI(purchase_order.id, purchase_order.order_number)" ><i class="mdi mdi-delete"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div v-if="is_API_call_running" class="text-center mt-5">
                                            <b-spinner label=""></b-spinner>
                                        </div>
                                        <div class="row" v-if="!is_API_call_running && purchase_orders.length == 0" >
                                            <div class="col-lg-12 text-center">
                                                <br/><br/><br/>
                                                <small class="text-muted">{{ errorMessage }}</small>
                                                <br/><br/><br/>
                                            </div>
                                        </div>
                                        <div class="b-pagination-outer" v-if="listPages > 1">
                                            <ul id="border-pagination">
                                                <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                                                <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getInventoryPurchaseOrderAPI(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                                                <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import string from "../../constant/strings.js"
import { EventBus } from '@/event-bus';
import Swal from 'sweetalert2'
import {get_user_settings } from '../../helper/helper';
import moment from "moment";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'

export default {
    name:'purchase order',
    title: string.PAGE_TITLE_PURCHASE_ORDERS,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      DateRangePicker
    },
    data() {
        const now = new Date()
        const startdate = new Date(now.getFullYear(), now.getMonth(), 1)
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const maxDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())
        return {
            moment,
            purchase_orders:[],
            is_API_call_running: false,
            listPages:0,
            activePage:1,
            searchtext:'',
            myTimer:null,
            errorMessage : 'No Data Found.',
            filter_status_list:[{"id":"all", "name":"All"},{"id":"pending", "name":"Pending"},{"id":"ordered", "name":"Ordered"},{"id":"received", "name":"Received"},{"id":"cancelled", "name":"Cancelled"}],
            filter_status: {"id":"all", "name":"All"},
            dialogResult: null,
            currency_data:null,
            dateRange:{
                startDate: startdate,
                endDate: today
            },
            MAXDATE:maxDate,
        }
    },
    mounted(){
        this.getInventoryPurchaseOrderAPI(this.activePage);
        this.currency_data = get_user_settings()
    },
  methods:{
    ...mapActions('hospital',["getInventoryPurchaseOrder", "deleteInventoryPurchaseOrder"]),

    getInventoryPurchaseOrderAPI(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.purchase_orders = [];
        let queryParams = {
            name: this.searchtext,
            status: this.filter_status.id,
            filter_start_date: moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'),
            filter_end_date: moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'),
            page: page,
        };
        this.getInventoryPurchaseOrder(queryParams).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
                this.purchase_orders = response.data;
                this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
        });
      }, 500)
    },

    previewClick(){
          this.activePage--;
          if(this.activePage >= 1){
            this.getInventoryPurchaseOrderAPI(this.activePage);
          }else{
            this.activePage = 1;
          }
        },

    nextClick(){
       this.activePage++;
       if(this.activePage <= this.listPages){
            this.getInventoryPurchaseOrderAPI(this.activePage);
       }else{
            this.activePage = this.listPages;
       }
    },
    openEditItemPage(item) {

    },
    deleteInventoryPurchaseOrderAPI(id, item_name, index) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + item_name + string.PURCHASE_ORDER,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('purchase_order_id', id);
            this.deleteInventoryPurchaseOrder(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.purchase_orders.length;i++){
                        if(id == this.purchase_orders[i].id) {
                            this.purchase_orders.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    },
    getStatusText(status) {
        let status_text = ''
        for (let i = 0; i < this.filter_status_list.length;i++) {
            if(status == this.filter_status_list[i].id) {
                status_text = this.filter_status_list[i].name
                break
            }
        }
        return status_text
    },
    getItemText(items) {
        let item_text = ''
        for (let i = 0; i < items.length;i++) {
            if (i == (items.length - 1)) {
                item_text = item_text + items[i].item.name
            } else {
                item_text = item_text + items[i].item.name + ", "
            }
        }
        return item_text
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>