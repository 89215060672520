<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row align-items-center">
            <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
                <div class="page-header">
                    <h4 class="page-title">Inventories</h4>
                </div>
            </div>
            <div class="col-lg-3 ms-auto">
                <div class="d-md-flex d-grid gap-3 justify-content-md-end">
                    <button v-if="tabIndex == 0" class="col-lg-12 btn btn-gradient-primary btn-fw" @click=openNextScreen()>Add Item</button>
                    <button v-if="tabIndex == 1" class="col-lg-12 btn btn-gradient-primary btn-fw"@click=openNextScreen()>Add Supplier</button>
                    <button v-if="tabIndex == 2" class="col-lg-12 btn btn-gradient-primary btn-fw"@click=openNextScreen()>Add Category</button>
                </div>
            </div>
          </div>
          </br>
          <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                  <div class="row">
                    <div>
                      <b-tabs content-class="mt-1" small card v-model="tabIndex">
                        <b-tab title="Items" :title-link-class="linkClass(0)" active>
                          <InventoryItem />
                        </b-tab>
                        <b-tab title="Suppliers" :title-link-class="linkClass(1)">
                          <InventorySupplier />
                        </b-tab>
                        <b-tab title="Categories" :title-link-class="linkClass(2)">
                          <InventoryCategory />
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
    <CategoryComponent/>
    <SupplierComponent/>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import InventoryItem from "./inventory_item.vue";
import InventorySupplier from "./inventory_supplier.vue";
import InventoryCategory from "./inventory_category.vue";
import { mapActions } from "vuex";
import string from "../../constant/strings.js"
import { EventBus } from '@/event-bus';
import CategoryComponent from './components/component_category.vue';
import SupplierComponent from './components/component_supplier.vue';

export default {
  name: 'inventories',
  title: string.PAGE_TITLE_INVENTORIES,
  components: {
    NavBar,
    Footer,
    SideBar,
    InventoryItem,
    InventorySupplier,
    InventoryCategory,
    CategoryComponent,
    SupplierComponent,
  },
  data() {
    return {
        tabIndex: 0
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions("hospital", ["getRoomListData"]),
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['btn-gradient-primary m-1', 'text-light']
            } else {
                return ['btn-gradient-secondary', 'text-black']
            }
        },

        openNextScreen() {
            if(this.tabIndex == 0) {
                this.$router.push({ name: 'add-inventory-item' });
            } else if(this.tabIndex == 1) {
              this.openSupplierDialog();
            } else {
              this.openCategoryDialog();
            }
        },

        openCategoryDialog() {
            const payload = { data: '' };
            EventBus.$emit('open-dialog', payload); // Trigger the dialog opening
        },

        openSupplierDialog() {
            const payload = { data: '' };
            EventBus.$emit('open-supplier-dialog', payload); // Trigger the dialog opening
        },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>