<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <!-- Profile Section -->
      <li class="nav-item nav-profile">
        <router-link to="/admin-profile" class="nav-link">
          <div class="nav-profile-image">
            <img :src="$auth.user.profile_pic" alt="profile">
            <span class="login-status online"></span>
          </div>
          <div class="nav-profile-text d-flex flex-column">
            <span class="font-weight-bold mb-2">{{ $auth.user.name }}</span>
            <span class="text-secondary text-small">{{ $auth.user.role }}</span>
          </div>
          <i
            class="mdi mdi-bookmark-check text-success nav-profile-badge"
            v-if="$auth.user.is_client_master_user"
          ></i>
        </router-link>
      </li>

      <!-- Menu Items -->
      <li
        v-for="item in menuItems"
        :key="item.name"
        class="nav-item"
        :class="[isActiveRoute(item.routeNames), item.isExpandable ? 'expandable' : '']"
      >
        <!-- Top-level Menu -->
        <div v-if="item.isExpandable" class="nav-link" @click="toggleSubMenu(item)">
          <i :class="item.icon + ' menu-icon'"></i>
          <span class="menu-title">{{ item.title }}</span>
          <i class="menu-arrow" :class="{ rotated: item.expanded }"></i>
        </div>

        <router-link
          v-else
          :to="item.path"
          class="nav-link"
        >
          <i :class="item.icon + ' menu-icon'"></i>
          <span class="menu-title">{{ item.title }}</span>
        </router-link>

        <!-- Sub-menu -->
        <ul v-if="item.isExpandable && item.expanded" class="sub-menu nav flex-column">
          <li v-for="subItem in item.subMenu" :key="subItem.name" class="nav-item"
            :class="isActiveRoute(subItem.routeNames)">
            <router-link :to="subItem.path" class="nav-link">
              <span class="menu-title" >{{ subItem.title }}</span>
            </router-link>
          </li>
        </ul>
      </li>

      <!-- Logout Button for Mobile View -->
      <li class="nav-item nav-logout d-lg-none">
        <a class="nav-link" href="javascript:void(0)" @click="LogOut">
          <i class="mdi mdi-power menu-icon"></i>
          <span class="menu-title">Logout</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      menuItems: [
        {
          title: "Dashboard",
          path: "/",
          icon: "mdi mdi-home",
          routeNames: ["home"],
        },
        {
          title: "OPDS",
          path: "/opdslist",
          icon: "mdi mdi-book-multiple",
          routeNames: ["opdslist", "opdsadd", "opddetails"],
        },
        {
          title: "IPDS",
          path: "/ipdslist",
          icon: "mdi mdi-book-open-page-variant",
          routeNames: ["ipdslist", "ipdsadd", "ipddetails"],
        },
        {
          title: "Appointments",
          path: "/appointment",
          icon: "mdi mdi-book-plus",
          routeNames: ["appointment", "appointmentdetails"],
        },
        {
          title: "Billing",
          path: "/billings",
          icon: "mdi mdi-cash-multiple",
          routeNames: ["billings"],
        },
        {
          title: "Patients",
          path: "/patients",
          icon: "mdi mdi-account-multiple",
          routeNames: ["patients", "opdsadd", "patientsdetails"],
        },
        {
          title: "Resource Management",
          isExpandable: true,
          icon: "mdi mdi-pill",
          routeNames: ["medicines", "addmedicines", "reportslist", "reportdetails", "effectslist", "effectdetails"],
          expanded: false,
          subMenu: [
            { title: "Medicines", path: "/medicines", routeNames: ["medicines"] },
            { title: "Reports", path: "/reports", routeNames: ["reportslist", "reportdetails"] },
            { title: "Effects", path: "/effects", routeNames: ["effectslist", "effectdetails"] },
          ],
        },
        {
          title: "Facilities Management",
          isExpandable: true,
          icon: "mdi mdi-view-grid",
          routeNames: ["rooms-wards", "departmentlist", "departmentdetails"],
          expanded: false,
          subMenu: [
            { title: "Rooms/Ward", path: "/rooms-wards", routeNames: ["rooms-wards"] },
            { title: "Departments", path: "/department", routeNames: ["department"] },
          ],
        },
        {
          title: "Statistics",
          path: "/statistics",
          icon: "mdi mdi-chart-areaspline",
          routeNames: ["statistics", "statistics_type_selection"],
        },
        {
          title: "Inventories",
          isExpandable: true,
          icon: "mdi mdi-package-variant-closed",
          routeNames: ["inventory", "add-update-inventory", "add-update-supplier", "add-update-category", "stock-in-out", "purchase-orders", "maintenance-requests"],
          expanded: false,
          subMenu: [
            { title: "Inventories", path: "/inventory", routeNames: ["inventory", "add-update-inventory", "add-update-supplier", "add-update-category"] },
            { title: "Stock In/Out", path: "/stock-in-out", routeNames: ["stock-in-out"] },
            { title: "Purchase Orders", path: "/purchase-orders", routeNames: ["purchase-orders"] },
            { title: "Maintenance Requests", path: "/maintenance-requests", routeNames: ["maintenance-requests"] },
          ],
        },
        {
          title: "System Configuration",
          isExpandable: true,
          icon: "mdi mdi-settings",
          routeNames: ["cities", "addcity", "editcity", "doctor", "adddoctor", "editdoctor", "settings", "changelog"],
          expanded: false,
          subMenu: [
            { title: "Cities", path: "/cities", routeNames: ["cities", "addcity", "editcity"] },
            { title: "Users", path: "/doctor", routeNames: ["doctor", "adddoctor", "editdoctor"] },
            { title: "Settings", path: "/settings", routeNames: ["settings"] },
            { title: "Changelog", path: "/changelog", routeNames: ["changelog"] },
          ],
        },
        {
          title: "Contact Us Request",
          path: "/contact-us-request",
          icon: "mdi mdi-comment-processing",
          routeNames: ["contact-us-request"],
        },
      ],
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "/assets/js/hoverable-collapse.js";
    document.head.appendChild(script);
  },
  methods: {
    ...mapActions("hospital", ["logoutAdmin"]),

    isActiveRoute(routeNames) {
      return routeNames.includes(this.$route.name) ? "active" : "";
    },

    toggleSubMenu(item) {
      item.expanded = !item.expanded;
    },

    LogOut() {
      if (confirm("Are you sure you want to logout?")) {
        const storedDeviceId = Cookies.get("device_id");
        let logoutData = {
          device_id: storedDeviceId,
        };

        this.logoutAdmin(logoutData).then((response) => {
          if (response.response_code === 200) {
            let logoutPayload = {
              user_id: this.$auth.user.user_id,
              devicetoken: this.$auth.user.token,
              device_id: storedDeviceId,
            };

            this.$auth.logout(logoutPayload, (callback) => {
              this.$router.push({ name: callback }).catch(() => {});
            });
          } else {
            this.$toasted.error(response.message, { duration: 2000 });
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.nav-item.active .nav-link {
  color: #4caf50; /* Adjust active color */
}
.expandable .menu-arrow {
  transition: transform 0.3s ease;
}
.expandable .menu-arrow.rotated {
  transform: rotate(90deg);
}
.menu-arrow {
  transform: rotate(-90deg);
}
.sub-menu {
  padding-left: 20px;
}
</style>
