<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
    <div class="row align-items-center">
        <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
            <div class="page-header">
                <h4 class="page-title">Patients</h4>
            </div>
        </div>
        <div class="col-lg-5 ms-auto">
            <div class="d-md-flex d-grid gap-3 justify-content-md-end">
                <router-link to="/opdsadd" type="button" class="btn btn-gradient-primary btn-fw">Register Patient</router-link>
            </div>
        </div>
    </div>

    <br/>

      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body nw-opd">
                <div class="row">
                  <div class="col-lg-4 mb-lg-0 mb-2">
                     <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchpatient" id="searchpatient" autocomplete="off" v-on:keyup="getPatientsList(1)">
                  </div>
                    <div class="col-lg-3"/>
                        <date-range-picker
                            class="col-lg-3 mb-lg-0 mb-2"
                            ref="picker"
                            :max-date="MAXDATE"
                            :opens="center"
                            :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                            :single-date-picker="false"
                            :showDropdowns="showDropdowns"
                            :show-week-numbers= "false"
                            :time-picker="false"
                            :autoApply="true"
                            v-model="dateRangeRegistration"
                            :linked-calendars="true"
                            :dateFormat="dd-mmm-yyyy"
                            @finish-selection=getPatientsList(1)
                            @update=getPatientsList(1)
                        />
                        <div class="col-lg-2">
                            <multiselect
                                v-model="filter_status"
                                :options="status_list"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :preserve-search="true" placeholder=""
                                :preselect-first="true"
                                :showLabels="false"
                                :allowEmpty="false"
                                @input=getPatientsList(1)
                            /></multiselect>
                        </div>
                    </div>
                <br>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Patient Number</th>
                        <th>Patient Name</th>
                        <th>Patient Age</th>
                        <th>City</th>
                        <th>Number Of Visit</th>
                        <th>Total OPDs</th>
                        <th>OPD Renew On </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(opd,i) in patientsLists" :key="i">
                        <td>{{opd.patient_number}}</td>
                        <td>{{opd.patient_name}}</td>
                        <td>{{opd.patient_age}}</td>
                        <td>{{opd.patient_city}}</td>
                        <td>{{opd.patient_number_of_visit}}</td>
                        <td>{{opd.patient_number_of_visit}}</td>
                        <td>{{opd.patient_opd_re_newed_on}}</td>
                        <td><router-link :to="{name:'patientsdetails',params:{'pid':opd.patient_number}}">
                        <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button>
                        </router-link>&nbsp;&nbsp;
                        <router-link :to="{name:'opdsadd',params:{'id':opd.patient_number}}">
                        <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-tooltip-edit"></i></button></router-link> &nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && patientsLists.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage}}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a class="" href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getPatientsList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>                                        
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul> 
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions, mapGetters } from "vuex";
import DateRangePicker from 'vue2-daterange-picker'
import Multiselect from 'vue-multiselect';
import moment from "moment";
import string from "../constant/strings.js"

export default {
  name:'OPDS',
  title: string.PAGE_TITLE_PATIENTS,
  components: {
      NavBar,
Footer,
      SideBar,
      DateRangePicker,
      Multiselect
  },
  data() {
    const now = new Date()
    const startdate = new Date(now.getFullYear(), now.getMonth(), 1)
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  	const maxDate = new Date(today)
    return {
      patientsLists:[],
      listPages:0,
      activePage:1,
      is_API_call_running:false,
      searchtext:'',
      myTimer:null,
      errorMessage:'No Data Found.',
      status_list:['All','Active', 'Inactive'],
      filter_status: "All",
      dateRangeRegistration:{
        startDate: startdate,
        endDate: today
      },
      moment,
      MAXDATE:maxDate,
    }
  },
  mounted() {
    this.getPatientsList(this.activePage);
  },
  methods:{
    ...mapActions("hospital",["getPatientsListData"]),

    getPatientsList(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
          this.patientsLists = [];
          var bodyFormData = new FormData();
          bodyFormData.append('filter_value', this.searchtext);
          bodyFormData.append('page', page);
          bodyFormData.append('filter_status', this.filter_status);
          bodyFormData.append('filter_from_date', moment(String(this.dateRangeRegistration.startDate)).format('YYYY-MM-DD'));
          bodyFormData.append('filter_to_date', moment(String(this.dateRangeRegistration.endDate)).format('YYYY-MM-DD'));
          this.getPatientsListData(bodyFormData).then((response) => {
            this.is_API_call_running = false;
            if (response.response_code == 200) {
              this.patientsLists = response.data;
              this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
          });
      }, 500)
    },
    previewClick(){
      this.activePage--;
      if(this.activePage >= 1){
        this.getPatientsList(this.activePage);
      }else{        
        this.activePage = 1;        
      }      
    },
    nextClick(){
      this.activePage++;
      if(this.activePage <= this.listPages){
        this.getPatientsList(this.activePage);
      }else{
        this.activePage = this.listPages;        
      }      
    }

  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
