<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="row">
                <h4 class="page-header page-title  mb-md-0">Patients</h4>
            </div>
            <div class="row">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb p-0">
                    <li class="breadcrumb-item"><router-link to="/patients">Patients List</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ this.$route.params.pid }}</li>
                  </ol>
                </nav>
            </div>
          </div>
        </div>
      </br>
      <div class="row">
        <div class="col-12">
          <h3 class="card-title">Patient Details</h3>
          <form class="form-sample" v-if="personalData">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group row">              
                        <div class="col-sm-12" >
                          <label class="col-form-label">Patient Name</label>
                          <input type="text" class="form-control" :value="personalData && personalData.patient_name" name="patient_name" placeholder="Patient Name" disabled /> 
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient Mobile</label>
                          <input type="text" class="form-control" :value="personalData && personalData.patient_mobile_number"  name="patient_mobile" placeholder="Patient Mobile" disabled />                        
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient Age</label>
                          <input type="text" class="form-control" :value="personalData && personalData.patient_age" name="age" placeholder="Age" disabled />                        
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Gender</label>
                          <select class="form-control custom_height" :value="personalData && personalData.patient_gender" disabled >
                            <option disabled value="">Please select gender</option>
                            <option>Male</option>
                            <option>Female</option>
                          </select>                        
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Address</label>
                          <input type="text" class="form-control" :value="personalData && personalData.patient_address"  name="address" placeholder="Address"  disabled/>                        
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">City</label>
                          <input type="text" class="form-control" :value="personalData && personalData.patient_city" name="city" placeholder="City" disabled/>                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="card-title">Total OPDs</h4>
            <div v-for="(opd ,i) in opdLists" :key="i">
              <div class="col-md-12 grid-margin stretch-card" v-if="opdLists">
                <div class="card">
                  <div class="card-body">
                       <div class="row align-items-center mb-2">
                            <h3 class="col-lg-8 mb-0">{{moment.parseZone(opd.opd_registered_on).format('MMMM,DD YYYY hh:mm a')}}</h3>
                            <div class="col-lg-4 text-md-end">
                                <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon me-4" @click="printOPDDesc(opd.opd_id)"><i class="mdi mdi-printer"></i></button>

                                <router-link :to="{name:'prescription',params:{'id':opd.opd_id}}" v-if="opd.opd_prescription == null" class="btn btn-outline-primary btn-fw">Add Prescription</router-link>
                                <router-link :to="{name:'prescription',params:{'id':opd.opd_id}}" v-if="opd.opd_prescription != null && opd.opd_prescription.saved_as_draft =='Yes'" class="btn btn-outline-primary btn-fw">Edit Prescription</router-link>
                                <router-link :to="{name:'PrescriptionReportView',params:{'id':opd.opd_id}}" v-if="opd.opd_prescription != null && opd.opd_prescription.saved_as_draft =='No'" class="btn btn-outline-primary btn-fw" >View Prescription</router-link>
                            </div>

                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <div class="col-sm-12">
                            <label class="col-form-label">Fees</label>
                            <div class="input-group">
                              <div class="input-group-append">
                                <span class="input-group-text">₹</span>
                              </div>
                              <input type="text" class="col-sm-10 form-control" :value="opd.opd_fees" name="fees" placeholder="Fees" disabled/> 
                            </div>
                          </div>
                        </div>
                      </div>                      
                      <div class="col-md-4">
                      <div class="form-group row">
                          <div class="col-sm-12">
                          <label class="col-form-label">Disease</label>
                          <input type="text" class="form-control" :value="opd.opd_disease" name="Disease" placeholder="Patient Name" disabled/>
                          </div>
                      </div>
                      </div>
                      <div class="col-md-4">
                      <div class="form-group row">
                          <div class="col-sm-12">
                          <label class="col-form-label">Concern Doctor</label>                        
                          <input
                                                          type="text"
                                                          class="form-control"
                                                          :value="opd.opd_concern_doctor"
                                                          name="Disease"
                                                          placeholder="Patient Name"
                                                          disabled
                                                          />
                          </div>
                      </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                          <label class="col-form-label">Patient BP</label>
                          <input type="text" class="form-control" :value="opd.opd_patient_bp" name="patient_BP" placeholder="Patient BP" disabled/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                        <label class="col-form-label">Patient Weight</label>
                        <input type="text" class="form-control" :value="opd.opd_patient_weight" name="patient_weight" placeholder="Patient Weight" disabled/>
                        </div>
                      </div>
                      </div>
                      <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                        <label class="col-form-label">Patient Temperature</label>
                        <input type="text" class="form-control" :value="opd.opd_patient_temperature" name="patient_temperature" placeholder="Patient Temperature" disabled/>
                        </div>
                      </div>
                      </div>                
                    </div>
                  </div>
                </div>
              </div>
            </div>                
            <!--<div class="col-md-4">
              <button type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">EDIT OPD</button>
            </div>-->
          </form>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import moment from "moment";
import string from "../constant/strings.js"

export default {
    name:'opddetails',
    title: string.PAGE_TITLE_PATIENT_DETAILS,
    components: {
      NavBar,
      SideBar,
      Footer,
    },
    data() {
    return {
      moment:moment,
      personalData:{},
      lastOpdData:{},
      typeform:{
        fees:'800',
        disease:'',
        concern_doctor:'',
        patient_BP:'',
        patient_weight:'',
        patient_temperature:'',
        opd_concern_doctor_id:''
      },
      opdLists:[],
      doctors:[],      
    }
    },
    validations: {
    typeform: {
      disease:{},
      concern_doctor:{required},
      patient_BP:{},
      patient_weight:{},
      patient_temperature:{},  
      opd_concern_doctor_id:{} ,
      fees:{}
    },
  },
  mounted(){      
    //this.getDoctorList();
    this.getOPDDetails();    
  },
  methods:{
    ...mapActions("hospital",["getPatientsByNumberData","getConcernDoctorListData","postEditOpdData", "printOPDData"]),
    submitForm(){
      this.$v.$touch();
      if (!this.$v.typeform.$invalid) {
      let formData = {
        opd_id:this.lastOpdData.opd_id,
        patient_number:this.lastOpdData.patient_number,
        patient_id:this.lastOpdData.patient_id,
        opd_fees:500,
        opd_disease:this.typeform.disease,
        opd_concern_doctor_id: this.typeform.opd_concern_doctor_id,
        opd_patient_bp: this.typeform.patient_BP,
        opd_patient_weight:this.typeform.patient_weight,
        opd_patient_temperature:this.typeform.patient_temperature,                
      };      
      this.postEditOpdData(formData).then((response) => {        
        if (response.response_code == 200) {          
          this.$toasted.success("OPD update successfully", {duration: 2000,});
          this.$router.push({ name: 'opdslist' });
        }else{
          this.$toasted.error(response.message, {duration: 2000,});
        }
      });
      }
    },

    getOPDDetails(){      
      var bodyFormData = new FormData();
      bodyFormData.append('filter_value', parseInt(this.$route.params.pid));
      this.getPatientsByNumberData(bodyFormData).then((response) => {        
        if (response.response_code == 200) {
          this.personalData = response.data;          
          this.opdLists = this.personalData.patient_opds;    
          this.lastOpdData = this.personalData.patient_last_opd;
          //this.typeform.concern_doctor = this.lastOpdData.opd_concern_doctor_id;
          this.typeform = {
            fees:this.lastOpdData.opd_fees,
            disease:this.lastOpdData.opd_disease,
            concern_doctor:this.lastOpdData.opd_concern_doctor,
            opd_concern_doctor_id:this.lastOpdData.opd_concern_doctor_id,
            patient_BP:this.lastOpdData.opd_patient_bp,
            patient_weight:this.lastOpdData.opd_patient_weight,
            patient_temperature:this.lastOpdData.opd_patient_temperature,
          }
        }
      });
    },
    getDoctorList(){
    var bodyFormData = new FormData();
    bodyFormData.append('filter_value', '');
    this.getConcernDoctorListData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.doctors = response.data;
        }
      });
    },

    printOPDDesc(opd_id){
      var bodyFormData = new FormData();
      bodyFormData.append('opd_id', opd_id);
      this.printOPDData(bodyFormData).then((response) => {
        if (response.response_code == 200) {

        }
      });
    },
  }
}
</script>