<template>
<div>
<nav-bar></nav-bar>
<div class="container-fluid page-body-wrapper">
    <side-bar></side-bar>    
    <div class="main-panel">
      <div class="content-wrapper">        

        <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="row">
                  <h4 class="page-header page-title  mb-md-0">Medicines</h4>
              </div>
              <div class="row">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb p-0">
                      <li class="breadcrumb-item"><router-link to="/medicines">Medicine List</router-link></li>
                      <li class="breadcrumb-item active" aria-current="page" v-if="$route.params.id && $route.params.id != ''">{{ this.$route.params.id }}</li>
                      <li class="breadcrumb-item active" aria-current="page" v-else>Add Medicine</li>
                    </ol>
                  </nav>
              </div>
            </div>
        </div>

        <div class="row">
          <div class="col-12">          
            <form class="form-sample">
              <div class="col-md-12 grid-margin stretch-card">              
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">New Medicine</h4>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group row">                      
                          <div class="col-sm-12">
                            <label class="col-form-label">Name</label>
                            <input type="text" class="form-control" v-model="$v.typeform.medicine_name.$model" :class="{ 'is-invalid':  $v.typeform.medicine_name.$error  }" name="medicine_name" placeholder="Medicine Name"/>
                            <div v-if="$v.typeform.medicine_name.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.medicine_name.required">Please enter medicine name</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Medicine Component</label>
                            <input type="text" class="form-control" v-model="$v.typeform.medicine_component.$model" name="medicine_component" placeholder="Medicine Component" :class="{ 'is-invalid':  $v.typeform.medicine_component.$error  }"/>
                            <div v-if="$v.typeform.medicine_component.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.medicine_component.required">Please enter medicine component</span>
                            </div>
                          </div>
                        </div>
                      </div>                      
                      <div class="col-md-4">
                        <div class="form-group row">                      
                          <div class="col-sm-12">
                            <label class="col-form-label">Company</label>
                            <input type="text" class="form-control" v-model="$v.typeform.medicine_company.$model" :class="{ 'is-invalid':  $v.typeform.medicine_company.$error  }" name="medicine_company" placeholder="Medicine Company"/>
                            <div v-if="$v.typeform.medicine_company.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.medicine_company.required">Please enter medicine name</span>
                            </div>
                          </div>
                        </div>
                      </div>                      
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Medicine Sell Price</label>
                            <div class="input-group">
                                <div class="input-group-append">
                                  <span class="input-group-text text-primary" v-html="currency_data.currency_symbol"></span>
                                </div>
                                <input type="number" class="form-control" v-model="$v.typeform.medicine_sell_price.$model" :class="{ 'is-invalid':  $v.typeform.medicine_sell_price.$error  }" name="medicine_sell_price" placeholder="Medicine Sell Price"/>
                            </div>
                            <div v-if="$v.typeform.medicine_sell_price.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.medicine_sell_price.required">Please enter medicine sell price</span>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="col-md-3">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                <label class="col-form-label">Medicine MRP</label>
                                <div class="input-group">
                                    <div class="input-group-append">
                                      <span class="input-group-text text-primary" v-html="currency_data.currency_symbol"></span>
                                    </div>
                                    <input type="number" class="form-control" v-model="$v.typeform.medicine_mrp.$model" :class="{ 'is-invalid':  $v.typeform.medicine_mrp.$error  }" name="medicine_mrp" placeholder="Medicine MRP"/>
                                </div>
                                <div v-if="$v.typeform.medicine_mrp.$error" class="invalid-feedback">
                                    <span v-if="!$v.typeform.medicine_mrp.required">Please enter medicine sell price</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="col-form-label">Medicine Quantity</label>
                                    <input type="text" class="form-control" v-model="$v.typeform.medicine_quantity.$model" :class="{ 'is-invalid':  $v.typeform.medicine_quantity.$error  }" name="medicine_quantity" placeholder="Medicine Quantity"/>
                                    <div v-if="$v.typeform.medicine_quantity.$error" class="invalid-feedback">
                                        <span v-if="!$v.typeform.medicine_quantity.required">Please enter medicine quantity</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div class="col-md-3">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Status</label>
                            <multiselect v-model="$v.typeform.medicine_status.$model" :options="status"
                                         :multiple="false" :close-on-select="true" :clear-on-select="false"
                                         :preserve-search="true" placeholder="Status"
                                         :preselect-first="true" :showLabels="false"></multiselect>
                            <div v-if="$v.typeform.medicine_status.$error" class="invalid-feedback">
                              <span v-if="!$v.typeform.medicine_status.required">Please select gender</span>
                            </div>
                          </div>
                        </div>
                      </div>                       
                    </div>
                    <div class="col-md-4">                      
                       <b-button v-if="is_btn_spinner" variant="primary" disabled>
                        <b-spinner small type="grow"></b-spinner>
                        Loading...
                      </b-button>
                      <button v-else type="button" @click="($route.params.id && $route.params.id != ''?submitForm('update'):submitForm('add'))" class="btn btn-gradient-primary me-2">Submit</button>                      
                    </div>
                  </div>
                </div>
              </div>
            </form>                               
          </div>
        </div>
      </div>
    <Footer/>
    </div>
  </div>
  </div>
  </template>
  <script>
  import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
  import SideBar from "@/components/SideBar.vue";
  import { required, numeric } from "vuelidate/lib/validators";
  import { mapActions} from "vuex";
  import VoerroTagsInput from '@voerro/vue-tagsinput';
  import Multiselect from 'vue-multiselect';
  import string from "../constant/strings.js"
import {get_user_settings } from '../helper/helper';

  export default {
      name:'AddMEDICINE',
      title: string.PAGE_TITLE_ADD_MEDICINE,
      components: {
        NavBar,
        Footer,
        SideBar,
        VoerroTagsInput,
        Multiselect,
      },
      data() {
        return {
        status :['Active','Inactive'],
          typeform:{
            medicine_name:'',
            medicine_component:'',
            medicine_sell_price:'',                
            medicine_mrp:'',
            medicine_quantity:'',
            medicine_company:'',
            medicine_status:'Active',
          },
          is_btn_spinner:false,
          currency_data:{},
        }
      },
      validations: {
      typeform: {
        medicine_name:{required},
        medicine_component:{},
        medicine_sell_price:{required},
        medicine_mrp:{required},
        medicine_quantity:{required,numeric},
        medicine_company:{required},
        medicine_status:{},
      },
    },
    mounted(){      
      if(this.$route.params.id){
        this.getMedicineData(this.$route.params.id);
      }
      this.currency_data = get_user_settings()
    },
    methods:{
      ...mapActions("hospital",["addMedicineData","getMedicineDetailsData","editMedicineDetailsData"]),
      submitForm(val){                
        this.is_btn_spinner = true;
        this.$v.$touch();
        if (!this.$v.typeform.$invalid) {
          var bodyFormData = new FormData();
          bodyFormData.append('medicine_name', this.typeform.medicine_name);
          bodyFormData.append('medicine_component', this.typeform.medicine_component);
          bodyFormData.append('medicine_sell_price', this.typeform.medicine_sell_price);
          bodyFormData.append('medicine_mrp', this.typeform.medicine_mrp);
          bodyFormData.append('medicine_quantity', this.typeform.medicine_quantity);
          bodyFormData.append('medicine_company', this.typeform.medicine_company);
          bodyFormData.append('medicine_status', this.typeform.medicine_status);
        if(val == 'add'){
          this.addMedicineData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
              this.is_btn_spinner = false;
              this.$toasted.success("Medicine added successfully", {duration: 2000,});
              this.$router.push({ name: 'medicines' });
            }else{
              this.is_btn_spinner = false;
              this.$toasted.error(response.message, {duration: 2000,});
            }
          });          
        }else if(val == 'update'){
          bodyFormData.append('medicine_id', this.$route.params.id);          
          this.editMedicineDetailsData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
              this.is_btn_spinner = false;
              this.$toasted.success("Medicine updated successfully", {duration: 2000,});
              this.$router.push({ name: 'medicines' });
            }else{
              this.is_btn_spinner = false;
              this.$toasted.error(response.message, {duration: 2000,});
            }
          });          
        }
      }else{
        this.is_btn_spinner = false;
      }
      },
      getMedicineData(id){
        var bodyFormData = new FormData();
        bodyFormData.append('medicine_id', id);
        this.getMedicineDetailsData(bodyFormData).then((response) => {
          if (response.response_code == 200) {            
            this.typeform.medicine_name =response.data.medicine_name;
            this.typeform.medicine_component = response.data.medicine_component;
            this.typeform.medicine_sell_price = response.data.medicine_sell_price;
            this.typeform.medicine_mrp = response.data.medicine_mrp;
            this.typeform.medicine_quantity = response.data.medicine_quantity;
            this.typeform.medicine_company = response.data.medicine_company;
            this.typeform.medicine_status = response.data.medicine_status;
          }
        })
        

      }
    }
  }
  </script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
