<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">

      <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="row">
                <h4 class="page-header page-title  mb-md-0">Appointments</h4>
            </div>
            <div class="row">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb p-0">
                    <li class="breadcrumb-item"><router-link to="/appointment">Appointments List</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="appointmentDetails.patient_number != null && appointmentDetails.patient_number > 0">{{ this.$route.params.apid }}</li>
                  </ol>
                </nav>
            </div>
          </div>
      </div>

      <div class="row">
        <div class="card-body" v-if="appointmentDetails">          
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">              
                      <div class="col-sm-12" >
                        <label class="col-form-label">Patient Name</label>
                        <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.patient_name" name="patient_name" placeholder="Patient Name" disabled /> 
                      </div>
                  </div>
                  </div>
                  <div class="col-md-4">
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <label class="col-form-label">Patient Mobile</label>
                      <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.patient_mobile_number"  name="patient_mobile" placeholder="Patient Mobile" disabled />                        
                    </div>
                  </div>
                  </div>
                  <div class="col-md-4" v-if="appointmentDetails.patient_number != null && appointmentDetails.patient_number > 0">
                      <div class="form-group row">
                          <div class="col-sm-12">
                          <label class="col-form-label">Patient Number</label>
                          <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.patient_number" name="patient_number" placeholder="Patient Number" disabled />
                          </div>
                      </div>
                  </div>
                </div>          
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Visiting Date/Time</label>
                        <input type="text" class="form-control" :value="moment.parseZone(appointmentDetails && appointmentDetails.visiting_date_time).format('MMMM,DD YYYY hh:mm a')" name="visiting_date_time" placeholder="Visiting Date Time" disabled />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Patient Age</label>
                        <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.patient_age"  name="patient_age" placeholder="patient_age"  disabled/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">FIrst Time Visit</label>
                        <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.is_first_time_visit" name="is_first_time_visit" placeholder="is_first_time_visit" disabled/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 grid-margin stretch-card" v-if="appointmentDetails.patient_details && Object.keys(appointmentDetails.patient_details).length > 0">
            <div class="card">
              <div class="card-body">
                <h3>Patient Details</h3>                
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">              
                      <div class="col-sm-12" >
                        <label class="col-form-label">Patient Name</label>
                        <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.patient_details && appointmentDetails.patient_details.patient_name" name="patient_name" placeholder="Patient Name" disabled /> 
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Patient Mobile</label>
                        <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.patient_details && appointmentDetails.patient_details.patient_mobile_number"  name="patient_mobile" placeholder="Patient Mobile" disabled />                        
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                      <label class="col-form-label">Patient Age</label>
                      <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.patient_details && appointmentDetails.patient_details.patient_age"  name="patient_age" placeholder="patient_age"  disabled/>
                      </div>
                    </div>
                  </div>
                </div>                
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Gender</label>
                        <select class="form-control" :value="appointmentDetails && appointmentDetails.patient_details && appointmentDetails.patient_details.patient_gender" disabled >
                          <option disabled value="">Please select gender</option>
                          <option>Male</option>
                          <option>Female</option>
                        </select>                        
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Address</label>
                        <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.patient_details && appointmentDetails.patient_details.patient_address"  name="address" placeholder="Address"  disabled/>                        
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">City</label>
                        <input type="text" class="form-control" :value="appointmentDetails && appointmentDetails.patient_details && appointmentDetails.patient_details.patient_city" name="city" placeholder="City" disabled/>                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>         
          <div class="col-md-12 grid-margin stretch-card" v-if="appointmentDetails.patient_details && appointmentDetails.patient_details.patient_opds && Object.keys(appointmentDetails.patient_details.patient_opds).length > 0">
            <div class="card">
              <div class="card-body">
                <h3>Patient OPDs</h3>
                <div v-for="(opd ,i) in appointmentDetails.patient_details && appointmentDetails.patient_details.patient_opds" :key="i">
                  <h4>{{moment.parseZone(opd.opd_registered_on).format('MMMM,DD YYYY hh:mm a')}}</h4>
                  <div class="row">                    
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="col-sm-12">
                        <label class="col-form-label">Fees</label>
                          <div class="input-group">
                            <div class="input-group-append">
                              <span class="input-group-text">₹</span>
                            </div>
                            <input type="text" class="col-sm-10 form-control" :value="opd.opd_fees" name="fees" placeholder="Fees" disabled/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Disease</label>
                          <input type="text" class="form-control" :value="opd.opd_disease" name="Disease" placeholder="Patient Name" disabled/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Concern Doctor</label>                        
                          <input
                                                          type="text"
                                                          class="form-control"
                                                          :value="opd.opd_concern_doctor"
                                                          name="Disease"
                                                          placeholder="Patient Name"
                                                          disabled
                                                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient BP</label>
                          <input type="text" class="form-control" :value="opd.opd_patient_bp" name="patient_BP" placeholder="Patient BP" disabled/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient Weight</label>
                          <input type="text" class="form-control" :value="opd.opd_patient_weight" name="patient_weight" placeholder="Patient Weight" disabled/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient Temperature</label>
                          <input type="text" class="form-control" :value="opd.opd_patient_temperature" name="patient_temperature" placeholder="Patient Temperature" disabled/>
                        </div>
                      </div>
                    </div>                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import moment from "moment";
import string from "../constant/strings.js"

export default {
    name:'opddetails',
    title: string.PAGE_TITLE_APPOINTMENT_DETAILS,
    components: {
      NavBar,
      SideBar
    },
    data() {
    return {
      moment:moment,      
      doctors:[],   
      appointmentDetails:{}   
    }
    },
    validations: {
    typeform: {
      disease:{},
      concern_doctor:{required},
      patient_BP:{},
      patient_weight:{},
      patient_temperature:{},  
      opd_concern_doctor_id:{} ,
      fees:{}
    },
  },
  mounted(){    
    //this.getDoctorList();
    this.getAppointmentDetails();    
  },

  methods:{
    ...mapActions("hospital",["getAppointmentDetailsData","getConcernDoctorListData"]),

    getAppointmentDetails(){      
      var bodyFormData = new FormData();      
      bodyFormData.append('appointment_id', parseInt(this.$route.params.apid));
      this.getAppointmentDetailsData(bodyFormData).then((response) => {        
        if (response.response_code == 200) {        
        this.appointmentDetails = response.data;          
        this.opdLists = this.appointmentDetails.patient_opds;    
        //   this.lastOpdData = this.appointmentDetails.patient_last_opd;
        //   //this.typeform.concern_doctor = this.lastOpdData.opd_concern_doctor_id;
        //   this.typeform = {
        //     fees:this.lastOpdData.opd_fees,
        //     disease:this.lastOpdData.opd_disease,
        //     concern_doctor:this.lastOpdData.opd_concern_doctor,
        //     opd_concern_doctor_id:this.lastOpdData.opd_concern_doctor_id,
        //     patient_BP:this.lastOpdData.opd_patient_bp,
        //     patient_weight:this.lastOpdData.opd_patient_weight,
        //     patient_temperature:this.lastOpdData.opd_patient_temperature,
        //   }
        }
      });
    },
    getDoctorList(){
    var bodyFormData = new FormData();
    bodyFormData.append('filter_value', '');
      this.getConcernDoctorListData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.doctors = response.data;
        }
      });
    }
  }
}
</script>