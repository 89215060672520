<template>
  <div>
        <nav-bar></nav-bar>
        <div class="container-fluid page-body-wrapper">
            <side-bar></side-bar>
            <!-- partial -->
                <div class="main-panel">
                    <div class="content-wrapper">
                        <div class="row">

                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="row">
                                        <h4 class="page-header page-title  mb-md-0">IPDs</h4>
                                    </div>
                                    <div class="row">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb p-0">
                                                <li class="breadcrumb-item"><router-link to="/ipdslist">IPDs List</router-link></li>
                                                <li class="breadcrumb-item active" aria-current="page">{{ipd.patient.patient_number}}</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                               <div class="row form-sample mt-3">
                                    <div class="col-md-8 grid-margin stretch-card">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <h4 class="col-md-9 card-title">IPD Details: {{ipd.patient.patient_number}}</h4>
                                                    <div class="col-md-3">
                                                        <multiselect v-model="$v.typeform.selected_ipd_status.$model" :options="ipd_status_list" :multiple="false"
                                                            :close-on-select="true" label="name"
                                                            :clear-on-select="false" :preserve-search="true" placeholder="IPD Status"
                                                            :preselect-first="true" :showLabels="false" @input=updateIPDDetails()
                                                            ></multiselect>
                                                    </div>
                                                </div>
                                                <div class="row mt-2 mt-lg-0">
                                                    <div class="col-md-3">
                                                        <div class="form-group mb-2">
                                                            <div class="col-sm-12">
                                                                <label class="col-4 col-lg-12 col-form-label">Admission Date</label>
                                                                <date-range-picker
                                                                    class="col-8 col-lg-12"
                                                                    ref="picker"
                                                                    :min-date="MINDATE"
                                                                    :max-date="MAXDATE"
                                                                    opens="bottom"
                                                                    :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                                                    :single-date-picker="true"
                                                                    :showDropdowns="showDropdowns"
                                                                    :show-week-numbers= "false"
                                                                    :time-picker="false"
                                                                    :autoApply="true"
                                                                    v-model="$v.typeform.admission_date.$model"
                                                                    :linked-calendars="true"
                                                                    :dateFormat="dd-mmm-yyyy"
                                                                />
                                                                <div v-if="$v.typeform.admission_date.$error" class="invalid-feedback">
                                                                    <span v-if="!$v.typeform.admission_date.required">Please Enter Admission Date</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="form-group mb-2">
                                                            <div class="col-sm-12">
                                                                <label class="col-4 col-lg-12 col-form-label">Discharge Date</label>
                                                                <date-range-picker
                                                                    class="col-8 col-lg-12 "
                                                                    ref="picker"
                                                                    :min-date="MINDATE"
                                                                    :max-date="MAXDATE"
                                                                    opens="bottom"
                                                                    :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                                                    :single-date-picker="true"
                                                                    :showDropdowns="showDropdowns"
                                                                    :show-week-numbers= "false"
                                                                    :time-picker="false"
                                                                    :autoApply="true"
                                                                    v-model="$v.typeform.discharge_date.$model"
                                                                    :linked-calendars="true"
                                                                    :dateFormat="dd-mmm-yyyy"
                                                                />
                                                                <div v-if="$v.typeform.discharge_date.$error" class="invalid-feedback">
                                                                    <span v-if="!$v.typeform.discharge_date.required">Please Enter Discharge Date</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3">
                                                        <div class="form-group mb-2">
                                                            <div class="col-sm-12">
                                                                <label class="col-4 col-lg-12 col-form-label">Transfer Date</label>
                                                                <date-range-picker
                                                                    class="col-8 col-lg-12"
                                                                    ref="picker"
                                                                    :min-date="MINDATE"
                                                                    :max-date="MAXDATE"
                                                                    opens="bottom"
                                                                    :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                                                    :single-date-picker="true"
                                                                    :showDropdowns="showDropdowns"
                                                                    :show-week-numbers= "false"
                                                                    :time-picker="false"
                                                                    :autoApply="true"
                                                                    v-model="$v.typeform.transfer_date.$model"
                                                                    :linked-calendars="true"
                                                                    :dateFormat="dd-mmm-yyyy"
                                                                />
                                                                <div v-if="$v.typeform.transfer_date.$error" class="invalid-feedback">
                                                                    <span v-if="!$v.typeform.transfer_date.required">Please Enter Transfer Date</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-lg-3 col-md-3">
                                                        <div class="form-group mb-2">
                                                            <div class="row">
                                                                <label class="col-4 col-lg-12 col-form-label">Concern Doctor</label>
                                                                <div class="col-8 col-lg-12">
                                                                    <multiselect class="col-8 col-lg-12" v-model="$v.typeform.concern_doctor.$model" :options="doctors" :multiple="false"
                                                                        :close-on-select="true" label="name"
                                                                            :clear-on-select="false" :preserve-search="true" placeholder="Select doctor"
                                                                                :preselect-first="false" :showLabels="false"></multiselect>
                                                                    <div v-if="$v.typeform.concern_doctor.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.typeform.concern_doctor.required">Please select doctor</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group mb-2">
                                                            <div class="row">
                                                                <label class="col-4 col-lg-12 col-form-label">Select Ward</label>
                                                                <div class="col-8 col-lg-12" >
                                                                    <multiselect v-model="$v.typeform.selected_ward.$model" :options="ward_list" :multiple="false"
                                                                        :close-on-select="true" label="name"
                                                                            :clear-on-select="false" :preserve-search="true" placeholder="Please select ward"
                                                                                :preselect-first="true" :showLabels="false" @input=getIPDRoomList()></multiselect>
                                                                    <div v-if="$v.typeform.selected_ward.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.typeform.selected_ward.required">Please select ward</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group mb-2">
                                                            <div class="row">
                                                                <label class="col-4 col-lg-12 col-form-label">Select Room</label>
                                                                <div class="col-8 col-lg-12" >
                                                                    <multiselect v-model="$v.typeform.selected_room.$model" :options="room_list" :multiple="false"
                                                                        :close-on-select="true" label="name"
                                                                            :clear-on-select="false" :preserve-search="true" placeholder="Please select room"
                                                                                :preselect-first="true" :showLabels="false" @input=getIPDRoomBedList()></multiselect>
                                                                    <div v-if="$v.typeform.selected_room.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.typeform.selected_room.required">Please select room</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group mb-2">
                                                            <div class="row">
                                                                <label class="col-4 col-lg-12 col-form-label">Bed Number</label>
                                                                <div class="col-8 col-lg-12" >
                                                                    <multiselect v-model="$v.typeform.selected_room_bed.$model" :options="room_bed_list" :multiple="false"
                                                                        :close-on-select="true" label="bed_name"
                                                                            :clear-on-select="false" :preserve-search="true" placeholder="Please select room bed"
                                                                                :preselect-first="true" :showLabels="false"
                                                                                :disabled="room_bed_list.length <= 1"></multiselect>
                                                                    <div v-if="$v.typeform.selected_room_bed.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.typeform.selected_room_bed.required">Please select room</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group row mb-2">
                                                            <div class="row">
                                                                <label class="col-4 col-lg-12 col-form-label">Admission Reason</label>
                                                                <div class="col-8 col-lg-12">
                                                                    <textarea rows="5" type="text"  style="margin-start:7px;" class="form-control" v-model="$v.typeform.admission_reason.$model" name="admission_reason" placeholder="Admission Reason" :class="{ 'is-invalid':  $v.typeform.admission_reason.$error }"/>
                                                                    <div v-if="$v.typeform.admission_reason.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.typeform.admission_reason.required">Please enter admission reason</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group row mb-2">
                                                            <div class="row">
                                                                <label class="col-4 col-lg-12 col-form-label">Notes</label>
                                                                <div class="col-8 col-lg-12">
                                                                    <textarea rows="5" type="text"  style="margin-start:7px;" class="form-control" v-model="$v.typeform.notes.$model" name="notes" placeholder="Notes" :class="{ 'is-invalid':  $v.typeform.notes.$error }"/>
                                                                    <div v-if="$v.typeform.notes.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.typeform.notes.required">Please enter notes</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group row mb-2">
                                                            <div class="row">
                                                                <label class="col-4 col-lg-12 col-form-label">Discharge Summary</label>
                                                                <div class="col-8 col-lg-12" >
                                                                    <textarea rows="5" type="text"  style="margin-start:7px;" class="form-control" v-model="$v.typeform.discharge_summary.$model" name="discharge_summary" placeholder="Discharge Summary" :class="{ 'is-invalid':  $v.typeform.discharge_summary.$error }"/>
                                                                    <div v-if="$v.typeform.discharge_summary.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.typeform.discharge_summary.required">Please enter discharge summary</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-4 mb-3">
                                                    <div class="col-md-6"></div>
                                                    <div class="col-md-6">


                                                        <div v-if="is_btn_spinner">
                                                            <div class="row">
                                                                <div class="col-md-6"></div>
                                                                <div class="col-md-4">
                                                                    <b-button variant="primary" disabled>
                                                                      <b-spinner small type="grow"></b-spinner>
                                                                      Loading...
                                                                    </b-button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-else class="row">
                                                            <div class="col-md-5"></div>
                                                            <button type="button" class="col-md-6 btn btn-gradient-primary me-2" @click="updateIPDDetails()">Update IPD</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- History-->
                                    <div class="col-md-4 grid-margin stretch-card">
                                        <div class="card card-fix-ipd-history">
                                            <div class="card-body">
                                                <h4 class="col-md-12 card-title text-center">IPD History</h4>
                                                <div class="row" v-if="ipd_history">
                                                    <div v-for="(history, i) in  ipd_history " :key="i">
                                                        <div class="row">
                                                            <p class="text-secondary">{{history.message}}
                                                            <small></br>{{history.created_at}}</small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" v-else>
                                                    <p class="text-secondary text-center"><br/><br/><br/><br/><br/><br/><br/><br/>No IPD History Found</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row form-sample">
                                    <div class="col-md-8 stretch-card">
                                        <!-- Treatment-->
                                        <div class="card card-fix mt-1">
                                            <div class="card-body">
                                                <div class="row align-items-center mb-2 mb-lg-2">
                                                  <h4 class="col-md-11 col-10 card-title">Treatment Details</h4>
                                                  <button class="col-md-1 col-2 btn btn-outline-primary btn-rounded btn-icon" @click="openAddIPDTreatmentModel(null)">
                                                    <i class="mdi mdi-plus-outline primary"></i>
                                                  </button>
                                                </div>

                                                <div class="row" v-if="!ipd_treatment">
                                                    <p class="text-secondary text-center"><br/><br/>Treatment Details Not Found</p>
                                                </div>

                                                <div v-for="(ipd_treatment, i) in  ipd_treatment " :key="i">
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered">
                                                            <tbody>
                                                                <tr>
                                                                    <th colspan="2">
                                                                        <div class="row align-items-center">
                                                                          <h5 class="col-8 col-md-10 col-lg-10 text-secondary">
                                                                            {{ ipd_treatment.name }}
                                                                            <small
                                                                              v-bind:class="{
                                                                                'text-warning': ipd_treatment.treatment_status === '0',
                                                                                'text-success': ipd_treatment.treatment_status === '1'
                                                                              }"
                                                                              @click="closeIPDTreatment(ipd_treatment.id, i)"
                                                                            >
                                                                              &#9679; {{ getIPDTreatmentStatus(ipd_treatment.treatment_status) }}
                                                                            </small>
                                                                          </h5>
                                                                          <h6 class="col-2 col-md-1 col-lg-1 text-primary text-center" @click="openAddIPDTreatmentModel(ipd_treatment)">
                                                                            <u>Edit</u>
                                                                          </h6>
                                                                          <i class="col-1 col-md-1 col-lg-1 mdi mdi-delete text-primary d-flex justify-content-center align-items-center"
                                                                             style="margin-top:-5px"
                                                                             @click="deleteTreatment(ipd_treatment.id, ipd_treatment.name)"
                                                                          ></i>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2"><h5><small class="text-secondary">Medicine: {{getMedicine(ipd_treatment.treatment_medicine)}}</small></h5></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2"><small class="col-md-6 text-secondary">Notes: {{ipd_treatment.note}}</small></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h6 class="text-secondary">
                                                                            Doctors: {{getDoctor(ipd_treatment.doctor)}}
                                                                            <small>
                                                                                Last Updated On: {{ moment.parseZone(ipd_treatment.updated_at).format('DD-MMM-YYYY HH:mm') }}
                                                                            </small>
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- IPD Report History-->
                                    <div class="col-md-4 stretch-card mt-3 mt-lg-0">
                                        <div class="card card-fix mt-1">
                                            <div class="card-body">
                                                <div class="row align-items-center mb-2 mb-lg-2"">
                                                  <h4 class="col-md-10 col-10 card-title">Reports</h4>
                                                  <button class="col-md-1 col-2 btn btn-outline-primary btn-rounded btn-icon" @click="openAddIPDReportModel()">
                                                    <i class="mdi mdi-plus-outline primary"></i>
                                                  </button>
                                                </div>

                                                <div class="row" v-if="ipd.treatment_reports.length <= 0">
                                                    <p class="text-secondary text-center"><br/><br/>Reports Details Not Found</p>
                                                </div>

                                                <div class="row" v-for="(ipd_report, i) in  ipd.treatment_reports " :key="i">
                                                    <div class="row align-items-center">
                                                      <h5 class="col-8 col-md-9 text-secondary">
                                                        {{ ipd_report.report_name }}
                                                        <small>{{ moment.parseZone(ipd_report.report_date).format('DD-MMM-YYYY HH:mm') }}</small>
                                                      </h5>
                                                      <h6 class="col-2 col-md-2 text-primary text-center" @click="openAddIPDReportModel()">
                                                        <u>Edit</u>
                                                      </h6>
                                                      <i class="col-1 col-md-1 col-lg-1 mdi mdi-delete text-primary d-flex justify-content-center align-items-center"
                                                         style="margin-top:-5px"
                                                         @click="deleteIPDReport(ipd_report.prescription_report_id, ipd_report.report_name)"
                                                      ></i>
                                                    </div>
                                                    <div class="row">
                                                      <small class="col-12 text-secondary">
                                                        Last Updated On: {{ moment.parseZone(ipd_report.updated_at).format('DD-MMM-YYYY HH:mm') }}
                                                      </small>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row form-sample">
                                    <div class="col-md-12 stretch-card">
                                        <!-- Patient Details-->
                                        <div class="card card mt-3">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div>
                                                            <p class="card-title">Patient Details</p>
                                                            <div class="row mt-4">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Patient Number</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_number">: {{ipd.patient.patient_number}}
                                                                    <small>
                                                                        <router-link :to="{name:'opddetails',params:{'pid':ipd.patient.patient_number}}">
                                                                            <i class="mdi mdi-open-in-new"></i>
                                                                        </router-link>
                                                                    </small>
                                                                </p>
                                                                <h6 class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</h6>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Name</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_name">: {{ipd.patient.patient_name}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Mobile</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_mobile_number">: {{ipd.patient.patient_mobile_number}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Email</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_email">: {{ipd.patient.patient_email}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Age</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_age">: {{ipd.patient.patient_age}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Gender</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_gender">: {{ipd.patient.patient_gender}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Marital Status</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_marital_status">: {{ipd.patient.patient_marital_status}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Address</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_address">: {{ipd.patient.patient_address}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">City</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_city">: {{ipd.patient.patient_city}} - {{ipd.patient.patient_postal_code}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4 mt-5 mt-lg-0">
                                                        <div>
                                                            <h4 class="card-title">Emergency Contact Details</h4>
                                                            <div class="row mt-4">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Contact Person Name</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.emergency_contact_name">: {{ipd.patient.emergency_contact_name}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Contact Person Number</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.emergency_contact_number">: {{ipd.patient.emergency_contact_number}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Relation With</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.emergency_contact_contact_relationship">: {{ipd.patient.emergency_contact_contact_relationship}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Family Doctor Name</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.family_doctor_name">: {{ipd.patient.family_doctor_name}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Family Doctor Number</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.family_doctor_contact_number">: {{ipd.patient.family_doctor_contact_number}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Preferred Pharmacy Store</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.preferred_pharmacy">: {{ipd.patient.preferred_pharmacy}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Pharmacy Store Number</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.preferred_pharmacy_contact_number">: {{ipd.patient.preferred_pharmacy_contact_number}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Current Medicine Details</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.current_medicine_details">: {{ipd.patient.current_medicine_details}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Allergy</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_allergies">: {{ipd.patient.patient_allergies}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mt-5 mt-lg-0">
                                                        <div>
                                                            <h4 class="card-title">Insurance Details</h4>
                                                            <div class="row mt-4">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Insurance Company Name</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.insurance_company_name">: {{ipd.patient.insurance_company_name}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Insurance Number</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.insurance_id_number">: {{ipd.patient.insurance_id_number}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Policy Holder Date Of Birth</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.insurance_policy_holder_date_of_birth">{{moment.parseZone(ipd.patient.insurance_policy_holder_date_of_birth).format('DD: -MMM-YYYY')}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="col-5 col-mg-6 col-lg-6 text-title">Policy Holder Name</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.insurance_policy_holder_name">: {{ipd.patient.insurance_policy_holder_name}}</p>
                                                                <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mt-5">
                                                    <div class="col-md-4">
                                                        <div class="row">
                                                            <p class="col-5 col-mg-6 col-lg-6 text-title">Patient Status</p>
                                                            <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_status">: {{ipd.patient.patient_status}}</p>
                                                            <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                        </div>
                                                        <div class="row">
                                                            <p class="col-5 col-mg-6 col-lg-6 text-title">Total Visits</p>
                                                            <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_number_of_visit">: {{ipd.patient.patient_number_of_visit}}</p>
                                                            <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                        </div>
                                                        <div class="row">
                                                            <p class="col-5 col-mg-6 col-lg-6 text-title">First Visit On</p>
                                                            <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-if="ipd.patient.patient_first_registered_on">: {{moment.parseZone(ipd.patient.patient_first_registered_on).format('DD-MMM-YYYY')}}</p>
                                                            <p class="col-7 col-mg-6 col-lg-6 text-secondary" v-else>: -</p>
                                                        </div>
                                                        <div class="row" v-if="ipd.patient.patient_opd_re_newed_on">
                                                            <p class="col-md-2 text-title">OPD Renewed On</p>
                                                            <p class="col-md-10 text-secondary">: {{moment.parseZone(ipd.patient.patient_opd_re_newed_on).format('DD-MMM-YYYY')}}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    <Footer/>
                </div>
        </div>
        <IPDTreatmentModel ref="ipdTreatmentModelComponent"/>
        <IPDReportModel ref="ipdReportModelComponent"/>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import { getCurrentInstance} from "vue";
import Multiselect from 'vue-multiselect';
import Vue from 'vue'
import string from "../../constant/strings.js"
import DateRangePicker from 'vue2-daterange-picker'
import moment from "moment";
import IPDTreatmentModel from "../model/ipd_treatment_model"
import IPDReportModel from "../model/ipd_report"
import Swal from 'sweetalert2'

export default {
    title: string.PAGE_TITLE_IPD_DETAILS,
    components:{NavBar,SideBar,Multiselect, DateRangePicker,IPDTreatmentModel,IPDReportModel},
    data() {
        const now = new Date()
        const maxDate = new Date(new Date(now.getFullYear(), now.getMonth(), now.getDate()))
        const minDate = new Date(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()))
        return {
          moment,
          typeform:{
             patient_number:'',
             admission_date:{},
             discharge_date:{},
             transfer_date:{},
             concern_doctor:null,
             selected_ward:null,
             selected_room:null,
             selected_room_bed:null,
             admission_reason:'',
             notes:'',
             selected_ipd_status:{},
             discharge_summary:'',
          },
          ipd:null,
          ipd_treatment:null,
          ipd_history:null,
          MAXDATE:maxDate,
          MINDATE:minDate,
          is_btn_spinner:false,
          doctors:[],
          ward_list:[],
          room_list:[],
          room_bed_list:[],
          ipd_status_list:[{"id":"0", "name":"Admitted"}, {"id":"1", "name":"Discharged"}, {"id":"2", "name":"Transferred"}],
          previous_ipd_status:null,
          is_listing_treatment:false,
          is_listing_report:false
        }
    },
    validations: {
        typeform: {
             patient_number:{},
             admission_date:{required},
             discharge_date:{required},
             transfer_date:{required},
             concern_doctor:{required},
             selected_ward:{required},
             selected_room:{required},
             selected_room_bed:{required},
             admission_reason:{required},
             notes:{},
             selected_ipd_status:{},
             discharge_summary:{},
        },
  },
  mounted(){
    this.getIPDDetails();
    this.getDoctorList();
    this.getIPDWardList();
    this.getIPDTreatments();
    this.getIPDHistory();
  },
  methods:{
    ...mapActions("hospital",["getIPDDetailsData", "getConcernDoctorListData", "getIPDRoomListData", "getIPDRoomBedListData", "getIPDWardListData", "updateIPDDetailsData", "getIPDTreatmentsData", "deleteTreatmentData", "deleteIPDReportData", "getIPDHistoryData", "closeIPDTreatmentData"]),

    getIPDDetails() {
        var bodyFormData = new FormData();
        bodyFormData.append('ipd_id', this.$route.params.ipdid);
        this.getIPDDetailsData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.ipd = response.data
                this.typeform.admission_date= {startDate:this.ipd.admission_date, endDate:this.ipd.admission_date};
                this.typeform.discharge_date= {startDate:this.ipd.discharge_date, endDate:this.ipd.discharge_date};
                this.typeform.transfer_date= {startDate:this.ipd.transfer_date, endDate:this.ipd.transfer_date};
                this.typeform.concern_doctor = this.ipd.doctor[0]
                this.typeform.selected_ward = this.ipd.room.ward
                this.typeform.selected_room = this.ipd.room

                if(this.ipd.reason_for_admission){
                    this.typeform.admission_reason = this.ipd.reason_for_admission
                }
                if(this.ipd.note){
                    this.typeform.notes = this.ipd.note
                }

                if(this.ipd.discharge_summary){
                    this.typeform.discharge_summary = this.ipd.discharge_summary
                }

                this.typeform.selected_ipd_status = {"id":this.ipd.ipd_status, "name":this.getIPDStatus(this.ipd.ipd_status)}
                this.typeform.previous_ipd_status = this.typeform.selected_ipd_status
                this.typeform.selected_room_bed = {
                    "bed_number": this.ipd.bed_number,
                    "bed_name": "Bed " + this.ipd.bed_number
                }
            }
        });
    },

    getDoctorList(){
        this.doctors = []
        this.typeform.concern_doctor = ''
        var bodyFormData = new FormData();
        bodyFormData.append('filter_value', '');
        this.getConcernDoctorListData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.doctors = response.data;
            }
        });
    },

    getIPDWardList(){
        this.ward_list = []
        this.typeform.selected_ward = ''
        var bodyFormData = new FormData();
        bodyFormData.append('search_text', '');
        this.getIPDWardListData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.ward_list = response.data;
            }
        });
    },

    getIPDRoomList(){
        this.room_list = []
        this.typeform.selected_room = ''
        var bodyFormData = new FormData();
        -bodyFormData.append('search_text', '');
        -bodyFormData.append('occupancy_status', 'Vacant');
        -bodyFormData.append('ward_id', this.typeform.selected_ward.id);
        this.getIPDRoomListData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.room_list = response.data;
            }
        });
    },

    getIPDRoomBedList(){
        this.room_bed_list = []
        this.typeform.selected_room_bed = ''
        var bodyFormData = new FormData();
        -bodyFormData.append('room_id', this.typeform.selected_room.id);
        this.getIPDRoomBedListData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.room_bed_list = response.data;
                if(this.room_bed_list.length == 1) {
                    this.typeform.selected_room_bed = this.room_bed_list[0]
                }
            }
        });
    },

    updateIPDDetails(){
          if (!this.typeform.admission_date.startDate) {
            this.$toasted.error("Please select admission date", {duration: 2000,});
            return;
          }
          if (!this.typeform.concern_doctor) {
            this.$toasted.error("Please select doctor", {duration: 2000,});
            return;
          }
          if (!this.typeform.selected_room) {
            this.$toasted.error("Please select room", {duration: 2000,});
            return;
          }
          if (!this.typeform.selected_room_bed) {
            this.$toasted.error("Please select room bed", {duration: 2000,});
            return;
          }
          if (!this.typeform.selected_ipd_status || !this.typeform.selected_ipd_status.id) {
            this.$toasted.error("Please select ipd status", {duration: 2000,});
            return;
          }
            console.log("Doctor", this.typeform.concern_doctor)
          this.$v.$touch();
          if (!this.$v.typeform.$invalid) {
              this.is_btn_spinner = true;
              var bodyFormData = new FormData();
              bodyFormData.append('ipd_id', this.ipd.id);
              bodyFormData.append('room_id', this.typeform.selected_room.id);
              bodyFormData.append('doctor_ids', this.typeform.concern_doctor.id);
              if (this.typeform.admission_date.startDate) {
                  bodyFormData.append('admission_date', moment(String(this.typeform.admission_date.startDate)).format('YYYY-MM-DD'));
              }
              if (this.typeform.discharge_date.startDate) {
                  bodyFormData.append('discharge_date', moment(String(this.typeform.discharge_date.startDate)).format('YYYY-MM-DD'));
              }
              if (this.typeform.transfer_date.startDate) {
                  bodyFormData.append('transfer_date', moment(String(this.typeform.transfer_date.startDate)).format('YYYY-MM-DD'));
              }
              bodyFormData.append('reason_for_admission', this.typeform.admission_reason);
              bodyFormData.append('bed_number', this.typeform.selected_room_bed.bed_number);
              bodyFormData.append('note', this.typeform.notes);
              bodyFormData.append('discharge_summary', this.typeform.discharge_summary);

              if (this.typeform.selected_ipd_status && this.typeform.selected_ipd_status.id) {
                  bodyFormData.append('ipd_status', this.typeform.selected_ipd_status.id);
              }
              this.updateIPDDetailsData(bodyFormData).then((response) => {
                this.is_btn_spinner = false;
                if (response.response_code == 200) {
                    this.typeform.previous_ipd_status = this.typeform.selected_ipd_status
                    this.$toasted.success(response.message, {duration: 2000,});
                    this.getIPDHistory()
                } else {
                    this.typeform.selected_ipd_status = this.typeform.previous_ipd_status
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
    },

    getIPDTreatments() {
        this.ipd_treatment = null
        var bodyFormData = new FormData();
        bodyFormData.append('ipd_id', this.$route.params.ipdid);
        this.getIPDTreatmentsData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.ipd_treatment = response.data
            }
        });
    },

    getIPDHistory() {
        this.ipd_history = null
        var bodyFormData = new FormData();
        bodyFormData.append('ipd_id', this.$route.params.ipdid);
        this.getIPDHistoryData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.ipd_history = response.data
            }
        });
    },

    closeIPDTreatment(treatment_id, position) {
        var bodyFormData = new FormData();
        bodyFormData.append('treatment_id', treatment_id);
        this.closeIPDTreatmentData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.ipd_treatment[position] = response.data
                var list = this.ipd_treatment
                this.ipd_treatment = []
                this.ipd_treatment = list
                this.$toasted.success(response.message, {duration: 2000,});
                this.getIPDHistory()
            } else {
                this.$toasted.error(response.message, {duration: 2000,});
            }
        });
    },

    getIPDStatus(ipd_status_code) {
      if(ipd_status_code == "0") {
        return "Admitted";
      } else if(ipd_status_code == "1") {
        return "Discharged";
      } else if(ipd_status_code == "2") {
        return "Transferred";
      }
    },

    getIPDTreatmentStatus(treatment_status_code) {
      if(treatment_status_code == "0") {
        return "Ongoing";
      } else if(treatment_status_code == "1") {
        return "Completed";
      } else {
        return "-"
      }
    },

    getMedicine(treatment_medicine) {
        if(treatment_medicine.length > 0) {
            return treatment_medicine.map(object => object.medicine_name).join(" | ")
        } else {
            return "-"
        }
    },

    getDoctor(treatment_doctor) {
        if(treatment_doctor.length > 0) {
            return treatment_doctor.map(object => object.name).join(" | ")
        } else {
            return "-"
        }
    },

    openAddIPDTreatmentModel(treatmentDetails) {
        this.$refs.ipdTreatmentModelComponent.clearOldData();
        this.$refs.ipdTreatmentModelComponent.receiveData(this.ipd, treatmentDetails);
        this.$refs.ipdTreatmentModelComponent.show();
        if(this.is_listing_treatment == false){
            this.is_listing_treatment = true
            var myParent = this
            this.$refs.ipdTreatmentModelComponent.$on("hide", function () {
                myParent.getIPDTreatments();
            });
        }
    },

    openAddIPDReportModel() {
        this.$refs.ipdReportModelComponent.clearData();
        this.$refs.ipdReportModelComponent.receiveData(this.ipd, this.ipd.treatment_reports);
        this.$refs.ipdReportModelComponent.show();
        if(this.is_listing_report == false){
            this.is_listing_report = true
            var myParent = this
            this.$refs.ipdReportModelComponent.$on("hide", function () {
                myParent.getIPDDetails();
            });
        }
    },

    deleteTreatment(treatment_id, treatment_name, index) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + treatment_name + string.TREATMENT,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('treatment_id', treatment_id);
            this.deleteTreatmentData(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.ipd_treatment.length;i++){
                        if(treatment_id == this.ipd_treatment[i].id) {
                            this.ipd_treatment.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    },

    deleteIPDReport(prescription_report_id, report_name, index) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + report_name + string.REPORT,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('ipd_id', this.ipd.id);
            bodyFormData.append('prescription_report_id', prescription_report_id);
            this.deleteIPDReportData(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.ipd.treatment_reports.length;i++){
                        if(prescription_report_id == this.ipd.treatment_reports[i].prescription_report_id) {
                            this.ipd.treatment_reports.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    },

  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
