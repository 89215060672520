<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">

        <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="row">
                  <h4 class="page-header page-title  mb-md-0">Effects</h4>
              </div>
              <div class="row">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb p-0">
                      <li class="breadcrumb-item"><router-link to="/effects">Effect List</router-link></li>
                      <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.effectid != ''">{{ this.$route.params.effectid }}</li>
                      <li class="breadcrumb-item active" aria-current="page" v-else>Add Effect</li>
                    </ol>
                  </nav>
              </div>
            </div>
        </div>

      <div class="row">
        <div class="card-body">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">              
                      <div class="col-sm-12" >
                        <label class="col-form-label">Effect Name</label>
                        <input type="text" class="form-control" v-model="$v.typeform.effect_name.$model" :class="{ 'is-invalid':  $v.typeform.effect_name.$error  }" name="effect_name" placeholder="Effect Name"/>
                      </div>
                      <div v-if="$v.typeform.effect_name.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.effect_name.required">Please enter effect name</span>
                      </div>
                  </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12" >
                        <label class="col-form-label">ICD-10 Code</label>
                        <input type="text" class="form-control" v-model="$v.typeform.icd_code.$model" :class="{ 'is-invalid':  $v.typeform.icd_code.$error  }" name="icd_code" placeholder="ICD-10 Code" disabled/>
                      </div>
                      <div v-if="$v.typeform.icd_code.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.icd_code.required">Please enter icd-10 code</span>
                      </div>
                  </div>
                  </div>


                  <div class="col-md-3">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="col-form-label">Status</label>

                            <multiselect v-model="$v.typeform.effect_status.$model" :options="status"
                                         :multiple="false" :close-on-select="true" :clear-on-select="false"
                                         :preserve-search="true" placeholder="Status" :showLabels="false"
                                         :preselect-first="true"></multiselect>
                                <div v-if="$v.typeform.effect_status.$error" class="invalid-feedback">
                                    <span v-if="!$v.typeform.effect_status.required">Please select status</span>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                <br/><br/>
                <div class="col-md-12">
                    <b-button v-if="is_btn_spinner" variant="primary" disabled>
                        <b-spinner small type="grow"></b-spinner>Loading...
                    </b-button>
                    <button v-if="!is_btn_spinner" type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">Submit</button>
                    <!-- <button class="btn btn-light">Cancel</button> -->
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    <Footer/>
    </div>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"

export default {
    name:'effectdetails',
    title: string.PAGE_TITLE_EFFECT_DETAILS,
    components: {
      NavBar,
Footer,
      SideBar,
      Multiselect,
    },
    data() {
    return {
        status :['Active','Inactive'],
      moment:moment,
      typeform:{
        effect_name:'',
        effect_status:'',
        icd_code:'',
      },
      is_btn_spinner:false,
    }
    },
    validations: {
    typeform: {
      effect_name:{required},
      icd_code:{},
      effect_status:{required},
    },
  },

  mounted(){
    if (this.$route.params.effectid != ''){
        this.getEffectDetails();
    }
  },

  methods:{
    ...mapActions("hospital",["getEffectDetailsData", "addEffectData", "editEffectData"]),

    getEffectDetails(){
      var bodyFormData = new FormData();      
      bodyFormData.append('effect_id', parseInt(this.$route.params.effectid));
      this.getEffectDetailsData(bodyFormData).then((response) => {
        if (response.response_code == 200) {        
            this.typeform = {
                effect_name :response.data.effect_name,
                effect_status : response.data.effect_status,
                icd_code : response.data.icd_code,
            }
        } else{
            this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },

    submitForm(){
        this.$v.$touch();
        if (!this.$v.typeform.$invalid) {
            this.is_btn_spinner = true;
            var bodyFormData = new FormData();
            bodyFormData.append('effect_name', this.typeform.effect_name);
            bodyFormData.append('effect_status', this.typeform.effect_status);

            if (this.$route.params.effectid != ''){
                bodyFormData.append('effect_id', this.$route.params.effectid);
                this.editEffectData(bodyFormData).then((response) => {
                  this.is_btn_spinner = false;
                  if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    this.$router.push({ name: 'effectslist' });
                  }else{
                    this.$toasted.error(response.message, {duration: 2000,});
                  }
                });
            } else {
                this.addEffectData(bodyFormData).then((response) => {
                  this.is_btn_spinner = false;
                  if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    this.$router.push({ name: 'effectslist' });
                  }else{
                    this.$toasted.error(response.message, {duration: 2000,});
                  }
                });
            }
          }
        },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>