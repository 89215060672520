<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper">

          <div class="row align-items-center">
            <div class="col-lg-4">
              <div class="page-header  mb-md-0">
                <h4 class="page-title">IPDs List</h4>
              </div>
            </div>

          </div>
          <br />
          <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body nw-opd">
                  <div class="row">
                    <div class="col-lg-2  mb-lg-0 mb-2">
                      <input placeholder="Search" v-model="searchtext" class="form-control" type="search"
                        name="searchopd" id="searchopd" autocomplete="off" v-on:keyup="getIpdList(1)">
                    </div>
                    <div class="col-lg-2  mb-lg-0 mb-2">
                      <multiselect v-model="ipd_filter_doctor" :options="doctors" :multiple="false"
                        label="name" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Doctors"
                        :preselect-first="true" :showLabels="false" :allowEmpty="true" @input=getIpdList(1) />
                      </multiselect>
                    </div>

                    <div class="col-lg-2  mb-lg-0 mb-2">
                      <multiselect v-model="ipd_filter_room" :options="room_list" :multiple="false"
                        label="name" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Room"
                        :preselect-first="true" :showLabels="false" :allowEmpty="false" @input=getIpdList(1) />
                      </multiselect>
                    </div>

                    <div class="col-lg-2  mb-lg-0 mb-2">
                      <multiselect v-model="ipd_filter_ward" :options="ward_list" :multiple="false"
                        label="name" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Ward"
                        :preselect-first="true" :showLabels="false" :allowEmpty="false" @input=getIpdList(1) />
                      </multiselect>
                    </div>

                    <date-range-picker class="col-lg-2 mb-lg-0 mb-2" ref="picker" :max-date="MAXDATE" :opens="center"
                      :locale-data="{ firstDay: 30, format: 'dd-mmm-yyyy' }" :single-date-picker="false"
                      :showDropdowns="showDropdowns" :show-week-numbers="false" :time-picker="false" :autoApply="true"
                      v-model="dateRange" :linked-calendars="true" :dateFormat="dd - mmm - yyyy"
                      @finish-selection=getIpdList(1) @update=getIpdList(1) />
                    <div class="col-lg-2">
                      <multiselect v-model="ipd_filter_status" :options="ipd_status_list" :multiple="false"
                        label="name" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder=""
                        :preselect-first="true" :showLabels="false" :allowEmpty="false" @input=getIpdList(1) />
                      </multiselect>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div class="table-responsive">
                    <table class="table table-hover ">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Admission Date</th>
                          <th>Discharge Date</th>
                          <th>Patient Number</th>
                          <th>Patient Name</th>
                          <th>Patient<br />Age</th>
                          <th>City</th>
                          <th>Room <br />Ward.</th>
                          <th>Concern<br />Doctor</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(ipd, i) in  ipdlists " :key="i">
                          <td>{{ ipd.id }}</td>
                          <td>{{ moment.parseZone(ipd.admission_date).format('DD MMM YYYY') }}</td>
                          <td v-if="ipd.discharge_date">{{ moment.parseZone(ipd.discharge_date).format('DD MMM YYYY') }}</td>
                          <td v-else>-</td>
                          <td>{{ ipd.patient.patient_number }}</td>
                          <td>{{ ipd.patient.patient_name }}</td>
                          <td>{{ ipd.patient.patient_age }}</td>
                          <td>{{ ipd.patient.patient_city }}</td>
                          <td v-if="ipd.room.ward">{{ ipd.room.name }}</br>{{ ipd.room.type }} </br> {{ ipd.room.ward.name }}</td>
                          <td v-else>{{ ipd.room.name }}/{{ ipd.room.type }}</td>
                          <td>{{ ipd.doctor[0].name }}</td>
                          <td><label v-bind:class="{
                        'badge badge-success': ipd.ipd_status === '1',
                        'badge badge-warning': ipd.ipd_status === '2',
                        'badge badge-info': ipd.ipd_status === '0'}
                        ">{{getIPDStatus(ipd.ipd_status)}}</label></td>
                          <td>

                            <router-link :to="{ name: 'ipddetails', params: { 'ipdid': ipd.id} }">
                              <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i
                                  class="mdi mdi-eye"></i></button>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="is_API_call_running" class="text-center mt-5">
                    <b-spinner label=""></b-spinner>
                  </div>
                  <div class="row" v-if="!is_API_call_running && ipdlists.length == 0">
                    <div class="col-lg-12 text-center">
                      <br /><br /><br />
                      <small class="text-muted">{{ errorMessage }}</small>
                      <br /><br /><br />
                    </div>
                  </div>
                  <div class="b-pagination-outer" v-if="listPages > 1">
                    <ul id="border-pagination">
                      <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                      <li v-for=" page  in  listPages " :key="page"><a href="javascript:void(0)"
                          @click="getIpdList(page)" :class="(activePage && activePage == page) ? 'active' : ''">{{
                        page }}</a></li>
                      <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    <Footer/>
      </div>
    </div>
    <b-modal id="modal-prevent-closing" ref="modal" title="" @ok="handleOk" centered>
      <b-form-input v-model="patientno" placeholder="Enter patient number"></b-form-input>
      <p v-if="errormsg != ''" class="err">{{ errormsg }}</p>
    </b-modal>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Subscription from "@/components/Subscription.vue";
import DateRangePicker from 'vue2-daterange-picker'
import { mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"

export default {
  name: 'IPDS',
  title: string.PAGE_TITLE_IPD_LIST,
  components: {
    NavBar,
    Footer,
    SideBar,
    DateRangePicker,
    Multiselect,
    Subscription
  },
  data() {
    const now = new Date()
    const startdate = new Date(now.getFullYear(), now.getMonth(), 1)
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)

    return {
      ipdlists: [],
      moment,
      listPages: 0,
      activePage: 1,
      patientno: '',
      errormsg: '',
      is_API_call_running: false,
      searchtext: '',
      myTimer: null,
      dateRange: {
        startDate: startdate,
        endDate: today
      },
      response_code: 200,
      errorMessage: "No Data Found.",
      ipd_status_list: [{"name":"All", "id":"All"}, {"name":"Admitted", "id":"0"}, {"name":"Discharged", "id":"1"}, {"name":"Transferred", "id":"2"}],
      ipd_filter_status: {"name":"All", "id":"All"},
      MAXDATE: maxDate,
      ward_list: [],
      room_list: [],
      doctors: [],
      ipd_filter_ward: '',
      ipd_filter_room: '',
      ipd_filter_doctor: [],
    }
  },
  mounted() {
    this.getIpdList(this.activePage);
    this.getIPDWardList();
    this.getIPDRoomList();
    this.getDoctorList()();
  },

  methods: {
    ...mapActions("hospital", ["getIpdListData", "checkPatientNumberData", "getIPDWardListData", "getIPDRoomListData", "getConcernDoctorListData"]),

    getIpdList(page) {
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.ipdlists = [];
        var bodyFormData = new FormData();
        bodyFormData.append('search_text', this.searchtext);
        bodyFormData.append('page', page);
        bodyFormData.append('filter_from_date', moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'));
        bodyFormData.append('filter_to_date', moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'));
        bodyFormData.append('ipd_status', this.ipd_filter_status.id);
        if(this.ipd_filter_ward.id) {
            bodyFormData.append('ward_id', this.ipd_filter_ward.id);
        } else {
            bodyFormData.append('ward_id', '');
        }
        if(this.ipd_filter_room.id) {
            bodyFormData.append('room_id', this.ipd_filter_room.id);
        } else {
            bodyFormData.append('room_id', '');
        }
        if(this.ipd_filter_doctor && this.ipd_filter_doctor.id){
            bodyFormData.append('doctor_ids', this.ipd_filter_doctor.id);
        }
        this.getIpdListData(bodyFormData).then((response) => {
          this.is_API_call_running = false;
          if (response.response_code == 200) {
            this.ipdlists = response.data;
            this.listPages = response.no_of_pages;
          } else {
            this.response_code = response.response_code;
            console.log(this.response_code)
            this.errorMessage = response.message;
          }
        });
      }, 500)
    },

    previewClick() {
      this.activePage--;
      if (this.activePage >= 1) {
        this.getIpdList(this.activePage);
      } else {
        this.activePage = 1;
      }
    },

    nextClick() {
      this.activePage++;
      if (this.activePage <= this.listPages) {
        this.getIpdList(this.activePage);
      } else {
        this.activePage = this.listPages;
      }
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (this.patientno) {
        var bodyFormData = new FormData();
        bodyFormData.append('patient_number', this.patientno);
        this.checkPatientNumberData(bodyFormData).then((response) => {
          if (response.response_code == 200) {
            this.$router.push({ name: 'opddetails', params: { 'pid': this.patientno, 'is_edit': 0 } });
            this.patientno = ''
          } else {
            this.response_code = response.response_code;

            this.errormsg = response.message;
          }
        });
      }
    },

    openDatePicker() {
      this.$refs.picker.togglePicker(true);
    },

    getIPDStatus(ipd_status_code) {
      if(ipd_status_code == "0") {
        return "Admitted";
      } else if(ipd_status_code == "1") {
        return "Discharged";
      } else if(ipd_status_code == "2") {
        return "Transferred";
      }
    },
    getIPDWardList() {
      this.ward_list = [];
      var bodyFormData = new FormData();
      bodyFormData.append('search_text', '');
      bodyFormData.append('page', 1);
      this.getIPDWardListData(bodyFormData).then((response) => {
          if (response.response_code == 200) {
            this.ward_list.push({"id":0, "name":"All"})
            response.data.forEach((obj) => {
                this.ward_list.push(obj);
            })
          }
      });
    },
    getIPDRoomList() {
      this.ward_list = [];
      var bodyFormData = new FormData();
      bodyFormData.append('search_text', '');
      bodyFormData.append('page', 1);
      this.getIPDRoomListData(bodyFormData).then((response) => {
          if (response.response_code == 200) {
            this.room_list.push({"id":0, "name":"All"})
            response.data.forEach((obj) => {
                this.room_list.push(obj);
            })
          }
      });
    },
    getDoctorList(){
      this.doctors = []
      var bodyFormData = new FormData();
      -bodyFormData.append('filter_value', '');
      this.getConcernDoctorListData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.doctors = response.data
        }
      });
    },
  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>