<template>
  <footer class="footer">
            <div class="container">
                 <div class="row text-center text-lg-start border-top">
                    <div class="col-12 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
                        <a href="mailto:support@softpital.in">Support</a>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                        <a href="https://softpital.in/terms-and-conditions/" target="_blank">Terms of Service</a>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                        <a href="https://softpital.in/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                        <a href="https://softpital.in/refund-policy/" target="_blank">Refund Policy</a>
                    </div>
                    <div class="col-12 col-lg-6 text-center text-lg-end">
                        <span class="text-muted">Copyright © Softpital 2024</span>
                    </div>
                </div>
            </div>
    </footer>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  methods:{
    ...mapActions("hospital",[]),

  }
}
</script>