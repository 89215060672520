<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <div class="main-panel">
        <div class="content-wrapper">

          <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="row">
                    <h4 class="page-header page-title  mb-md-0">Rooms/Wards</h4>
                </div>
                <div class="row">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb p-0">
                        <li class="breadcrumb-item"><router-link to="/rooms-wards">Room/Wards</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Room</li>
                        <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.id && this.$route.params.id != ''">{{ this.$route.params.id }}</li>
                        <li class="breadcrumb-item active" aria-current="page" v-else>Add Room</li>
                      </ol>
                    </nav>
                </div>
              </div>
          </div>

          <div class="row">
            <div class="col-12">
              <form class="form-sample">
                <div class="col-md-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">New Room</h4>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Ward</label>
                              <multiselect v-model="$v.typeform.ward_id.$model" :options="wardlists"
                                placeholder="Select Ward" label="name" track-by="id" :multiple="false"
                                :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                :preselect-first="true"></multiselect>

                            </div>

                            <div v-if="$v.typeform.ward_id.$error" class="invalid-feedback"
                              style="display: block !important;">
                              <span v-if="!$v.typeform.ward_id.required">Please select ward id</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Type</label>
                              <multiselect v-model="$v.typeform.type.$model" :options="type" :multiple="false"
                                :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                placeholder="Type" :preselect-first="true" :showLabels="false"></multiselect>
                              <div v-if="$v.typeform.type.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.type.required">Please select Type</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Name</label>
                              <input type="text" class="form-control" v-model="$v.typeform.room_name.$model"
                                :class="{ 'is-invalid': $v.typeform.room_name.$error }" name="room_name"
                                placeholder="Room Name" />
                              <div v-if="$v.typeform.room_name.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.room_name.required">Please enter room name</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Floor Number</label>
                              <input type="number" class="form-control" v-model="$v.typeform.floor_number.$model"
                                name="floor_number" placeholder="Floor Number"
                                :class="{ 'is-invalid': $v.typeform.floor_number.$error }" />
                              <div v-if="$v.typeform.floor_number.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.floor_number.required">Please enter floor number</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Capacity</label>
                              <input type="number" class="form-control" v-model="$v.typeform.capacity.$model"
                                :class="{ 'is-invalid': $v.typeform.capacity.$error }" name="capacity"
                                placeholder="Room Capacity" />
                              <div v-if="$v.typeform.capacity.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.capacity.required">Please enter room capacity</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="!this.$route.params.id">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">No Of Room</label>
                              <input type="number" class="form-control" v-model="$v.typeform.no_of_room_to_add.$model"
                                :class="{ 'is-invalid': $v.typeform.no_of_room_to_add.$error }" name="no_of_room_to_add"
                                placeholder="No Of Room" />
                              <h6><small class="text-muted">How many rooms you want to create at a time?</small></h6>
                              <div v-if="$v.typeform.no_of_room_to_add.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.no_of_room_to_add.required">Please enter no of room</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Rate Per Day</label>
                              <div class="input-group">
                                  <div class="input-group-append">
                                    <span class="input-group-text text-primary" v-html="currency_data.currency_symbol"></span>
                                  </div>
                                  <input type="number" class="form-control" v-model="$v.typeform.rate_per_day.$model"
                                          :class="{ 'is-invalid': $v.typeform.rate_per_day.$error }" name="rate_per_day"
                                          placeholder="Rate Per Day" />
                              </div>

                              <div v-if="$v.typeform.rate_per_day.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.rate_per_day.required">Please enter room rate per Day</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Description</label>
                              <textarea rows="5" type="text" class="form-control"
                                v-model="$v.typeform.description.$model" name="description" placeholder="Description"
                                :class="{ 'is-invalid': $v.typeform.description.$error }" />
                              <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.description.required">Please enter Description</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3" v-if="this.$route.params.id">
                          <div class="form-group row">
                            <div class="col-sm-12">
                              <label class="col-form-label">Occupancy Status</label>
                              <multiselect v-model="occupancystatus" :options="occupancy_status" :multiple="false"
                                :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                placeholder="Type" :preselect-first="true" :showLabels="false"></multiselect>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <b-button v-if="is_btn_spinner" variant="primary" disabled>
                          <b-spinner small type="grow"></b-spinner>
                          Loading...
                        </b-button>
                        <button v-else type="button"
                          @click="($route.params.id && $route.params.id != '' ? submitForm('update') : submitForm('add'))"
                          class="btn btn-gradient-primary me-2">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import VoerroTagsInput from '@voerro/vue-tagsinput';
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import {get_user_settings } from '../../helper/helper';

export default {
  name: 'AddROOM',
  title: string.PAGE_TITLE_ADD_MEDICINE,
  components: {
    NavBar,
    SideBar,
    VoerroTagsInput,
    Multiselect,
  },
  data() {
    return {
      wardlists: [],
      currency_data:null,
      status: ['Active', 'Inactive'],
      occupancy_status: ['Vacant', 'Occupied', 'Reserved'],
      type: ['ICU', 'MICU', 'SICU', 'WARD', 'Special Room', 'Twin Sharing Room', 'General Room', 'Recovery Room'],
      typeform: {
        room_name: '',
        type: '',
        capacity: '',
        no_of_room_to_add: '',
        ward_id: '',
        floor_number: '',
        rate_per_day: '',
        description: ''
        //    occupancy_status:'', 
      },
      is_btn_spinner: false,
      occupancystatus: '',

    }
  },
  validations: {
    typeform: {
      room_name: { required },
      type: {},
      floor_number: { required, numeric },
      capacity: { required, numeric },
      no_of_room_to_add: { numeric },
      ward_id: { required },
      rate_per_day: { required, numeric },
      description: { required }
    },
  },
  mounted() {
    this.wardlisting();
    if (this.$route.params.id) {
      this.getRoomData(this.$route.params.id);
    }
    this.currency_data = get_user_settings()
  },
  methods: {
    ...mapActions("hospital", ["addRoomData", "getRoomDetailsData", "editRoomDetailsData", "getWardListData"]),
    wardlisting(page) {
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.activePage = page;
        var bodyFormData = new FormData();

        this.getWardListData(bodyFormData).then((response) => {
          this.is_API_call_running = false;
          if (response.response_code == 200) {
            this.wardlists = response.data;
            this.listPages = response.no_of_pages;
          } else {
            this.errorMessage = response.message;
          }
        });
      }, 500)
    },
    submitForm(val) {
      this.is_btn_spinner = true;
      this.$v.$touch();
      if (!this.$v.typeform.$invalid) {
        var bodyFormData = new FormData();
        bodyFormData.append('name', this.typeform.room_name);
        bodyFormData.append('type', this.typeform.type);
        bodyFormData.append('floor_number', this.typeform.floor_number);
        bodyFormData.append('capacity', this.typeform.capacity);
        bodyFormData.append('ward_id', this.typeform.ward_id.id);
        bodyFormData.append('rate_per_day', this.typeform.rate_per_day);
        bodyFormData.append('description', this.typeform.description);
        if (this.typeform.no_of_room_to_add) {
            bodyFormData.append('no_of_room_to_add', this.typeform.no_of_room_to_add);
        }
        if (val == 'add') {
          this.addRoomData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
              this.is_btn_spinner = false;
              this.$toasted.success("Room added successfully", { duration: 2000, });
              this.$router.push({ name: 'rooms-wards' });
            } else {
              this.is_btn_spinner = false;
              this.$toasted.error(response.message, { duration: 2000, });
            }
          });
        } else if (val == 'update') {
          bodyFormData.append('room_id', this.$route.params.id);
          bodyFormData.append("occupancy_status", this.occupancystatus)
          this.editRoomDetailsData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
              this.is_btn_spinner = false;
              this.$toasted.success("Room updated successfully", { duration: 2000, });
              this.$router.push({ name: 'rooms-wards' });
            } else {
              this.is_btn_spinner = false;
              this.$toasted.error(response.message, { duration: 2000, });
            }
          });
        }
      } else {
        this.is_btn_spinner = false;
      }
    },
    getRoomData(id) {
      var bodyFormData = new FormData();
      bodyFormData.append('room_id', id);
      this.getRoomDetailsData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.typeform.room_name = response.data.name;
          this.typeform.type = response.data.type;
          this.typeform.no_of_room_to_add = response.data.no_of_room_to_add;
          this.typeform.capacity = response.data.capacity;
          this.typeform.floor_number = response.data.floor_number;
          this.typeform.ward_id = response.data.ward;
          this.typeform.rate_per_day = response.data.rate_per_day;
          this.occupancystatus = response.data.occupancy_status;
          this.typeform.description = response.data.description
        }
      })


    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
