export default {
    getOpdList: (data) => window.axios.post('get-opd-list/', data),
    getPatientsList: (data) => window.axios.post('get-patients-list/', data),
    getDoctorList: (data) => window.axios.post('get-doctors/', data),
    getCityList: (data) => window.axios.post('get-city-list/', data),
    registerOpd: (data) => window.axios.post('register-opd/', data),
    editPatient: (data) => window.axios.post('patient/edit-patient-details/', data),
    registerOpdAndPrint: (data) => window.axios.post('register-opd/', data, { responseType: 'blob' }),
    editOPDAndPrint: (data) => window.axios.post('edit-opd/', data, { responseType: 'blob' }),
    getAppointmentList: (data) => window.axios.post('get-appointment-list/', data),
    postAddDoctor: (data) => window.axios.post('add-doctor/', data),
    getAppointmentDetails: (data) => window.axios.post('get-appointment-details/', data),
    getPatientsByNumber: (data) => window.axios.post('get-patients-by_number/', data),
    getOPDDepartmentList: (data) => window.axios.post('department/get-opd-department-list/', data),
    postEditOpd: (data) => window.axios.post('edit-opd/', data),
    getDashboard: (data) => window.axios.post('get-dashboard/', data),
    addEditSettings: (data) => window.axios.post('add-edit-settings/', data),
    getSettings: (data) => window.axios.get('get-settings/', data),
    addCity: (data) => window.axios.post('add-city/', data),
    editDoctorDetails: (data) => window.axios.post('edit-doctor-details/', data),
    getCityDetails: (data) => window.axios.post('get-city-details/', data),
    editCityDetails: (data) => window.axios.post('edit-city-details/', data),
    adminLogin: (data) => window.axios.post('admin-login/', data),
    CheckLogout: (data) => window.axios.post('admin-login/', data),
    getOpdFees: (data) => window.axios.post('get-opd-fees/', data),
    getMedicineList: (data) => window.axios.post('get-medicine-list/', data),
    getICDEffectList: (data) => window.axios.post('icd10-disease/', data),
    getStatisticsList: (data) => window.axios.post('get-report/', data),
    getAddPrescription: (data) => window.axios.post('add-prescription/', data),
    addMedicine: (data) => window.axios.post('add-medicine/', data),
    editMedicineDetails: (data) => window.axios.post('edit-medicine-details/', data),
    getMedicineDetails: (data) => window.axios.post('get-medicine-details/', data),
    getPrescription: (data) => window.axios.post('get-prescription/', data),
    checkPatientNumber: (data) => window.axios.post('check-patient-number/', data),
    getChangelogList: (data) => window.axios.post('get-changelog/', data),
    getConcernDoctorList: (data) => window.axios.post('get-concern-doctors/', data),
    getOPDCityList: (data) => window.axios.post('get-opd-city-list/', data),
    getPrescriptionMedicineList: (data) => window.axios.post('get-prescription-medicine-list/', data),
    getPrescriptionReportList: (data) => window.axios.post('get-prescription-report-list/', data),
    resetAdminPassword: (data) => window.axios.post('admin-reset-password/', data),
    forgotPassword: (data) => window.axios.post('admin-forgot-password/', data),
    reportsData: (data) => window.axios.post('get-report/', data),
    exportReportsData: (data) => window.axios.post('get-report/', data, { responseType: 'blob' }),
    getDepartmentList: (data) => window.axios.post('department/get-department-list/', data),
    getDepartmentDetail: (data) => window.axios.post('department/get-department-details/', data),
    editDepartmentDetails: (data) => window.axios.post('department/update-department-details/', data),
    editDepartmentStatus: (data) => window.axios.post('department/update-department-status/', data),
    addDepartment: (data) => window.axios.post('department/add-department/', data),
    deleteDepartment: (data) => window.axios.post('department/delete-department/', data),

    getReportList: (data) => window.axios.post('get-report-list/', data),
    getReportDetails: (data) => window.axios.post('get-report-details/', data),
    addReportData: (data) => window.axios.post('add-report/', data),
    editReportData: (data) => window.axios.post('edit-report-details/', data),
    deleteReportData: (data) => window.axios.post('delete-report/', data),

    getEffectList: (data) => window.axios.post('get-effect-list/', data),
    getEffectDetails: (data) => window.axios.post('get-effect-details/', data),
    addEffectData: (data) => window.axios.post('add-effect/', data),
    editEffectData: (data) => window.axios.post('edit-effect-details/', data),
    deleteEffectData: (data) => window.axios.post('delete-effect/', data),

    logoutData: (data) => window.axios.post('logout/', data),

    getsubcriptionList: (data) => window.axios.get('subscription/get-plan/', data),
    redeemSubscription: (data) => window.axios.post('subscription/redeem-subscription/', data),

    getAdminDetails: (data) => window.axios.post('get-doctors-details/', data),

    updateBusinessDetails: (data) => window.axios.post('client/update-client-details/', data),
    updatePrescriptionBackground: (data) => window.axios.post('settings/update-prescription-background/', data),
    deletePrescriptionBackground: (data) => window.axios.post('settings/delete-prescription-background/', data),

    getCurrencies: (data) => window.axios.post('currency/get-currency-list/', data),

    getContactUsRequestList: (data) => window.axios.post('client-contact-us/get-contact-us-request/', data),
    getContactUsRequestDetails: (data) => window.axios.post('client-contact-us/get-contact-us-request-details/', data),

    printOPDDescData: (data) => window.axios.post('print-opd-prescription/', data, { responseType: 'blob' }),
    getRoomList: (data) => window.axios.post('roomward/get-room-list/', data),
    getRoomDetails: (data) => window.axios.post('roomward/get-room-details/', data),
    addRoom: (data) => window.axios.post('roomward/add-room/', data),
    getWardList: (data) => window.axios.post('roomward/get-ward-list/', data),
    addWard: (data) => window.axios.post('roomward/add-ward/', data),
    getWardDetails: (data) => window.axios.post('roomward/get-ward-details/', data),
    getIPDWardList: (data) => window.axios.post('roomward/get-ipd-ward-list/', data),
    deleteRoomData: (data) => window.axios.post('roomward/delete-room/', data),
    deleteWardData: (data) => window.axios.post('roomward/delete-ward/', data),
    getIpdList: (data) => window.axios.post('ipd/get-ipd-list/', data),
    getIPDRoomList: (data) => window.axios.post('roomward/get-ipd-room-list/', data),
    getIPDRoomBedList: (data) => window.axios.post('ipd/get-room-bed-number/', data),
    admitPatient: (data) => window.axios.post('ipd/admit-patient/', data),
    getIPDDetails: (data) => window.axios.post('ipd/get-ipd-details/', data),
    updateIPDDetails: (data) => window.axios.post('ipd/update-ipd/', data),
    getIPDTreatments: (data) => window.axios.post('ipd/get-ipd-treatment/', data),
    deleteTreatment: (data) => window.axios.post('ipd/delete-ipd-treatment/', data),
    deleteIPDReport: (data) => window.axios.post('ipd/delete-ipd-report/', data),
    addIPDTreatment: (data) => window.axios.post('ipd/add-ipd-treatment/', data),
    editIPDTreatment: (data) => window.axios.post('ipd/update-ipd-treatment/', data),
    addIPDReport: (data) => window.axios.post('ipd/add-ipd-report/', data),
    editIPDReport: (data) => window.axios.post('ipd/update-ipd-report/', data),
    getIPDHistory: (data) => window.axios.post('ipd/get-ipd-history/', data),
    closeIPDTreatment: (data) => window.axios.post('ipd/close-ipd-treatment/', data),
    getBillings: (data) => window.axios.post('billing/get-bills/', data),
    getPatientBilling: (data) => window.axios.post('billing/get-patient-bill/', data),
    payPatientBilling: (data) => window.axios.post('billing/pay-patient-bill/', data),
    paidPatientBilling: (data) => window.axios.post('billing/get-paid-bill/', data),
    getPaymentTransactions: (data) => window.axios.post('subscription/get-transactions/', data),
    getTimeZoneList: (data) => window.axios.post('settings/get-time-zones/', data),
    verifyPayment: (data) => window.axios.post('subscription/verify-payment/', data),
    downloadPaymentTransactionInvoice: (data) => window.axios.post('subscription/get-transaction-invoice/', data),
    loginHistory: (data) => window.axios.get('login-history/', data),

    getSubscriptionPlanList: (data) => window.axios.post('master-admin/get-subscriptions-list/', data),
    getSubscriptionPlanDetails: (data) => window.axios.post('master-admin/get-subscriptions-details/', data),
    addSubscriptionPlanList: (data) => window.axios.post('master-admin/add-subscription-plan/', data),
    updateSubscriptionPlanList: (data) => window.axios.put('master-admin/update-subscription-plan/', data),
    updateSubscriptionPlanFeature: (data) => window.axios.put('master-admin/update-subscription-feature/', data),
    updateSubscriptionPlanStatus: (data) => window.axios.put('master-admin/update-subscription-plan-status/', data),
    updateSubscriptionPlanFeatureStatus: (data) => window.axios.put('master-admin/update-subscription-feature-status/', data),
    deleteSubscriptionPlan: (data) => window.axios.delete('master-admin/delete-subscription-plan/', {data : data}),
    deleteSubscriptionPlanFeature: (data) => window.axios.delete('master-admin/delete-subscription-feature/', data),

    addUpdateInventoryCategory: (data) => window.axios.post('inventory/add-update-delete-category/', data),
    getInventoryCategory: (data) => window.axios.get('inventory/add-update-delete-category/', { params: data }),
    deleteInventoryCategory: (data) => window.axios.delete('inventory/add-update-delete-category/', {data : data}),
    addUpdateInventorySupplier: (data) => window.axios.post('inventory/add-update-delete-supplier/', data),
    getInventorySupplier: (data) => window.axios.get('inventory/add-update-delete-supplier/', { params: data }),
    deleteInventorySupplier: (data) => window.axios.delete('inventory/add-update-delete-supplier/', {data : data}),
    addUpdateInventoryItem: (data) => window.axios.post('inventory/add-update-delete-item/', data),
    getInventoryItem: (data) => window.axios.get('inventory/add-update-delete-item/', { params: data }),
    deleteInventoryItem: (data) => window.axios.delete('inventory/add-update-delete-item/', {data : data}),
    addUpdateInventoryPurchaseOrder: (data) => window.axios.post('inventory/add-update-delete-purchase-order/', data),
    getInventoryPurchaseOrder: (data) => window.axios.get('inventory/add-update-delete-purchase-order/', { params: data }),
    deleteInventoryPurchaseOrder: (data) => window.axios.delete('inventory/add-update-delete-purchase-order/', {data : data}),
    addUpdateInventoryStockTransaction: (data) => window.axios.post('inventory/add-update-delete-stock-transactions/', data),
    getInventoryStockTransaction: (data) => window.axios.get('inventory/add-update-delete-stock-transactions/', { params: data }),
    deleteInventoryStockTransaction: (data) => window.axios.delete('inventory/add-update-delete-stock-transactions/', {data : data}),
    addUpdateInventoryEquipmentMaintenance: (data) => window.axios.post('inventory/add-update-equipment-maintenance/', data),
    getInventoryEquipmentMaintenance: (data) => window.axios.get('inventory/add-update-equipment-maintenance/', { params: data }),
    deleteInventoryEquipmentMaintenance: (data) => window.axios.delete('inventory/add-update-equipment-maintenance/', {data : data}),

    getClientList: (data) => window.axios.post('master-admin/get-clients-list/', data),
    getClientUserList: (data) => window.axios.post('master-admin/get-client-user/', data),
    getClientSubscription: (data) => window.axios.post('master-admin/get-client-subscriptions/', data),
    updateClientSubscription: (data) => window.axios.post('master-admin/update-client-subscription/', data),
    getClientTransaction: (data) => window.axios.post('master-admin/get-client-transactions/', data),
    getClientDashboard: (data) => window.axios.post('master-admin/get-client-dashboard/', data),
    getMasterAdminDashboard: (data) => window.axios.post('master-admin/get-dashboard/', data),
    getMasterAdminRevenue: (data) => window.axios.post('master-admin/get-revenue/', data),
    updateClientDetails: (data) => window.axios.put('master-admin/update-client-details/', data),
    addClientUser: (data) => window.axios.post('master-admin/add-new-client/', data),
    updateClientUserDetails: (data) => window.axios.post('master-admin/update-client-user-details/', data),
    getAppointmentDepartmentList: (data) => window.axios.post('department/get-appointment-department-list/', data),
    loginClientUser: (data) => window.axios.post('master-admin/login-client-user/', data),
    updateCurrencyStatus: (data) => window.axios.post('currency/update-currency-status/', data),
    insertCurrency: (data) => window.axios.get('currency/insert-currency/', data),
    getWebSettings: (data) => window.axios.get('settings/get-admin-web-settings/', data),
    updateWebSettings: (data) => window.axios.post('settings/update-admin-web-settings/', data),
    deleteWebImage: (data) => window.axios.post('settings/delete-web-images/', data),
    addAndVerifyDomain: (data) => window.axios.post('settings/add-update-verify-domain/', data),
    getClientDomain: (data) => window.axios.post('settings/get-client-domain/', data),
    deleteProfilePic: (data) => window.axios.post('doctor/delete-doctor-profile-pic/', data),
}