<template>
    <div class="row">

        <div class="col-md-12 mt-4 text-center" v-if="is_btn_spinner">
            <b-spinner></b-spinner>
        </div>

        <div class="col-lg-12 text-center" v-if="errorMessage">
            <br/><br/><br/>
            <small class="text-muted">{{ errorMessage }}</small>
            <br/><br/><br/>
        </div>

        <div class="row">
          <div class="col-md-5">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Your Own Domain</label>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="domain" placeholder="softpital.in">
                  <div class="input-group-append">
                    <span v-if="domain_verified != 'Yes'" class="input-group-text btn btn-gradient-primary"
                            style="color:#fff" @click="addAndVerifyDomain()">Save & Verify </span>
                  </div>
                </div>
              </div>
              <span v-if="domain_verified == 'Yes'" class="text-success">Your domain is verified</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
            <div class="page-header">
                <h4 class="page-title">How to Bind Website to Own Domain</h4>
            </div>
        </div>

        <div class="row">

            <ul class="list-ticked ms-2">
                <li><b>Step 1: Log In to Your Domain Registrar</b></li>

                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li>Log in to the website where you bought your domain (e.g., GoDaddy, Namecheap, Google Domains, etc.).</li>
                            <li>Open a browser and go to the website where you registered your domain.</li>
                            <li>Log in to the account using your username and password.</li>
                        </ul>
                        <br/>
                    </div>

                <li><b>Step 2: Find the DNS Settings (or DNS Management)</b></li>

                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li>Find the section where you can manage domain's DNS (Domain Name System) settings.</li>
                            <li>Look for a section called "DNS Settings", "DNS Management", or "Manage Domains".</li>
                            <li>This is usually under the "Domain Management" or "My Domains" area.</li>
                        </ul>
                        <br/>
                    </div>

                <li><b>Step 3: Locate the CNAME Record Section</b></li>

                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li>After accessing the DNS management page, look for a section where you can add DNS records. This is usually labeled "Add DNS Record" or "Add CNAME Record".</li>
                            <li>Be careful not to delete or modify any existing DNS records unless instructed to do so.</li>
                        </ul>
                        <br/>
                    </div>

                <li><b>Step 4: Add a New CNAME Record</b></li>

                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li>Now, add a new CNAME (Canonical Name) record to point your domain to <b>softpital-landing-page.netlify.app</b> web application.</li>
                            <li>In the DNS settings, select the option to Add a new record.</li>
                            <li>For the Record Type, select CNAME.</li>
                        </ul>
                        <br/>
                    </div>

                <li><b>Step 5: Enter the Required Information</b></li>

                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li><b>Name (or Host):</b> This is the part before the domain name. For example, if you want to point www.mydomain.com to your site, you should enter www in this field.</li>
                            <li>Example: If you want to use www.mydomain.com, then enter www here.</li>
                            <li><b>Value (or Points To / Target): softpital-landing-page.netlify.app.</b></li>
                            <li>Eaxmple:
                                <br/>
                                Name: www
                                <br/>
                                Value: softpital-landing-page.netlify.app.
                            </li>
                        </ul>
                        <br/>
                    </div>

                <li><b>Step 6: Save the CNAME Record</b></li>

                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li>Click the Save or Add Record button to apply the new CNAME record.</li>
                        </ul>
                        <br/>
                    </div>

                <li><b>Step 7: Wait for DNS Propagation</b></li>

                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li>DNS changes can take some time to propagate across the internet. Typically, it can take anywhere from a few minutes to 48 hours for the new CNAME record to work.</li>
                        </ul>
                        <br/>
                    </div>


                <li><b>Step 8: Test the Domain</b></li>

                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li>Go to browser and type your domain (e.g., www.mydomain.com).</li>
                            <li>If everything was done correctly, the website should now load your web application hosted on your platform.</li>
                        </ul>
                        <br/>
                    </div>
            </ul>
        </div>

        <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
            <div class="page-header">
                <h4 class="page-title">Contacting Support if You Need Help</h4>
            </div>
        </div>

        <div class="row">

            <ul class="list-ticked ms-2">
                <li><b>Step 1: Contact Us</b></li>
                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li>If you’re still having trouble, you can also reach out to the support team.</li>
                            <li>Look for a "Help", "Support", or "Contact" section within the platform's website or dashboard.</li>
                            <li>Or you can write us on <a href="mailto:support@softpital.in">here</a></li>
                        </ul>
                        <br/>
                    </div>
                </li>
                <li><b>Step 2: Contact Your Domain Registrar's Support Team:</b></li>
                    <div class="ms-5">
                        <br/>
                        <ul class="list-arrow">
                            <li>Each domain provider (e.g., GoDaddy, Namecheap, Google Domains) has a support team that can help with DNS setup and domain management.</li>
                            <li>Look for a "Support", "Help", or "Contact Us" section on the website where you registered your domain.</li>
                            <li>Many domain providers offer 24/7 customer support through live chat, email, or phone. You can reach out to them and explain that you need help setting up a CNAME record for your domain.</li>
                        </ul>
                        <br/>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import string from "../../constant/strings.js"
import Multiselect from 'vue-multiselect';

export default {
    name:'settings',
    title: string.PAGE_TITLE_SETTINGS,
    components: {
      NavBar,
      Footer,
      Multiselect,
      SideBar
    },
    data() {
        return {
          is_btn_spinner:false,
          is_api_call_running:false,
          domain:'',
          domain_verified:"",
          errorMessage:"",
        }
    },
  mounted(){
       this.getClientDomain()
  },
  methods:{
    ...mapActions('hospital',['getClientDomainData', 'addAndVerifyDomainData']),

    getClientDomain() {
        var bodyFormData = new FormData();
        this.getClientDomainData().then((response) => {
        if (response.response_code == 200) {
            this.domain = response.domain
            this.domain_verified = response.is_domain_verified
        } else {
            this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },

    addAndVerifyDomain(){
        var bodyFormData = new FormData();
        bodyFormData.append('domain', this.domain);
        this.addAndVerifyDomainData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
            this.domain = response.domain
            this.domain_verified = response.is_domain_verified
            this.$toasted.success(response.message, {duration: 2000,});
        } else {
            this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>