<template>
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body nw-opd">
                    <div class="row me-1">
                        <div class="col-lg-4">
                            <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="search" id="search" autocomplete="off" v-on:keyup="getInventoryCategoryAPI(1)">
                        </div>
                        <h4 class="col-lg-6"></h4>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(category,i) in categories" :key="i">
                                    <td>{{category.id}}</td>
                                    <td>{{category.name}}</td>
                                    <td>{{category.description}}</td>
                                    <td>
                                        <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon" @click="openEditCategoryDialog(category)"><i class="mdi mdi-pencil"></i></button> &nbsp;&nbsp;
                                        <button type="button" class="btn btn-outline-secondary btn-rounded btn-icon" @click="deleteInventoryCategoryAPI(category.id, category.name)" ><i class="mdi mdi-delete"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="is_API_call_running" class="text-center mt-5">
                        <b-spinner label=""></b-spinner>
                    </div>
                    <div class="row" v-if="!is_API_call_running && categories.length == 0" >
                        <div class="col-lg-12 text-center">
                            <br/><br/><br/>
                            <small class="text-muted">{{ errorMessage }}</small>
                            <br/><br/><br/>
                        </div>
                    </div>
                    <div class="b-pagination-outer" v-if="listPages > 1">
                        <ul id="border-pagination">
                            <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                            <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getInventoryCategoryAPI(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                            <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import string from "../../constant/strings.js"
import { EventBus } from '@/event-bus';
import Swal from 'sweetalert2'

export default {
    name:'settings',
    title: string.PAGE_TITLE_SETTINGS,
    components: {
      NavBar,
      Footer,
      SideBar,
    },
    data() {
        return {
            categories:[],
            is_API_call_running: false,
            listPages:0,
            activePage:1,
            searchtext:'',
            myTimer:null,
            errorMessage : 'No Data Found.',
            status_list:['All','Active', 'Inactive'],
            filter_status: "All",
            dialogResult: null,
        }
    },
    mounted(){
        this.getInventoryCategoryAPI(this.activePage);
    },
    created() {
        // Listen for the dialog result
        EventBus.$on('dialog-result', (result) => {
            this.dialogResult = result;
            if(this.dialogResult.status) {
                this.getInventoryCategoryAPI(1);
            }
        });
    },
  methods:{
    ...mapActions('hospital',["getInventoryCategory", "deleteInventoryCategory"]),

    getInventoryCategoryAPI(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.categories = [];
        let queryParams = {
            name: this.searchtext,
            page: page,
        };
        this.getInventoryCategory(queryParams).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
                this.categories = response.data;
                this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
        });
      }, 500)
    },

    previewClick(){
          this.activePage--;
          if(this.activePage >= 1){
            this.getInventoryCategoryAPI(this.activePage);
          }else{
            this.activePage = 1;
          }
        },

    nextClick(){
       this.activePage++;
       if(this.activePage <= this.listPages){
            this.getInventoryCategoryAPI(this.activePage);
       }else{
            this.activePage = this.listPages;
       }
    },
    openEditCategoryDialog(category) {
        console.log("a")
        const payload = { data: category };
        EventBus.$emit('open-dialog', payload); // Trigger the dialog opening
    },
    deleteInventoryCategoryAPI(id, category_name, index) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + category_name + string.CATEGORY,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('item_category_id', id);
            this.deleteInventoryCategory(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.categories.length;i++){
                        if(id == this.categories[i].id) {
                            this.categories.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    },
  }
}
</script>
