<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">      

      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="row">
              <h4 class="page-header page-title  mb-md-0">OPDs</h4>
          </div>
          <div class="row">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0">
                  <li class="breadcrumb-item"><router-link to="/opdslist">OPDs List</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.id != null && this.$route.params.id != ''">{{this.$route.params.id}}</li>
                  <li class="breadcrumb-item active" aria-current="page" v-else>Add Patients</li>
                </ol>
              </nav>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <form class="form-sample">
            <div class="col-md-12 grid-margin stretch-card">              
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Patient Details</h4>
                  <div class="row">
                    <div class="col-md-4">
                        <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Patient Name</label>
                              <input type="text" class="form-control" v-model="$v.typeform.patient_name.$model" :class="{ 'is-invalid':  $v.typeform.patient_name.$error  }" name="patient_name" placeholder="Patient Name"/>
                              <div v-if="$v.typeform.patient_name.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.patient_name.required">Please enter patient name</span>
                              </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient Mobile</label>
                          <input type="tel" maxlength="16" class="form-control" v-model="$v.typeform.patient_mobile.$model" name="patient_mobile" placeholder="Patient Mobile" :class="{ 'is-invalid':  $v.typeform.patient_mobile.$error  }"/>
                          <div v-if="$v.typeform.patient_mobile.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.patient_mobile.required">Please enter patient mobile</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient Email</label>
                          <input type="email" class="form-control" v-model="$v.typeform.patient_email.$model" name="patient_email" placeholder="Patient Email" :class="{ 'is-invalid':  $v.typeform.patient_email.$error  }"/>
                          <div v-if="$v.typeform.patient_email.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.patient_email.required">Please enter patient email</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient Age</label>
                          <div class="input-group">
                              <input type="number" maxlength="3" class="form-control" v-model="$v.typeform.age.$model" :class="{ 'is-invalid':  $v.typeform.age.$error  }" name="age" placeholder="Age"/>
                              <div class="input-group-append">
                                  <span class="input-group-text">Years</span>
                              </div>
                          </div>
                          <div v-if="$v.typeform.age.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.age.required">Please enter patient age</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Gender</label>
                           <multiselect v-model="$v.typeform.gender.$model" :options="genderlist" :multiple="false"
                                :close-on-select="true" 
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select marital Status" 
                                 :preselect-first="true" :showLabels="false"></multiselect>

                          <div v-if="$v.typeform.gender.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.gender.required">Please select gender</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group row mb-2">
                            <div class="col-sm-12">
                                <label class="col-form-label">Marital Status</label>
                                 <multiselect v-model="$v.typeform.patient_marital_status.$model" :options="patient_marital_status_list" :multiple="false"
                                :close-on-select="true"  :showLabels="false"
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select marital Status" 
                                 :preselect-first="true"></multiselect>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Address</label>
                          <textarea rows="5" type="text" class="form-control" v-model="$v.typeform.address.$model" name="address" placeholder="Address" :class="{ 'is-invalid':  $v.typeform.address.$error }"/>
                          <div v-if="$v.typeform.address.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.address.required">Please enter address</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">City</label>
                            <multiselect v-model="selectedCity" :options="cities" :multiple="false"
                            :close-on-select="true" :showLabels="false"
                           :clear-on-select="false" :preserve-search="true" placeholder="Please select city" 
                           label="city_name" track-by="city_name" :preselect-first="true"></multiselect>
                          <div v-if="$v.typeform.city.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.city.required">Please select city</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Postal Code</label>
                         <input type="text" maxlength="8" class="form-control" v-model="$v.typeform.patient_postal_code.$model" :class="{ 'is-invalid':  $v.typeform.patient_postal_code.$error  }" name="patient_postal_code" placeholder="Postal Code"/>
                          <div v-if="$v.typeform.patient_postal_code.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.patient_postal_code.required">Please enter patient postal code</span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <h4 class="card-title"><br/>Emergency Contact Details</h4>
            

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Contact Person Name</label>
                          <input type="text" class="form-control" v-model="$v.typeform.emergency_contact_name.$model" :class="{ 'is-invalid':  $v.typeform.emergency_contact_name.$error  }" name="emergency_contact_name" placeholder="Contact Person Name"/>
                          <div v-if="$v.typeform.emergency_contact_name.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.emergency_contact_name.required">Please enter contact person name</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Contact Person Number</label>
                          <input type="tel" maxlength="16" class="form-control" v-model="$v.typeform.emergency_contact_number.$model" name="emergency_contact_number" placeholder="Contact Person Number" :class="{ 'is-invalid':  $v.typeform.emergency_contact_number.$error  }"/>
                          <div v-if="$v.typeform.emergency_contact_number.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.emergency_contact_number.required">Please enter contact person number</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Relation With:</label>
                             <multiselect v-model="$v.typeform.emergency_contact_contact_relationship.$model" :options="relationswith" :multiple="false"
                                :close-on-select="true"  :showLabels="false"
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select Relation With Patient" 
                                 :preselect-first="true"></multiselect>
                          <div v-if="$v.typeform.emergency_contact_contact_relationship.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.emergency_contact_contact_relationship.required">Please select relationship</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Family Doctor Name</label>
                          <input type="text" class="form-control" v-model="$v.typeform.family_doctor_name.$model" name="family_doctor_name" placeholder="Family Doctor Name" :class="{ 'is-invalid':  $v.typeform.family_doctor_name.$error  }"/>
                          <div v-if="$v.typeform.family_doctor_name.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.family_doctor_name.required">Please enter family doctor name</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Family Doctor Number</label>
                          <input type="tel" maxlength="16" class="form-control" v-model="$v.typeform.family_doctor_contact_number.$model" name="family_doctor_contact_number" placeholder="Family Doctor Number" :class="{ 'is-invalid':  $v.typeform.family_doctor_contact_number.$error  }"/>
                          <div v-if="$v.typeform.family_doctor_contact_number.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.family_doctor_contact_number.required">Please enter family doctor number</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Preferred Pharmacy Store</label>
                          <input type="text" class="form-control" v-model="$v.typeform.preferred_pharmacy.$model" name="preferred_pharmacy" placeholder="Pharmacy Store" :class="{ 'is-invalid':  $v.typeform.preferred_pharmacy.$error  }"/>
                          <div v-if="$v.typeform.preferred_pharmacy.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.preferred_pharmacy.required">Please enter family doctor name</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Pharmacy Store Number</label>
                          <input type="tel" maxlength="16" class="form-control" v-model="$v.typeform.preferred_pharmacy_contact_number.$model" name="preferred_pharmacy_contact_number" placeholder="Pharmacy Store Number" :class="{ 'is-invalid':  $v.typeform.preferred_pharmacy_contact_number.$error  }"/>
                          <div v-if="$v.typeform.preferred_pharmacy_contact_number.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.preferred_pharmacy_contact_number.required">Please Enter Pharmacy Store Number</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Current Medicine Details</label>
                            <textarea rows="5" type="text" class="form-control" v-model="$v.typeform.current_medicine_details.$model" name="current_medicine_details"
                            placeholder="Current Medicine Details" :class="{ 'is-invalid':  $v.typeform.current_medicine_details.$error }"/>
                          <div v-if="$v.typeform.current_medicine_details.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.current_medicine_details.required">Please Enter Current Medicine Details</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Allergy</label>
                            <textarea rows="5" type="text" class="form-control" v-model="$v.typeform.patient_allergies.$model" name="allergy"
                            placeholder="Allergy" :class="{ 'is-invalid':  $v.typeform.patient_allergies.$error }"/>
                          <div v-if="$v.typeform.patient_allergies.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.patient_allergies.required">Please Enter Current Medicine Details</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h4 class="card-title"><br/>Insurance Details</h4>
                    

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Insurance Company Name</label>
                          <input type="text" class="form-control" v-model="$v.typeform.insurance_company_name.$model" :class="{ 'is-invalid':  $v.typeform.insurance_company_name.$error  }" name="insurance_company_name" placeholder="Patient Insurance Company Name"/>
                          <div v-if="$v.typeform.insurance_company_name.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.insurance_company_name.required">Please Enter Insurance Company Name</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Insurance Number</label>
                          <input type="text" class="form-control" v-model="$v.typeform.insurance_id_number.$model" name="insurance_id_number" placeholder="Insurance Number" :class="{ 'is-invalid':  $v.typeform.insurance_id_number.$error  }"/>
                          <div v-if="$v.typeform.insurance_id_number.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.insurance_id_number.required">Please Enter Insurance Number</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Policy Holder Date Of Birth</label>
            			  <div class="form-control">
                            <DatePicker class="date-picker"
                                v-model="$v.typeform.insurance_policy_holder_date_of_birth.$model"
                                    format="DD-MM-YYYY"
                                  	placeholder="Date of Birth"
                                  	:disabled-date="disableDatesAfterMax"/>
                          </div>
                          <div v-if="$v.typeform.insurance_policy_holder_date_of_birth.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.insurance_policy_holder_date_of_birth.required">Please Enter Policy Holder Date Of Birth</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Policy Holder Name</label>
                          <input type="text" class="form-control" v-model="$v.typeform.insurance_policy_holder_name.$model" name="patient_email" placeholder="Policy Holder Name" :class="{ 'is-invalid':  $v.typeform.insurance_policy_holder_name.$error  }"/>
                          <div v-if="$v.typeform.insurance_policy_holder_name.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.insurance_policy_holder_name.required">Please Enter Patient Policy Holder Name</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="this.$route.params.id != null && this.$route.params.id != ''" >
                    <div class="col-md-4">
                        <b-button v-if="is_btn_spinner" variant="primary" disabled>
                          <b-spinner small type="grow"></b-spinner>Loading...
                        </b-button>
                        <button v-else type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">Edit Patient Details</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.$route.params.id == null || this.$route.params.id == ''" class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">OPD Details</h4>
                  
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="col-sm-12">
                          <label class="col-form-label">Fees</label>
                          <div class="input-group">
                            <div class="input-group-append">
                              <span class="input-group-text text-primary" v-html="client_currency"></span>
                            </div>
                            <input type="text" class="col-sm-10 form-control" v-model="typeform.fees" name="fees" placeholder="Fees" disabled/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Disease</label>
                          <input type="text" class="form-control" v-model="$v.typeform.disease.$model" name="Disease" placeholder="Disease"/>
                        </div>
                      </div>
                    </div>
                     <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Department</label>
                        
                              <multiselect v-model="department_id" :options="department" :multiple="false"
                            :close-on-select="true"  @input="onSelectDepartment"
                           :clear-on-select="false"  :showLabels="false" :preserve-search="true" placeholder="Please select Department"
                           label="department_name" track-by="department_name" :preselect-first="false"></multiselect>
                        
                          <div v-if="$v.typeform.department_id.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.department_id.required">Please select Department</span>
                          </div>
                        </div>
                      </div>
                    </div> 
                    <div class="col-md-3">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Concern Doctor</label>
                          <multiselect v-model="$v.typeform.concern_doctor.$model" :options="doctors" :multiple="false"
                              :close-on-select="true" label="name"
                              :clear-on-select="false" :preserve-search="true" placeholder="Please select doctor"
                               :preselect-first="true" :showLabels="false"></multiselect>
                          <div v-if="$v.typeform.concern_doctor.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.concern_doctor.required">Please select doctor</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient BP</label>
                          <input type="number" class="form-control" v-model="$v.typeform.patient_BP.$model" name="patient_BP" placeholder="Patient BP"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient Weight</label>
                          <input type="number" class="form-control" v-model="$v.typeform.patient_weight.$model" name="patient_weight" placeholder="Patient Weight"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Patient Temperature</label>
                          <input type="number" class="form-control" v-model="$v.typeform.patient_temperature.$model" name="patient_temperature" placeholder="Patient Temperature"/>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                          <b-button v-if="is_btn_spinner" variant="primary" disabled>
                            <b-spinner small type="grow"></b-spinner>Loading...
                          </b-button>
                          <div v-else>
                            <button type="button" @click="submitForm(false)" class="btn btn-gradient-primary me-2">Submit</button>
                            <button type="button" @click="submitForm(true)" class="btn btn-gradient-primary me-2">Submit & Print</button>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </form>                               
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import VoerroTagsInput from '@voerro/vue-tagsinput';
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import moment from "moment";
import string from "../../constant/strings.js"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name:'AddOPD',
    title: string.PAGE_TITLE_ADD_OPD,
    components: {
      NavBar,
Footer,
      SideBar,
      VoerroTagsInput,
      Multiselect,
      DateRangePicker,
      DatePicker,
    },
    data() {
    return {
      moment,
      typeform:{
        patient_name:'',
        patient_mobile:'',
        age:'',
        gender:'',
        address:'',
        city:'',
        fees:'',
        disease:'',
        concern_doctor:'',
        patient_BP:'',
        patient_weight:'',
        patient_temperature:'',
        patient_marital_status:'',
        patient_postal_code:'',
        patient_email:'',
        insurance_company_name:'',
        insurance_id_number:'',
        insurance_policy_holder_name:'',
        insurance_policy_holder_date_of_birth:'',
        family_doctor_name:'',
        emergency_contact_number:'',
        emergency_contact_name:'',
        family_doctor_contact_number:'',
        preferred_pharmacy:'',
        preferred_pharmacy_contact_number:'',
        current_medicine_details:'',
        patient_allergies:'',
        emergency_contact_contact_relationship:'',
        relations:['Mother','Father','Sister','Brother','Family','Grand Father','Grand Mother','Uncle','Aunty','Son','Daughter','Wife','Husband','Neighbour','Niece','Colleague'],
        department_id:''
      },
      patient_marital_status_list:['Yes','No'],
      genderlist:['Male','Female'],
      relationswith:['Mother','Father','Sister','Brother','Family','Grand Father','Grand Mother','Uncle','Aunty','Son','Daughter','Wife','Husband','Neighbour','Niece','Colleague'],
      patient_id:'',
      department_id:'',
      formDataApi:'',
      department:[],
      selectedCity: {},
      selected_department:{},
      doctors:[],
      cities:[],
      opd:[],
      is_btn_spinner:false,
      client_currency:'',
    }
    },
    validations: {
    typeform: {
      patient_id:{},
      patient_name:{required},
      patient_mobile:{required,numeric},
      age:{required,numeric},
      gender:{required},
      address:{required},
      city:{required},
      disease:{},
      concern_doctor:{required},
      patient_BP:{},
      patient_weight:{},
      patient_temperature:{},
      patient_marital_status:{},
      patient_postal_code:{required},
      patient_email:{required},
      insurance_company_name:{},
      insurance_id_number:{},
      insurance_policy_holder_name:{},
      insurance_policy_holder_date_of_birth:'',
      family_doctor_name:{},
      emergency_contact_number:{required,numeric},
      emergency_contact_name:{required},
      family_doctor_contact_number:{},
      preferred_pharmacy:{},
      preferred_pharmacy_contact_number:{},
      current_medicine_details:{},
      patient_allergies:{},
      emergency_contact_contact_relationship:{required},
      department_id:{required}
    },
  },
  mounted(){
    this.getDepartment();
    this.getopdfess();
    this.getCityNames();  
    if(this.$route.params.id){
      this.getPatientData(this.$route.params.id);
    }
   
    
  },
  methods:{
    ...mapActions("hospital",["editPatientData","registerOpdData","getPatientsByNumberData","getOPDDepartmentListData","getConcernDoctorListData","getOpdFeesData","getOPDCityListData", "registerOpdAndPrintData", "editOpdAndPrintData"]),
    getDepartment(){
       var bodyFormData = new FormData();
     
         this.getOPDDepartmentListData(bodyFormData).then((response) => {
          console.log("response",response);
          if (response.response_code == 200) { 
            this.department = response.data;
          }
         });

    },
    onSelectDepartment(){
         this.getDoctorList();   
    },
    getPatientData(id){
      var bodyFormData = new FormData();
      bodyFormData.append('filter_value', parseInt(this.$route.params.id));
      this.getPatientsByNumberData(bodyFormData).then((response) => {  
          if (response.response_code == 200) { 
        this.department_id = response.data.patient_opds[0];
        this.opd = response.data.patient_opds[0];
             this.getDoctorList();   
            this.patient_id = response.data.patient_id;
            this.typeform.patient_name = response.data.patient_name;
        this.typeform.patient_mobile = response.data.patient_mobile_number;
        this.typeform.patient_email = response.data.patient_email;
        this.typeform.age = response.data.patient_age;
        this.typeform.gender = response.data.patient_gender;
        this.typeform.address = response.data.patient_address;
        this.typeform.patient_city = response.data.patient_city_obj;
        this.selectedCity = response.data.patient_city_obj;
        this.typeform.disease = response.data.patient_disease
        this.typeform.fees = response.data.patient_last_opd.opd_fees;
        this.typeform.disease = response.data.patient_last_opd.opd_disease;
        this.typeform.concern_doctor = response.data.patient_last_opd.opd_concern_doctor_id;
        this.typeform.patient_BP = response.data.patient_last_opd.opd_patient_bp;
        this.typeform.patient_weight = response.data.patient_last_opd.opd_patient_weight
        this.typeform.patient_temperature = response.data.patient_last_opd.opd_patient_temperature;
        this.typeform.patient_marital_status = response.data.patient_marital_status;
        this.typeform.patient_postal_code = response.data.patient_postal_code;
        this.typeform.insurance_company_name = response.data.insurance_company_name
        this.typeform.insurance_id_number = response.data.insurance_id_number;
        this.typeform.insurance_policy_holder_name = response.data.insurance_policy_holder_name;
        this.typeform.insurance_policy_holder_date_of_birth = new Date(response.data.insurance_policy_holder_date_of_birth);
        this.typeform.family_doctor_name = response.data.family_doctor_name;
        this.typeform.emergency_contact_number = response.data.emergency_contact_number;
        this.typeform.emergency_contact_name = response.data.emergency_contact_name;
        this.typeform.family_doctor_contact_number = response.data.family_doctor_contact_number;
        this.typeform.preferred_pharmacy = response.data.preferred_pharmacy;
        this.typeform.preferred_pharmacy_contact_number = response.data.preferred_pharmacy_contact_number;
        this.typeform.current_medicine_details = response.data.current_medicine_details;
        this.typeform.patient_allergies = response.data.patient_allergies;
        this.typeform.emergency_contact_contact_relationship = response.data.emergency_contact_contact_relationship;
        
      
          }
        })
      
    },
    submitForm(is_print){
      this.typeform.city = this.selectedCity.city_name;
      this.typeform.department_id = this.department_id.id;//this.selected_department.id

      if(this.$route.params.id){
          this.typeform.patient_id = this.$route.params.id;
      }

      if (!this.typeform.gender) {
          this.$toasted.error("Please select gender", {duration: 2000,});
          return;
      } else if(!this.typeform.patient_marital_status) {
          this.$toasted.error("Please select marital status", {duration: 2000,});
          return;
      } else if(!this.typeform.city) {
          this.$toasted.error("Please select city", {duration: 2000,});
          return;
      } else if(!this.typeform.emergency_contact_contact_relationship) {
          this.$toasted.error("Please select relationship", {duration: 2000,});
          return;
      } else if(!this.typeform.department_id) {
          this.$toasted.error("Please select department", {duration: 2000,});
          return;
      } else if(!this.typeform.concern_doctor) {
          this.$toasted.error("Please select concern doctor", {duration: 2000,});
          return;
      }
      this.$v.$touch();

      if (!this.$v.typeform.$invalid) {
        this.is_btn_spinner = true;

        let formData = {
        patient_name: this.typeform.patient_name,
        patient_mobile_number:this.typeform.patient_mobile,
        patient_email:this.typeform.patient_email,
        patient_age:this.typeform.age,
        patient_gender:this.typeform.gender,
        patient_address:this.typeform.address,
        patient_city:this.typeform.city,
        patient_disease:this.typeform.disease,
        opd_fees:this.typeform.fees,
        opd_disease:this.typeform.disease,
        opd_concern_doctor_id:this.typeform.concern_doctor.id,
        opd_patient_bp:this.typeform.patient_BP,
        opd_patient_weight:this.typeform.patient_weight,
        opd_patient_temperature:this.typeform.patient_temperature,
        patient_marital_status:this.typeform.patient_marital_status,
        patient_postal_code:this.typeform.patient_postal_code,
        insurance_company_name:this.typeform.insurance_company_name,
        insurance_id_number:this.typeform.insurance_id_number,
        insurance_policy_holder_name:this.typeform.insurance_policy_holder_name,
        insurance_policy_holder_date_of_birth: moment(this.typeform.insurance_policy_holder_date_of_birth).format('YYYY-MM-DD'),
        family_doctor_name:this.typeform.family_doctor_name,
        emergency_contact_number:this.typeform.emergency_contact_number,
        emergency_contact_name:this.typeform.emergency_contact_name,
        family_doctor_contact_number:this.typeform.family_doctor_contact_number,
        preferred_pharmacy:this.typeform.preferred_pharmacy,
        preferred_pharmacy_contact_number:this.typeform.preferred_pharmacy_contact_number,
        current_medicine_details:this.typeform.current_medicine_details,
        patient_allergies:this.typeform.patient_allergies,
        emergency_contact_contact_relationship:this.typeform.emergency_contact_contact_relationship,
        department_id:this.department_id.id        
      };
     
      if(this.$route.params.id){
          if(is_print) {
            this.formDataApi = this.editOpdAndPrintData;
            formData ={
                ...formData,
                patient_id:this.patient_id,
                save_and_print:true,
                 department_id:this.department_id.department_id
              };
          } else {
          formData ={
              ...formData,
              patient_id:this.patient_id,
               department_id:this.department_id.department_id
            };
            this.formDataApi = this.editPatientData;
          }
      }else{
        if(is_print) {
            formData ={
                ...formData,
                save_and_print:true,
              };
         this.formDataApi = this.registerOpdAndPrintData;
        } else {
          this.formDataApi = this.registerOpdData;
        }
      }

    this.formDataApi(formData).then((response) => {
        console.log("RR", response)
        if (response.response_code == 200) {
          this.is_btn_spinner = false;
            if(this.$route.params.id){
              this.$toasted.success("Patient Details updated successfully", {duration: 2000,});
          this.$router.push({ name: 'patients' });
            }else{
              this.$toasted.success("OPD added successfully", {duration: 2000,});
          this.$router.push({ name: 'opdslist' });
            }
          
        }else{
          this.is_btn_spinner = false;
          this.$toasted.error("Something Went wrong", {duration: 2000,});
        }
      });
      } else{
        this.$toasted.error("Check errors", {duration: 2000,});
        this.is_btn_spinner = false;
      }
    },

    getDoctorList(){
      this.doctors = []
      this.typeform.concern_doctor = ''
      if(this.$route.params.id){
        this.department_id.id = this.department_id.department_id;
      }
      var bodyFormData = new FormData();
      -bodyFormData.append('filter_value', '');
      bodyFormData.append('department_id',this.department_id.id);
      this.getConcernDoctorListData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.doctors = response.data;
        }
      });
    },
    getopdfess(){
      this.getOpdFeesData().then((response) => {
        if (response.response_code == 200) {
          this.typeform.fees = response.opd_fees;
          this.client_currency = response.client_currency;
        } else if(response.response_code == 105) {
            this.$toasted.error(response.message, {duration: 2000,});
            this.$router.push({ name: 'settings' });
        }
      });
    },
    getCityNames(){
      var bodyFormData = new FormData();
      bodyFormData.append('city_name', '');
      bodyFormData.append('filter_status', 'Active');
      this.getOPDCityListData(bodyFormData).then((response) => {
        if (response.response_code == 200) {      
          this.cities = response.data;              
                   
        }
      });
    },
    openDatePicker() {
        this.$refs.picker.togglePicker(true);
    },
     disableDatesAfterMax(date) {
      return date > new Date();
    },
  }
}
</script>
<style>
.prescriptions{
  float:right;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
