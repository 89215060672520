<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper">

          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="row">
                  <h4 class="page-header page-title  mb-md-0">OPDs</h4>
              </div>
              <div class="row">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb p-0">
                      <li class="breadcrumb-item"><router-link to="/opdslist">OPDs List</router-link></li>
                      <li class="breadcrumb-item active" aria-current="page">{{ personalData.patient_number }}</li>
                    </ol>
                  </nav>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <form class="form-sample" v-if="personalData">
                <div class="col-md-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center mb-3">
                        <h4 class="col-12 col-md-4 card-title mb-2 mb-md-0">Patient Details: {{ personalData.patient_number }}</h4>
                        <span v-if="lastOpdData" class="opdnumber col-12 col-md-auto text-md-end">{{ lastOpdData.opd_today_number }}</span>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Patient Name</label>
                              <input
                                type="text"
                                class="form-control"
                                :value="personalData && personalData.patient_name"
                                name="patient_name"
                                placeholder="Patient Name"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label"
                                >Patient Mobile</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                :value="personalData && personalData.patient_mobile_number"
                                name="patient_mobile"
                                placeholder="Patient Mobile"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Patient Age</label>
                              <input
                                type="text"
                                class="form-control"
                                :value="personalData && personalData.patient_age"
                                name="age"
                                placeholder="Age"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Gender</label>
                              <select
                                class="form-control custom_height"
                                :value="personalData && personalData.patient_gender"
                                disabled
                              >
                                <option disabled value="">
                                  Please select gender
                                </option>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Address</label>
                              <textarea
                                type="text"
                                class="form-control"
                                :value="personalData && personalData.patient_address"
                                name="address"
                                placeholder="Address"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">City</label>
                              <input
                                type="text"
                                class="form-control"
                                :value="personalData && personalData.patient_city"
                                name="city"
                                placeholder="City"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row align-items-center mb-3"
                  v-if="lastOpdData || $route.params.is_edit == 0"
                >
                  <h3 v-if="$route.params.is_edit == 1" class="col-lg-8 mb-0">
                    Last OPD Details
                  </h3>
                  <h3 v-else class="col-lg-9">New OPD</h3>

                  <div class="col-lg-4 text-md-end mt-md-0 mt-2">

                    <button v-if="$route.params.is_edit == 1" type="button" class="btn btn-gradient-primary btn-rounded btn-icon me-4" @click="printOPDDesc($v.typeform.$model.opd_id)"><i class="mdi mdi-printer"></i></button>

                    <router-link
                      :to="{name:'prescription',params:{'id':$v.typeform.$model.opd_id}}"
                      v-if="$v.typeform.prescription_data.$model == null && $route.params.is_edit == 1"
                      class="btn btn-outline-primary btn-fw prescription mb-0"
                      >Add Prescription</router-link
                    >
                    <router-link
                      :to="{name:'prescription',params:{'id':$v.typeform.$model.opd_id}}"
                      v-if="$v.typeform.prescription_data.$model != null && $v.typeform.prescription_data.$model.saved_as_draft =='Yes'"
                      class="btn btn-outline-primary btn-fw prescription"
                      >Edit Prescription</router-link
                    >
                    <router-link
                      :to="{name:'prescription-report-view',params:{'id':$v.typeform.$model.opd_id}}"
                      v-if="$v.typeform.prescription_data.$model != null && $v.typeform.prescription_data.$model.saved_as_draft =='No'"
                      class="btn btn-outline-primary btn-fw prescription"
                      >View Prescription</router-link>
                  </div>
                </div>

                <div
                  class="col-md-12 grid-margin stretch-card"
                  v-if="lastOpdData || $route.params.is_edit == 0"
                >
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <div class="col-sm-12">
                              <label class="col-form-label">Fees</label>
                              <div class="input-group">
                                <div class="input-group-append">
                                  <span class="input-group-text text-primary" v-html="client_currency"></span>
                                </div>
                                <input
                                  type="text"
                                  class="col-sm-10 form-control"
                                  v-model="$v.typeform.fees.$model"
                                  name="fees"
                                  placeholder="Fees"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Disease</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="$v.typeform.disease.$model"
                                name="Disease"
                                placeholder="Disease"
                              />
                            </div>
                          </div>
                        </div>
                         <div class="col-md-4">
                      <div class="form-group row mb-2">
                        <div class="col-sm-12">
                          <label class="col-form-label">Department</label>
                        
                            <multiselect v-model="department_id" :options="department" :multiple="false"
                            :close-on-select="true"  @input="onSelectDepartment"
                           :clear-on-select="false"  :showLabels="false" :preserve-search="true" placeholder="Please select Department"
                           label="department_name" track-by="department_name" :preselect-first="false"></multiselect>
                        
                        </div>
                      </div>
                    </div> 
                        <div class="col-md-3">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Concern Doctor</label>
                              <multiselect v-model="$v.typeform.concern_doctor.$model" :options="doctors" :multiple="false"
                                    :close-on-select="true" label="name"
                                    :clear-on-select="false" :preserve-search="true" placeholder="Please select doctor"
                                     :preselect-first="true" :showLabels="false"></multiselect>
                              <div
                                v-if="$v.typeform.concern_doctor.$error"
                                class="invalid-feedback"
                              >
                              <span v-if="!$v.typeform.concern_doctor.required" >Please select doctor</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Patient BP</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="$v.typeform.patient_BP.$model"
                                name="patient_BP"
                                placeholder="Patient BP"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label"
                                >Patient Weight</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                v-model="$v.typeform.patient_weight.$model"
                                name="patient_weight"
                                placeholder="Patient Weight"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label"
                                >Patient Temperature</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                v-model="$v.typeform.patient_temperature.$model"
                                name="patient_temperature"
                                placeholder="Patient Temperature"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-md-flex align-items-center gap-3 justify-content-between">
                  <h3 class="card-title mb-2">Previous OPDs</h3>

                  <b-button v-if="is_btn_spinner" variant="primary" disabled>
                    <b-spinner small type="grow"></b-spinner>Loading...
                  </b-button>

                  <div v-else >
                    <div class="" v-if="lastOpdData || $route.params.is_edit == 0" >

                      <div v-if="$route.params.is_edit == 1" class="row">
                          <div class="col-md-5 col-lg-5 mb-lg-0 mb-2">
                            <button type="button" @click="submitForm(false)" class="col-12 btn btn-gradient-primary">Edit</button>
                          </div>
                          <div class="col-md-5 col-lg-7 mb-lg-0 mb-2">
                              <button type="button" @click="submitForm(true)" class="col-12 btn btn-gradient-primary">Edit & Print</button>
                          </div>
                      </div>

                      <div v-else class="row">
                          <div class="col-md-5 col-lg-5 mb-lg-0 mb-2">
                            <button type="button" @click="submitForm(false)" class="col-12 btn btn-gradient-primary">ADD</button>
                          </div>
                          <div class="col-md-5 col-lg-7 mb-lg-0 mb-2">
                              <button type="button" @click="submitForm(true)" class="col-12 btn btn-gradient-primary">ADD & Print</button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>

                <div
                  class="col-md-12 grid-margin stretch-card"
                  v-for="(opd ,i) in opdLists"
                  :key="i"
                >
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center mb-3">
                        <h3 class="col-lg-8 mb-2 mb-md-0">
                          {{opd.opd_registered_on | formatDate}}
                        </h3>
                        <div class="col-lg-4 text-md-end">
                          <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon me-4" @click="printOPDDesc(opd.opd_id)"><i class="mdi mdi-printer"></i></button>

                          <router-link :to="{name:'prescription',params:{'id':opd.opd_id}}"
                            v-if="opd.opd_prescription == null"
                            class="btn btn-outline-primary btn-fw"
                            >Add Prescription </router-link
                          >
                          <!--<b-button v-if="opd.opd_prescription == null" v-b-modal.modal-prevent-closing class="btn btn-outline-primary btn-fw">Add Prescription</b-button>-->
                          <router-link
                            :to="{name:'prescription',params:{'id':opd.opd_id}}"
                            v-if="opd.opd_prescription != null && opd.opd_prescription.saved_as_draft =='Yes'"
                            class="btn-outline-primary btn-fw"
                            >Edit Prescription</router-link
                          >
                          <router-link
                            :to="{name:'prescription-report-view',params:{'id':opd.opd_id}}"
                            v-if="opd.opd_prescription != null && opd.opd_prescription.saved_as_draft =='No'"
                            class="btn btn-outline-primary btn-fw"
                            >View Prescription</router-link
                          >
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <div class="col-sm-12">
                              <label class="col-form-label">Fees</label>
                              <div class="input-group">
                                <div class="input-group-append">
                                  <span class="input-group-text text-primary" v-html="client_currency"></span>
                                </div>
                                <input
                                  type="text"
                                  class="col-sm-10 form-control"
                                  :value="opd.opd_fees"
                                  name="fees"
                                  placeholder="Fees"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Disease</label>
                              <input
                                type="text"
                                class="form-control"
                                :value="opd.opd_disease"
                                name="Disease"
                                placeholder="Patient Name"
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label"
                                >Department</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                :value="opd.department_name"
                                name="Department"
                                placeholder="Department Name"
                                disabled
                                />
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label"
                                >Concern Doctor</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                :value="opd.opd_concern_doctor"
                                name="Disease"
                                placeholder="Patient Name"
                                disabled
                                />
                            </div>
                          </div>
                        </div>                        <div class="col-md-3">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label">Patient BP</label>
                              <input
                                type="text"
                                class="form-control"
                                :value="opd.opd_patient_bp"
                                name="patient_BP"
                                placeholder="Patient BP"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label"
                                >Patient Weight</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                :value="opd.opd_patient_weight"
                                name="patient_weight"
                                placeholder="Patient Weight"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group row mb-2">
                            <div class="col-sm-12">
                              <label class="col-form-label"
                                >Patient Temperature</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                :value="opd.opd_patient_temperature"
                                name="patient_temperature"
                                placeholder="Patient Temperature"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
    <Footer/>
      </div>
    </div>
    <b-modal id="modal-prevent-closing" ref="modal" title="" @ok="handleOk"  centered>
      <b-form-input v-model="patientno" placeholder="Enter patient number"></b-form-input>
      <p v-if="errormsg != ''" class="err">{{errormsg}}</p>
    </b-modal>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import Vue from 'vue'
import { PulseSpinner } from 'vue-spinners'
Vue.component('pulse', PulseSpinner)
import string from "../../constant/strings.js"

export default {
    name:'opddetails',
    title: string.PAGE_TITLE_OPD_DETAILS,
    components:{NavBar,SideBar,Multiselect, Footer},
    data() {
    return {
      moment:moment,
      personalData:{},
      lastOpdData:{},
      typeform:{
        fees:'',
        disease:'',
        concern_doctor:{},
        patient_BP:'',
        patient_weight:'',
        patient_temperature:'',
        prescription_id:'',
        prescription_data:'',
        department_id:''
      },
      department_id:'',
      department:[],
      opdLists:[],
      doctors:[],
      is_btn_spinner:false,
      patientno:'',
      errormsg :'',
      opdid:'',
      client_currency:'',
      formDataApi:'',
    }
    },
  validations: {
    typeform: {
      disease:{},
      concern_doctor:{},
      patient_BP:{},
      patient_weight:{},
      patient_temperature:{},  
      prescription_id:{} ,
      prescription_data:{} ,
      fees:{}
    },
  },
  mounted(){
    this.getDepartment();
    this.getOPDDetails();
  },
  methods:{
    ...mapActions("hospital",["getPatientsByNumberData","getOPDDepartmentListData","getConcernDoctorListData","postEditOpdData","getOpdFeesData", "registerOpdData","checkPatientNumberData", "printOPDData", "editOpdAndPrintData", "registerOpdAndPrintData"]),
    getDepartment(){
       var bodyFormData = new FormData();
     
         this.getOPDDepartmentListData(bodyFormData).then((response) => {
          console.log("response",response);
          if (response.response_code == 200) { 
            this.department = response.data;
          }
         });
    },
    onSelectDepartment(){
      this.doctors = []
      this.typeform.concern_doctor = ''
      this.getDoctorList();
    },
    submitForm(is_print){
      this.is_btn_spinner = true;
      this.$v.$touch();
      this.opdid = ''
      if(this.lastOpdData != null && this.lastOpdData.opd_id != null) {
        this.opdid = this.lastOpdData.opd_id
      }
      if (!this.$v.typeform.$invalid) {
      let formData = {
        opd_id:this.opdid,
        patient_number:this.personalData.patient_number,
        patient_id:this.personalData.patient_id,
        opd_fees:this.typeform.fees,
        opd_disease:this.typeform.disease,
        opd_concern_doctor_id: this.typeform.concern_doctor.id,
        opd_patient_bp: this.typeform.patient_BP,
        opd_patient_weight:this.typeform.patient_weight,
        opd_patient_temperature:this.typeform.patient_temperature,
        opd_prescription_id:this.typeform.prescription_id,
        opd_prescription_data : this.typeform.opd_prescription,
        department_id : this.department_id.id,
        save_and_print : is_print
      };

      if(this.$route.params.is_edit == 1) {
        if(is_print) {
            this.formDataApi = this.editOpdAndPrintData;
        } else {
            this.formDataApi = this.postEditOpdData;
        }
      } else {
        if(is_print) {
            this.formDataApi = this.registerOpdAndPrintData;
        } else {
            this.formDataApi = this.registerOpdData;
        }
      }

      if (this.$route.params.is_edit == 1) {
        this.formDataApi(formData).then((response) => {
                if (response.response_code == 200) {
                  this.is_btn_spinner = false;
                  this.$toasted.success("OPD update successfully", {duration: 2000,});
                  this.$router.push({ name: 'opdslist' });
                }else{
                  this.$toasted.error("Something Went wrong", {duration: 2000,});
                }
              });
      } else {
        this.formDataApi(formData).then((response) => {
                if (response.response_code == 200) {
                  this.is_btn_spinner = false;
                  this.$toasted.success("OPD update successfully", {duration: 2000,});
                  this.$router.push({ name: 'opdslist' });
                }else{
                  this.$toasted.error("Something Went wrong", {duration: 2000,});
                }
              });
      }
      }else{
        this.is_btn_spinner = false;
      }
    },

    getOPDDetails() {
      var bodyFormData = new FormData();
      bodyFormData.append('filter_value', parseInt(this.$route.params.pid));
      this.getPatientsByNumberData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.personalData = response.data;     
          this.client_currency = response.client_currency;
          this.opdLists = this.personalData.patient_opds;    
          this.lastOpdData = this.personalData.patient_last_opd;
          if (this.lastOpdData) {
            this.department_id = {'id': this.lastOpdData.department_id, 'department_name': this.lastOpdData.department_name};
          } else {
            this.department_id = {'id': this.opdLists[0].department_id, 'department_name': this.opdLists[0].department_name};
          }
          this.getDoctorList();

          if(this.$route.params.is_edit == 0){
             this.getopdfess(this.$route.params.pid);
          }
          console.log(this.$route.params.is_edit);
          if(this.$route.params.is_edit == 1 && this.lastOpdData){
            this.typeform = {
              opd_id:this.lastOpdData.opd_id,
              fees:this.lastOpdData.opd_fees,
              disease:this.lastOpdData.opd_disease,
              concern_doctor:{id:this.lastOpdData.opd_concern_doctor_id, name:this.lastOpdData.opd_concern_doctor},
              patient_BP:this.lastOpdData.opd_patient_bp,
              patient_weight:this.lastOpdData.opd_patient_weight,
              patient_temperature:this.lastOpdData.opd_patient_temperature,
              prescription_id:this.lastOpdData.prescription_id,
              prescription_data : this.lastOpdData.opd_prescription,
            }
          } else {
            this.typeform = {
              opd_id: "",
              fees:'',
              disease:'',
              concern_doctor:{},
              patient_BP:'',
              patient_weight:'',
              patient_temperature:'',
              prescription_id:'',
              prescription_data : null,
            }
          }
        }
      });
    },

    getDoctorList(){
      if(!this.department_id.id){
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append('filter_value', '');
      bodyFormData.append('department_id',this.department_id.id);
      this.getConcernDoctorListData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.doctors = response.data;
        }
      });
    },

    getopdfess(id){
      var bodyFormData = new FormData();
      bodyFormData.append('patient_number', parseInt(id));
      this.getOpdFeesData(bodyFormData).then((response) => {
        if (response.response_code == 200) {          
          this.typeform.fees = response.opd_fees;
          this.client_currency = response.client_currency;
        }
      });
    },

    printOPDDesc(opd_id){
      var bodyFormData = new FormData();
      bodyFormData.append('opd_id', opd_id);
      this.printOPDData(bodyFormData).then((response) => {
        if (response.response_code == 200) {

        }
      });
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      if(this.patientno){
        var bodyFormData = new FormData();
        bodyFormData.append('patient_number', parseInt(this.patientno));
        this.checkPatientNumberData(bodyFormData).then((response) => {
          if (response.response_code == 200) {
            this.$router.push({name: 'prescription', params: { id: this.patientno }});
            this.patientno = ''
          }else{
            this.errormsg = response.message;
          }
        });
      }
    },
  }
}
</script>
<style scoped>
.err{
  color: red;
}
.prescription{
  margin-bottom:10px;
}

.opdnumber {
  list-style: none; /* Remove list styling */
  border: 2px ridge #b66dff; /* Border styling */
  width: 40px; /* Set a base width */
  height: 40px; /* Set a base height */
  padding: 0; /* No padding */
  background: rgba(255, 255, 209, 0.87); /* Semi-transparent background */
  border-radius: 50%; /* Perfectly round */
  display: flex; /* Flexbox for centering */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  font-size: 1.25rem; /* Adjust font size for visibility */
  color: #212529; /* Text color */
  text-align: center; /* Center text */
  transition: all 0.3s; /* Optional: transition for hover effect */
}

.opdnumber:hover {
  background: rgba(255, 255, 209, 0.95); /* Change background on hover */
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>