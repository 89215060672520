<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">

    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="row">
            <h4 class="page-header page-title  mb-md-0">OPDs List</h4>
        </div>
        <div class="row" hidden>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb p-0">
                <li class="breadcrumb-item active" aria-current="page">OPDs List</li>
              </ol>
            </nav>
        </div>
      </div>

      <div class="col-lg-6 ms-auto">
        <div class="d-md-flex d-grid gap-3 justify-content-md-end">
          <button type="button" v-b-modal.modal-prevent-closing class="btn btn-gradient-dark btn-fw">New OPD</button>
          <router-link to="/opdsadd" class="btn btn-gradient-primary">Add Patient</router-link>
        </div>
      </div>
    </div>

    <br/>
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body nw-opd">
              <div class="row">
                <div class="col-lg-4  mb-lg-0 mb-2">
                    <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchopd" id="searchopd" autocomplete="off" v-on:keyup="getOpdList(1)">
                </div>
                <div class="col-lg-2"/>
                    <date-range-picker
                                class="col-lg-3 mb-lg-0 mb-2"
                                ref="picker"
                                :max-date="MAXDATE"
                                :opens="center"
                                :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                :single-date-picker="false"
                                :showDropdowns="showDropdowns"
                                :show-week-numbers= "false"
                                :time-picker="false"
                                :autoApply="true"
                                v-model="dateRange"
                                :linked-calendars="true"
                                :dateFormat="dd-mmm-yyyy"
                                @finish-selection=getOpdList(1)
                                @update=getOpdList(1)
                        />
                        <div class="col-lg-3">
                            <multiselect
                                    v-model="opd_filter_status"
                                    :options="opd_status_list"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true" placeholder=""
                                    :preselect-first="true"
                                    :showLabels="false"
                                    :allowEmpty="false"
                                    @input=getOpdList(1)
                                    /></multiselect>
                        </div>
              </div>
              <br/>
              <br/>
              <div class="table-responsive">
                <table class="table table-hover ">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>OPD Date</th>
                    <th>Patient Number</th>
                    <th>Patient Name</th>
                    <th>Patient<br/>Age</th>
                    <th>City</th>
                    <th>OPD<br/>No.</th>
                    <th>Concern<br/>Doctor</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>                  
                <tbody>
                  <tr v-for="(opd,i) in opdlists" :key="i">
                    <td @click="openOPDDetails(opd)">{{opd.opd_id}}</td>
                    <td @click="openOPDDetails(opd)">{{moment.parseZone(opd.opd_registered_on).format('DD MMM YYYY')}}</td>
                    <td @click="openOPDDetails(opd)">{{opd.patient_number}}</td>
                    <td @click="openOPDDetails(opd)">{{opd.patient_details.patient_name}}</td>
                    <td @click="openOPDDetails(opd)">{{opd.patient_details.patient_age}}</td>
                    <td @click="openOPDDetails(opd)">{{opd.patient_details.patient_city}}</td>
                    <td @click="openOPDDetails(opd)">{{opd.opd_today_number}}</td>
                    <td @click="openOPDDetails(opd)">{{opd.opd_concern_doctor}}</td>
                    <td><label v-bind:class="{
                        'badge badge-success': opd.opd_status === 'Waiting',
                        'badge badge-warning': opd.opd_status === 'Under Treatment' || opd.opd_status === 'Waiting For Prescription',
                        'badge badge-info': opd.opd_status === 'Completed',
                        'badge badge-danger': opd.opd_status === 'InActive' || opd.opd_status === 'Waiting For Report',
                        }">{{opd.opd_status}}</label></td>
                    <td>
                        <button type="button" @click="openAddIPDModel(opd)" class="btn btn-gradient-primary btn-rounded btn-icon me-2" :hidden="opd.is_patient_admitted == true || opd.opd_status == 'InActive' ||  opd.opd_status == 'Completed'"><i class="mdi mdi-book-open-page-variant"></i></button>

                        <router-link v-if="opd.opd_prescription" :to="{name:'prescription',params:{'id':opd.opd_id}}" hidden>
                            <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-library-books"></i></button>
                            &nbsp
                        </router-link>

                        <router-link v-if="opd.opd_prescription == null || opd.opd_prescription.saved_as_draft == 'Yes'" :to="{name:'prescription',params:{'id':opd.opd_id}}">
                            <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-library-books"></i></button>
                            &nbsp
                        </router-link>

                        <router-link :to="{name:'prescription-report-view',params:{'id':opd.opd_id}}"  v-if="opd.opd_prescription != null">
                            <button type="button" class="btn btn-outline-secondary btn-rounded btn-icon"><i class="mdi mdi-open-in-new"></i></button>
                        </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && opdlists.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getOpdList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
    <b-modal id="modal-prevent-closing" ref="modal" title="" @ok="handleOk"  centered>
      <b-form-input v-model="patientno" placeholder="Enter patient number"></b-form-input>
      <p v-if="errormsg != ''" class="err">{{errormsg}}</p>
    </b-modal>
    <AddIPDModel ref="addIPDModelComponent"/>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Subscription from "@/components/Subscription.vue";
import DateRangePicker from 'vue2-daterange-picker'
import { mapActions} from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import AddIPDModel from "../model/add_ipd_model"

export default {
  name:'OPDS',
  title: string.PAGE_TITLE_OPDs,
  components: {
    NavBar,
    Footer,
    SideBar,
    DateRangePicker,
    Multiselect,
    Subscription,
    AddIPDModel
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  	const maxDate = new Date(today)

    return {
      opdlists:[],
      moment,
      listPages:0,
      activePage:1,      
      patientno:'',
      errormsg:'',
      is_API_call_running:false,
      searchtext:'',
      myTimer:null,
      dateRange:{
        startDate: today,
        endDate: today
      },
      response_code:200,
      errorMessage : "No Data Found.",
      opd_status_list:['All','Waiting', 'Under Treatment', 'Waiting For Report', 'Waiting For Prescription', 'Completed', 'InActive'],
      opd_filter_status: "All",
      MAXDATE:maxDate,
      is_listing_ipd:false,
    }
  },
  mounted() {
    this.getOpdList(this.activePage);
  },
  
  methods:{
    ...mapActions("hospital",["getOpdListData", "checkPatientNumberData"]),

    getOpdList(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
            this.opdlists = [];
            var bodyFormData = new FormData();
            bodyFormData.append('search_text', this.searchtext);
            bodyFormData.append('page', page);
            bodyFormData.append('filter_from_date', moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'));
            bodyFormData.append('filter_to_date', moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'));
            bodyFormData.append('filter_status', this.opd_filter_status);
            this.getOpdListData(bodyFormData).then((response) => {
              this.is_API_call_running = false;
              if (response.response_code == 200) {
                this.opdlists = response.data;
                this.listPages = response.no_of_pages;
              }else{
                this.response_code = response.response_code;
                 console.log(this.response_code)
                this.errorMessage = response.message;
              }
        });
      }, 500)
    },

    previewClick(){
      this.activePage--;
      if(this.activePage >= 1){
        this.getOpdList(this.activePage);
      }else{
        this.activePage = 1;
      }
    },

    nextClick(){
      this.activePage++;
      if(this.activePage <= this.listPages){
        this.getOpdList(this.activePage);
      }else{
        this.activePage = this.listPages;
      }
    },

    handleOk(bvModalEvent) {
          bvModalEvent.preventDefault();
          if(this.patientno){
            var bodyFormData = new FormData();
            bodyFormData.append('patient_number', this.patientno);
            this.checkPatientNumberData(bodyFormData).then((response) => {
              if (response.response_code == 200) {
                this.$router.push({name: 'opddetails', params: { 'pid':this.patientno, 'is_edit':0 }});
                this.patientno = ''
              }else{
                 this.response_code = response.response_code;
                
                this.errormsg = response.message;
              }
            });
          }
        },

    openDatePicker() {
        this.$refs.picker.togglePicker(true);
    },

    openAddIPDModel(opd) {
        this.$refs.addIPDModelComponent.clearData();
        this.$refs.addIPDModelComponent.receiveData(opd);
        this.$refs.addIPDModelComponent.show();
        if(this.is_listing_ipd == false){
            this.is_listing_ipd = true
            var myParent = this
            this.$refs.addIPDModelComponent.$on("hide", function () {
                myParent.getOpdList(myParent.activePage);
            });
        }
    },

    openOPDDetails(opd) {
        this.$router.push({ name: 'opddetails', params:{'pid':opd.patient_number, 'is_edit':1}});
    },
  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>