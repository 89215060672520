<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
    <div class="page-header">
      <h4 class="page-title">Appointment List</h4>
    </div>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
            <div class="row">
                <div class="col-lg-4  mb-lg-0 mb-2">
                    <br/>
                   <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchappointment" id="searchappointment" autocomplete="off" v-on:keyup="getAppointmentList(1)">
                </div>

                <div class="col-lg-1"></div>
                <div class="col-lg-5">
                    <div class="row">
                        <div class="col-lg-6 mb-lg-0 mb-2">
                            <small class="text-muted mb-2 d-inline-block">Booked On:</small>
                            <date-range-picker
                                class="ms-lg-0 ms-4"
                                ref="picker"
                                :opens="center"
                                :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                :single-date-picker="false"
                                :showDropdowns="showDropdowns"
                                :show-week-numbers= "false"
                                :time-picker="false"
                                :autoApply="true"
                                v-model="dateRangeRegistration"
                                :linked-calendars="true"
                                :dateFormat="dd-mmm-yyyy"
                                @finish-selection=getAppointmentList(1)
                                @update=getAppointmentList(1)
                            />
                        </div>
                        <div class="col-lg-6  mb-lg-0 mb-2">
                            <small class="text-muted mb-2 d-inline-block">Visiting Date:</small>
                            <date-range-picker
                                class="ms-lg-0 ms-3"
                                ref="picker"
                                :opens="center"
                                :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                :single-date-picker="false"
                                :showDropdowns="showDropdowns"
                                :show-week-numbers= "false"
                                :time-picker="false"
                                :autoApply="true"
                                v-model="dateRangeVisiting"
                                :linked-calendars="true"
                                :dateFormat="dd-mmm-yyyy"
                                @finish-selection=getAppointmentList(1)
                                @update=getAppointmentList(1)
                            />
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 align-self-end  mb-lg-0 mb-2">
                    <br/>
                    <multiselect
                        v-model="filter_status"
                        :options="status_list"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true" placeholder=""
                        :preselect-first="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        @input=getAppointmentList(1)
                    /></multiselect>
                </div>
            </div>

              <br>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Patient Name</th>
                        <th>Patient Number</th>
                        <th>Patient Patient Mobile Number	</th>
                        <th>Patient Age</th>
                        <th>First Time Visit</th>
                        <th>Visiting Date</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(appinmnt,i) in appointments" :key="i">
                        <td>{{appinmnt.patient_name}}</td>
                        <td><router-link v-if="appinmnt.patient_number != null && appinmnt.patient_number > 0" :to="{name:'patientsdetails',params:{'pid':appinmnt.patient_number}}">{{appinmnt.patient_number}}</router-link></td>
                        <td>{{appinmnt.patient_mobile_number}}</td>
                        <td>{{appinmnt.patient_age}}</td>
                        <td>{{appinmnt.is_first_time_visit}}</td>
                        <td>{{moment.parseZone(appinmnt.visiting_date_time).format('YYYY-MM-DD')}}</td>
                        <td>
                          <label v-bind:class="{'badge badge-success': appinmnt.appointment_status === 'Active', 'badge badge-danger': appinmnt.appointment_status === 'InActive' || appinmnt.appointment_status === 'Inactive'}">{{appinmnt.appointment_status}}</label
                        </td>
                        <td><router-link :to="{name:'appointmentdetails',params:{'apid':appinmnt.appointment_id}}">
                        <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button></router-link></td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && appointments.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted mb-2 d-inline-block">{{ errorMessage }}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getAppointmentList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions } from "vuex";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import Multiselect from 'vue-multiselect';
import string from "../constant/strings.js"

export default {
  name:'appointment',
  title: string.PAGE_TITLE_APPOINTMENT,
  components: {
      NavBar,
Footer,
      SideBar,
      DateRangePicker,
      Multiselect
  },
  data() {
    const now = new Date()
    const startdate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  	const maxDate = new Date(today)

    return {
      appointments:[],
      moment: moment,
      is_API_call_running:false,
      listPages:0,
      activePage:1,
      searchtext:'',
      myTimer:null,
      errorMessage: 'No Data Found.',
      status_list:['All','Active', 'Inactive'],
      filter_status: "All",
      dateRangeRegistration:{
        startDate: startdate,
        endDate: today
      },
      dateRangeVisiting:{
        startDate: today,
        endDate: today
      },
      MAXDATE:maxDate
    }
  },
  mounted() {
    this.getAppointmentList(this.activePage);
  },
  methods:{
    ...mapActions("hospital",["getAppointmentListData"]),

    getAppointmentList(page){
        this.activePage = page;
        clearTimeout(this.myTimer)
        this.myTimer = setTimeout(() => {
            this.is_API_call_running = true;
                  this.appointments = [];
                  var bodyFormData = new FormData();
                  bodyFormData.append('filter_value', this.searchtext);
                  bodyFormData.append('page', page);
                  bodyFormData.append('filter_status', this.filter_status);
                  bodyFormData.append('filter_from_date', moment(String(this.dateRangeRegistration.startDate)).format('YYYY-MM-DD'));
                  bodyFormData.append('filter_to_date', moment(String(this.dateRangeRegistration.endDate)).format('YYYY-MM-DD'));
                  bodyFormData.append('filter_from_date_visiting', moment(String(this.dateRangeVisiting.startDate)).format('YYYY-MM-DD'));
                  bodyFormData.append('filter_to_date_visiting', moment(String(this.dateRangeVisiting.endDate)).format('YYYY-MM-DD'));
                  this.getAppointmentListData(bodyFormData).then((response) => {
                    this.is_API_call_running = false;
                    if (response.response_code == 200) {
                      this.appointments = response.data;
                      this.listPages = response.no_of_pages;
                    }else{
                      this.errorMessage = response.message;
                    }
                  });
        }, 500)
    },

    previewClick(){
              this.activePage--;
              if(this.activePage >= 1){
                this.getAppointmentList(this.activePage);
              }else{
                this.activePage = 1;
              }
            },

    nextClick(){
        this.activePage++;
        if(this.activePage <= this.listPages){
           this.getAppointmentList(this.activePage);
        }else{
           this.activePage = this.listPages;
        }
    },
  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>