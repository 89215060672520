<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row align-items-center">
            <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
                <div class="page-header">
                    <h4 class="page-title">Maintenance Request</h4>
                </div>
            </div>
            <div class="col-lg-5 ms-auto">
                <div class="d-md-flex d-grid gap-3 justify-content-md-end">
                    <button class="btn btn-gradient-primary btn-fw" @click="openAddEditMaintenanceRequestDialog()">Add Request/Out</button>
                </div>
            </div>
          </div>
          </br>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body nw-opd">
                        <div class="row me-1">
                            <div class="col-lg-4">
                                    <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="search" id="search" autocomplete="off" v-on:keyup="getInventoryEquipmentMaintenanceAPI(1)">
                            </div>
                              <div class="col-lg-3 mb-lg-0 mb-2">
                                  <multiselect
                                      v-model="filter_for"
                                      :options="filter_for_list"
                                      :multiple="false"
                                      :close-on-select="true"
                                      :clear-on-select="false"
                                      :preserve-search="true" placeholder=""
                                      :preselect-first="true"
                                      :showLabels="false"
                                      :allowEmpty="false"
                                      label="name"
                                      @input=getInventoryEquipmentMaintenanceAPI(1)
                                  /></multiselect>
                              </div>
                            <date-range-picker
                                    class="col-lg-3 mb-lg-0 mb-2"
                                    ref="picker"
                                    :max-date="MAXDATE"
                                    :opens="left"
                                    :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                    :single-date-picker="false"
                                    :showDropdowns="showDropdowns"
                                    :show-week-numbers= "false"
                                    :time-picker="false"
                                    :autoApply="true"
                                    v-model="dateRange"
                                    :linked-calendars="true"
                                    :dateFormat="dd-mmm-yyyy"
                                    @finish-selection=getInventoryEquipmentMaintenanceAPI(1)
                                    @update=getInventoryEquipmentMaintenanceAPI(1)
                            />
                              <div class="col-lg-2 mb-lg-0 mb-2">
                                  <multiselect
                                      v-model="status"
                                      :options="status_list"
                                      :multiple="false"
                                      :close-on-select="true"
                                      :clear-on-select="false"
                                      :preserve-search="true" placeholder=""
                                      :preselect-first="true"
                                      :showLabels="false"
                                      :allowEmpty="false"
                                      label="name"
                                      @input=getInventoryEquipmentMaintenanceAPI(1)
                                  /></multiselect>
                              </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Last Maintenance Date</th>
                                        <th>Next Maintenance Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(maintenance_request,i) in maintenance_requests" :key="i">
                                        <td>{{maintenance_request.id}}</td>
                                        <td v-if="maintenance_request && maintenance_request.item">{{maintenance_request.item.name}}</td>
                                        <td v-else>-</td>

                                        <td>{{maintenance_request.last_maintenance_date}}</td>
                                        <td>{{maintenance_request.next_maintenance_due}}</td>
                                        <td><div v-bind:class="{
                                                 'badge badge-success': maintenance_request.status === 'completed',
                                                 'badge badge-warning': maintenance_request.status === 'pending',
                                                 }">{{maintenance_request.status}}</div></td>
                                        <td>
                                            <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon" @click="openAddEditMaintenanceRequestDialog(maintenance_request)"><i class="mdi mdi-pencil"></i></button>  &nbsp;&nbsp;
                                            <button type="button" class="btn btn-outline-secondary btn-rounded btn-icon" @click="deleteMaintenanceRequestAPI(maintenance_request.id, maintenance_request.item.name)" ><i class="mdi mdi-delete"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="is_API_call_running" class="text-center mt-5">
                            <b-spinner label=""></b-spinner>
                        </div>
                        <div class="row" v-if="!is_API_call_running && maintenance_requests.length == 0" >
                            <div class="col-lg-12 text-center">
                                <br/><br/><br/>
                                <small class="text-muted">{{ errorMessage }}</small>
                                <br/><br/><br/>
                            </div>
                        </div>
                        <div class="b-pagination-outer" v-if="listPages > 1">
                            <ul id="border-pagination">
                                <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                                <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getInventoryEquipmentMaintenanceAPI(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                                <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Footer/>
      </div>
    </div>
    <MaintenanceRequestComponent/>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions } from "vuex";
import string from "../../constant/strings.js"
import { EventBus } from '@/event-bus';
import {get_user_settings } from '../../helper/helper';
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import moment from "moment";
import Swal from 'sweetalert2'
import MaintenanceRequestComponent from './components/component_maintenance_request.vue';

export default {
  name: 'maintenance request',
  title: string.PAGE_TITLE_MAINTENANCE_REQUESTS,
  components: {
    NavBar,
    Footer,
    SideBar,
    Multiselect,
    DateRangePicker,
    MaintenanceRequestComponent,
  },
  data() {
    const now = new Date()
    const startdate = new Date(now.getFullYear(), now.getMonth(), 1)
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())

    return {
        moment,
        maintenance_requests:[],
        is_API_call_running: false,
        listPages:0,
        activePage:1,
        searchtext:'',
        myTimer:null,
        errorMessage : 'No Data Found.',
        dialogResult: null,
        currency_data:null,
        status_list: [{"id":"all","name":"All"}, {"id":"pending","name":"Pending"}, {"id":"completed","name":"Completed"}],
        status: {"id":"all","name":"All"},
        filter_for_list: [{"id":"all","name":"All"}, {"id":"last_mr_date","name":"Last Maintenance Date"}, {"id":"next_mr_date","name":"Next Maintenance"}],
        filter_for: {"id":"all","name":"All"},
        dateRange:{
            startDate: startdate,
            endDate: today
        },
        MAXDATE:maxDate,
    }
  },
  mounted() {
      this.getInventoryEquipmentMaintenanceAPI(this.activePage);
      this.currency_data = get_user_settings()
  },
    created() {
        // Listen for the dialog result
        EventBus.$on('dialog-mr-result', (result) => {
            this.dialogResult = result;
            if(this.dialogResult.status) {
                this.getInventoryEquipmentMaintenanceAPI(1);
            }
        });
    },
  methods: {
    ...mapActions("hospital", ["getInventoryEquipmentMaintenance", "deleteInventoryEquipmentMaintenance"]),

    getInventoryEquipmentMaintenanceAPI(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.maintenance_requests = [];
        this.listPages = 0;
        let queryParams = {
            item_name: this.searchtext,
            status: this.status.id,
            filter_start_date: moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'),
            filter_end_date: moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'),
            page: page,
            filter_for: this.filter_for.id,
        };
        this.getInventoryEquipmentMaintenance(queryParams).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
                this.maintenance_requests = response.data;
                this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
        });
      }, 500)
    },

    previewClick(){
          this.activePage--;
          if(this.activePage >= 1){
            this.getInventoryEquipmentMaintenanceAPI(this.activePage);
          }else{
            this.activePage = 1;
          }
        },

    nextClick(){
       this.activePage++;
       if(this.activePage <= this.listPages){
            this.getInventoryEquipmentMaintenanceAPI(this.activePage);
       }else{
            this.activePage = this.listPages;
       }
    },
    deleteMaintenanceRequestAPI(id, item_name, index) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + item_name + string.TRANSACTIONS,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('equipment_maintenance_id', id);
            this.deleteInventoryEquipmentMaintenance(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.maintenance_requests.length;i++){
                        if(id == this.maintenance_requests[i].id) {
                            this.maintenance_requests.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    },
    openAddEditMaintenanceRequestDialog(transaction) {
        const payload = { data: transaction };
        EventBus.$emit('open-mr-dialog', payload); // Trigger the dialog opening
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
