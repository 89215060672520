const Dashboard = () => import('../../views/Dashboard.vue')
const Appointment = () => import('../../views/Appointment.vue')
const OPDSList = () => import('../../views/Opds/OpdsList.vue')
const AddOPDS = () => import('../../views/Opds/AddOpds.vue')
const Login = () => import('../../views/SignIn.vue')
const Patients = () => import('../../views/Patients.vue')
const DoctorList = () => import('../../views/DoctorList.vue')
const AddDoctor = () => import('../../views/AddDoctor.vue')
const cityList = () => import('../../views/City.vue')
const AddCity = () => import('../../views/AddCity.vue')
const OPDSDetails = () => import('../../views/Opds/opddetails.vue')
const patientsDetails = () => import('../../views/PatientDetails.vue')
const AppointmentDetails = () => import('../../views/AppointmentDetails.vue')
const settings = () => import('../../views/settings/Settings.vue')
const Prescriptions = () => import('../../views/Prescription.vue')
const medicines = () => import('../../views/MedicineList.vue');
const addMedicines = () => import('../../views/AddMedicine.vue');
const PrescriptionReportView = () => import('../../views/PrescriptionReportPreview.vue');
const Changelog = () => import('../../views/Changelog.vue')
const Statistics = () => import('../../views/statistics/Statistics.vue')
const StatisticsTypeSelection = () => import('../../views/statistics/statistics_type_selection.vue')
const ResetPassword = () => import('../../views/ResetPassword.vue')
const ForgotPassword = () => import('../../views/ForgotPassword.vue')
const DepartmentList = () => import('../../views/department/DepartmentList.vue')
const DepartmentDetails = () => import('../../views/department/DepartmentDetails.vue')
const ReportsList = () => import('../../views/reports/ReportsList.vue')
const ReportDetails = () => import('../../views/reports/ReportDetails.vue')
const EffectsList = () => import('../../views/effects/EffectsList.vue')
const EffectDetails = () => import('../../views/effects/EffectDetails.vue')
const AdminProfile = () => import('../../views/profile/AdminProfile.vue')
const Subscription = () => import('../../views/subscription/Subscription.vue')
const ContactUsRequest = () => import('../../views/contact_us_request/Contact_Us_Request.vue')
const roomWardListing = () => import('../../views/room-wards/Room_WardListing.vue')
const addRooms = () => import('../../views/room-wards/AddRoom.vue')
const addWards = () => import('../../views/room-wards/AddWard.vue')
const IPDSList = () => import('../../views/Ipds/IpdsList.vue')
const AddIPDS = () => import('../../views/Ipds/AddIpds.vue')
const IPDSDetails = () => import('../../views/Ipds/Ipddetails.vue')
const Billings = () => import('../../views/billings/billings.vue')
const TermsAndConditions = () => import('../../views/pages/terms_and_conditions.vue')
const PrivacyPolicy = () => import('../../views/pages/privacy_policy.vue')
const RefundPolicy = () => import('../../views/pages/refund_policy.vue')
const Inventories = () => import('../../views/inventory/inventory.vue')
const AddInventoryItem = () => import('../../views/inventory/add_inventory_item.vue')
const StockInOut = () => import('../../views/inventory/stock_in_out.vue')
const MaintenanceRequest = () => import('../../views/inventory/maintenance_request.vue')
const PurchaseOrders = () => import('../../views/inventory/purchase_orders.vue')
const AddPurchaseOrder = () => import('../../views/inventory/add_purchase_order.vue')

// Master Admin
const MasterAdminDashboard = () => import('../../views/master-admin/dashboard/Dashboard.vue')
const MasterAdminSubscriptionPlan = () => import('../../views/master-admin/subscription/SubscriptionList.vue')
const MasterAdminAddSubscriptionPlan = () => import('../../views/master-admin/subscription/AddSubscriptionPlan.vue')
const MasterAdminClients = () => import('../../views/master-admin/clients/ClientList.vue')
const MasterAdminClientsDetails = () => import('../../views/master-admin/clients/ClientDetails.vue')
const MasterAdminClientsUserDetails = () => import('../../views/master-admin/clients/ClientUserDetailsPage.vue')
const MasterAdminAddClients = () => import('../../views/master-admin/clients/AddClientDetailsPage.vue')
const MasterAdminTransactions = () => import('../../views/master-admin/TransactionListing.vue')
const MasterAdminCurrency = () => import('../../views/master-admin/CurrencyListing.vue')

const restaurant = [
    {
        path: '/',
        name: 'home',
        component: Dashboard
    },
    {
        path: "/opdslist",
        name: 'opdslist',
        component: OPDSList
    },
    {
        path: "/opdsadd/:id?",
        name: 'opdsadd',
        component: AddOPDS
    },
    {
        path: "/opddetails/:pid/:is_edit?",
        name: 'opddetails',
        component: OPDSDetails
    },
    {
        path: "/appointment",
        name: 'appointment',
        component: Appointment
    },
    {
        path: "/appointmentdetails/:apid",
        name: 'appointmentdetails',
        component: AppointmentDetails
    },
    {
        path: "/login",
        name: 'login',
        component: Login
    },
    {
        path: "/patients",
        name: 'patients',
        component: Patients
    },
    {
        path: "/patientsdetails/:pid",
        name: 'patientsdetails',
        component: patientsDetails
    },
    {
        path: "/doctor",
        name: 'doctor',
        component: DoctorList
    },
    {
        path: "/adddoctor",
        name: 'adddoctor',
        component: AddDoctor
    },
    {
        path: "/cities",
        name: 'cities',
        component: cityList
    },
    {
        path: "/addcity",
        name: 'addcity',
        component: AddCity
    },
    {
        path: "/editcity/:id",
        name: 'editcity',
        component: AddCity
    },
    {
        path: "/editdoctor/:id",
        name: 'editdoctor',
        component: AddDoctor
    },
    {
        path: "/settings",
        name: 'settings',
        component: settings
    },
    {
        path: "/prescriptions/:id",
        name: 'prescription',
        component: Prescriptions
    },
    {
        path: "/medicines",
        name: 'medicines',
        component: medicines
    },
    {
        path: "/add-medicines/:id?",
        name: 'addmedicines',
        component: addMedicines
    },
    {
        path: "/prescription-report-preview/:id?",
        name: 'prescription-report-view',
        component: PrescriptionReportView
    },
    {
        path: "/changelog",
        name: 'changelog',
        component: Changelog
    },
    {
        path: "/statistics/:type?:earning_type",
        name: 'statistics',
        component: Statistics
    },
    {
        path: "/statistics",
        name: 'statistics_type_selection',
        component: StatisticsTypeSelection
    },
    {
        path: "/reset-password",
        name: 'resetpasswords',
        component: ResetPassword
    },
    {
        path: "/forgot-password",
        name: 'forgotpassword',
        component: ForgotPassword
    },
    {
        path: "/department",
        name: 'departmentlist',
        component: DepartmentList
    },
    {
        path: "/department-details",
        name: 'departmentdetails',
        component: DepartmentDetails
    },
    {
        path: "/reports",
        name: 'reportslist',
        component: ReportsList
    },
    {
        path: "/report-details",
        name: 'reportdetails',
        component: ReportDetails
    },
    {
        path: "/effects",
        name: 'effectslist',
        component: EffectsList
    },
    {
        path: "/effect-details",
        name: 'effectdetails',
        component: EffectDetails
    },
    {
        path: "/admin-profile",
        name: 'adminprofile',
        component: AdminProfile
    },
    {
        path: "/subscription",
        name: 'subscription',
        component: Subscription
    },
    {
        path: "/contact-us-request",
        name: 'contact-us-request',
        component: ContactUsRequest
    },
    {
        path: "/rooms-wards",
        name: 'rooms-wards',
        component: roomWardListing
    },
    {
        path: "/add-room/:id?",
        name: 'addroom',
        component: addRooms
    },
    {
        path: "/add-ward/:id?",
        name: 'addward',
        component: addWards
    },
    {
        path: "/ipdslist",
        name: 'ipdslist',
        component: IPDSList
    },
    {
        path: "/ipdsadd/:id?",
        name: 'ipdsadd',
        component: AddIPDS
    },
    {
        path: "/ipddetails/:ipdid",
        name: 'ipddetails',
        component: IPDSDetails
    },
    {
        path: "/billings",
        name: 'billings',
        component: Billings
    },
    {
        path: "/terms-and-conditions",
        name: 'termsandconditions',
        component: TermsAndConditions
    },
    {
        path: "/privacy-policy",
        name: 'privacypolicy',
        component: PrivacyPolicy
    },
    {
        path: "/refund-policy",
        name: 'refundpolicy',
        component: RefundPolicy
    },
    {
        path: "/inventory",
        name: 'inventory',
        component: Inventories
    },
    {
        path: "/add-update-inventory/:id?",
        name: 'add-inventory-item',
        component: AddInventoryItem
    },
    {
        path: "/stock-in-out",
        name: 'stock-in-out',
        component: StockInOut
    },
    {
        path: "/maintenance-requests",
        name: 'maintenance-requests',
        component: MaintenanceRequest
    },
    {
        path: "/purchase-orders",
        name: 'purchase-orders',
        component: PurchaseOrders
    },
    {
        path: "/add-purchase-order/:id?",
        name: 'add-purchase-order',
        component: AddPurchaseOrder
    },
    {
        path: "/master-admin",
        name: 'masteradmin',
        component: MasterAdminDashboard
    },
    {
        path: "/master-admin/subscription-plans",
        name: 'master-admin-subscription-plans',
        component: MasterAdminSubscriptionPlan
    },
    {
        path: "/master-admin/add-subscription-plan",
        name: 'master-admin-add-subscription-plan',
        component: MasterAdminAddSubscriptionPlan
    },
    {
        path: "/master-admin/clients",
        name: 'master-admin-clients',
        component: MasterAdminClients
    },
    {
        path: "/master-admin/client-details/:id?",
        name: 'master-admin-client-details',
        component: MasterAdminClientsDetails
    },
    {
        path: "/master-admin/add-client",
        name: 'master-admin-client-details',
        component: MasterAdminAddClients
    },
    {
        path: "/master-admin/client-user-details/:client_id/:id?",
        name: 'master-admin-client-user-details',
        component: MasterAdminClientsUserDetails
    },
    {
        path: "/master-admin/transactions",
        name: 'master-admin-transactions',
        component: MasterAdminTransactions
    },
    {
        path: "/master-admin/currencies",
        name: 'master-admin-currencies',
        component: MasterAdminCurrency
    },

]

export default restaurant;