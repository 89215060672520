<template>
  <b-modal ref="modal2" id="modal2" title="Feature Details" centered size="midium" hide-footer>
    <form class="form-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Select Feature</label>
                <multiselect v-model="$v.typeform.feature_name.$model" :options="feature_list"
                             label = "name"
                             :multiple="false" :close-on-select="true" :clear-on-select="false"
                             :preserve-search="true" placeholder="Select Feature"
                             :preselect-first="false" :showLabels="false"></multiselect>
                <div v-if="$v.typeform.feature_name.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.feature_name.required">Please select feature</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Max Limit</label>
                <input type="number" class="form-control" v-model="$v.typeform.max_limit.$model"
                    :class="{ 'is-invalid':  $v.typeform.max_limit.$error  }"
                    placeholder="Max Limit"/>
                <div v-if="$v.typeform.max_limit.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.max_limit.required">Please enter max limit</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Max Limit Period</label>
                <input type="number" class="form-control" v-model="$v.typeform.max_limit_period.$model"
                    :class="{ 'is-invalid':  $v.typeform.max_limit_period.$error  }"
                    placeholder="Max Limit Period"/>
                <div v-if="$v.typeform.max_limit_period.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.max_limit_period.required">Please enter max limit period</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Description</label>
                <textarea class="form-control" v-model="$v.typeform.feature_desc.$model"
                    :class="{ 'is-invalid':  $v.typeform.feature_desc.$error  }"
                    placeholder="Description"/>
                <div v-if="$v.typeform.feature_desc.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.feature_desc.required">Please enter description</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <div class="col-sm-12">
                  <label class="col-form-label">Status</label>
                  <multiselect v-model="$v.typeform.status.$model" :options="status"
                               :multiple="false" :close-on-select="true" :clear-on-select="false"
                               :preserve-search="true" placeholder="Status"
                               :preselect-first="true" :showLabels="false"></multiselect>
                  <div v-if="$v.typeform.status.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.status.required">Please select status</span>
                  </div>
                </div>
              </div>
            </div>
        </div>

          <button type="button"
            class="btn btn-gradient-primary"
            v-if="feature_details.id"
            @click="updateFeature()"
            >Update Feature</button>
          <button type="button" @click="addFeature()"
                      class="btn btn-gradient-primary"
                      v-else
                      >Add Feature</button>
    </form>

  </b-modal>
</template>
<script>
import { mapActions} from "vuex";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';

export default {
    components: {
      Multiselect,
    },
    data() {
        return {
          status :['Active','Inactive'],
          moment,
          feature_details: null,
          feature_list: [
            {"id":"1", "name":"Dashboard"},
            {"id":"2", "name":"OPD"},
            {"id":"3", "name":"Appointment"},
            {"id":"4", "name":"Patients"},
            {"id":"5", "name":"UserManagement"},
            {"id":"6", "name":"Medicine"},
            {"id":"7", "name":"City"},
            {"id":"8", "name":"Reports"},
            {"id":"9", "name":"Settings"},
            {"id":"10", "name":"Changelog"},
            {"id":"11", "name":"Department"},
            {"id":"12", "name":"Room/Ward"},
            {"id":"13", "name":"IPD"},
            {"id":"14", "name":"Billing"},
            {"id":"15", "name":"Static Web Page"},
            {"id":"16", "name":"Inventory"},
          ],
          typeform:{
            feature_name: '',
            feature_desc: '',
            max_limit: '',
            max_limit_period: '',
            status: '',
          }
        }
    },
    validations: {
        typeform: {
            feature_name: {required},
            feature_desc: {required},
            max_limit: {required},
            max_limit_period: {required},
            status: {required},
        }
    },
    mounted(){
    },
    methods: {
        ...mapActions("hospital",["updateSubscriptionPlanFeatureAPI"]),
        show() {
            this.$refs.modal2.show();
            if(this.feature_details) {
                this.typeform.id = this.feature_details.id
                this.typeform.feature_name = this.getFeatureName(this.feature_details.feature_name)
                this.typeform.feature_desc = this.feature_details.feature_desc
                this.typeform.max_limit = this.feature_details.max_limit
                this.typeform.max_limit_period = this.feature_details.max_limit_period
                this.typeform.status = this.feature_details.status
            }
        },

        hide() {
            this.$refs.modal2.hide();
        },

        clearData() {
            this.typeform.feature_name = ''
            this.typeform.feature_desc = ''
            this.typeform.max_limit = ''
            this.typeform.max_limit_period = ''
            this.typeform.status = ''
            this.feature_details = null
        },

        receiveData(feature_details) {
            this.feature_details = feature_details
        },

        addFeature() {
            this.$v.$touch();
            if (!this.$v.typeform.$invalid) {
                this.feature_details = {
                    "feature_name": this.typeform.feature_name.id,
                    "feature_desc": this.typeform.feature_desc,
                    "max_limit": this.typeform.max_limit,
                    "max_limit_period": this.typeform.max_limit_period,
                    "status": this.typeform.status,
                }
                this.$emit('feature_details', this.feature_details)
                this.hide()
            }
        },

        getFeatureName(value) {
            return this.feature_list[parseInt(value) - 1]
        },

        updateFeature() {
          var bodyFormData = new FormData();
          bodyFormData.append('feature_id', this.feature_details.id);
          bodyFormData.append('feature_name', this.typeform.feature_name.id);
          bodyFormData.append('feature_desc', this.typeform.feature_desc);
          bodyFormData.append('max_limit', this.typeform.max_limit);
          bodyFormData.append('max_limit_period', this.typeform.max_limit_period);
          bodyFormData.append('status', this.typeform.status);

          this.updateSubscriptionPlanFeatureAPI(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.$toasted.success(response.message, {duration: 2000,});
                this.$emit('feature_details', response.data)
                this.hide()
            }else{
                this.$toasted.error(response.message, {duration: 2000,});
            }
          });
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

