<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">

        <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="row">
                  <h4 class="page-header page-title  mb-md-0">Inventories</h4>
              </div>
              <div class="row">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb p-0">
                      <li class="breadcrumb-item"><router-link to="/inventory">Inventories</router-link></li>
                      <li class="breadcrumb-item active" aria-current="page">Items</li>
                      <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.id == null || this.$route.params.id == ''">Add New Inventory</li>
                      <li class="breadcrumb-item active" aria-current="page" v-else>{{this.$route.params.id}}</li>
                    </ol>
                  </nav>
              </div>
            </div>
        </div>



      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Item Details</h4>
              <form class="form-sample">
                <div class="row">

                  <div class="col-md-4">
                    <div class="form-group row">                      
                      <div class="col-sm-12">
                        <label class="col-form-label">Item Name</label>
                        <input type="text" class="form-control" v-model="$v.typeform.name.$model" :class="{ 'is-invalid':  $v.typeform.name.$error  }"  placeholder="Item Name"/>
                        <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.name.required">Please enter item name</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Item Price</label>
                        <input type="text" class="form-control" v-model="$v.typeform.unit_price.$model" :class="{ 'is-invalid':  $v.typeform.unit_price.$error  }"  placeholder="Item Price"/>
                        <div v-if="$v.typeform.unit_price.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.unit_price.required">Please enter item price</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">UOM</label>
                        <multiselect v-model="$v.typeform.unit_of_measure.$model" :options="unit_of_measure"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="UOM" label="name"
                                     :preselect-first="false" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.unit_of_measure.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.unit_of_measure.required">Please select uom</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Stock Qty.</label>
                        <input type="text" class="form-control" v-model="$v.typeform.quantity_in_stock.$model" :class="{ 'is-invalid':  $v.typeform.quantity_in_stock.$error  }"  placeholder="Stock Qty."/>
                        <div v-if="$v.typeform.quantity_in_stock.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.quantity_in_stock.required">Please enter stoc qty.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Low Stock Level</label>
                        <input type="text" class="form-control" v-model="$v.typeform.reorder_level.$model" :class="{ 'is-invalid':  $v.typeform.reorder_level.$error  }"  placeholder="Low Stock Level"/>
                        <div v-if="$v.typeform.reorder_level.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.reorder_level.required">Please enter stock level</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Location</label>
                        <input type="text" class="form-control" v-model="$v.typeform.location.$model" :class="{ 'is-invalid':  $v.typeform.location.$error  }"  placeholder="Location"/>
                        <div v-if="$v.typeform.location.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.location.required">Please enter location</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Conditions</label>
                        <multiselect v-model="$v.typeform.condition.$model" :options="condition_list"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Conditions" label="name"
                                     :preselect-first="false" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.condition.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.condition.required">Please select condition</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Expiry Date</label>
                        <div class="form-control">
                            <DatePicker class="date-picker"
                                v-model="$v.typeform.expiry_date.$model"
                                format="DD-MM-YYYY"
                                placeholder="Expiry Date"
                                :disabled-date="disableDatesAfterMax"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Supplier</label>
                        <multiselect v-model="$v.typeform.supplier.$model" :options="suppliers"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Supplier" label="name"
                                     :preselect-first="false" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.supplier.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.supplier.required">Please select supplier</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Category</label>
                        <multiselect v-model="$v.typeform.category.$model" :options="categories"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Category" label="name"
                                     :preselect-first="false" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.category.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.category.required">Please select category</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Notes</label>
                        <textarea rows="5" class="form-control" v-model="$v.typeform.description.$model" :class="{ 'is-invalid':  $v.typeform.description.$error  }"  placeholder="Notes"/>
                        <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.description.required">Please enter notes</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <b-spinner v-if="is_btn_spinner" label=""></b-spinner>
                    <button v-if="!is_btn_spinner && this.$route.params.id" type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">Edit Item</button>
                    <button v-if="!is_btn_spinner && !this.$route.params.id" type="button" @click="submitForm($route.params.id)" class="btn btn-gradient-primary me-2">Add Item</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>

</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment";

export default {
    name:'ADDItem',
    title: string.PAGE_TITLE_ITEM_DETAILS,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      DatePicker,
    },
    data() {
    return {
      moment,
      unit_of_measure :[{'id':'pcs', 'name':'Pieces'},{'id':'kg', 'name':'Kilograms'},{'id':'liters', 'name':'Liters'}],
      condition_list :[{'id':'new', 'name':'New'},{'id':'used', 'name':'Used'},{'id':'damaged', 'name':'Damaged'}],
      categories: [],
      suppliers: [],
      typeform:{
        name:'',
        category:'',
        supplier:'',
        description:'',
        unit_price:'',
        quantity_in_stock:'',
        unit_of_measure:'',
        reorder_level:'',
        location:'',
        condition:'',
        expiry_date:'',
      },
      is_btn_spinner:false
    }
    },
    validations: {
    typeform: {
      name:{required},
      category:{required},
      supplier:{required},
      description:{},
      unit_price:{required},
      quantity_in_stock:{required},
      unit_of_measure:{},
      reorder_level:{required},
      location:{},
      condition:{},
      expiry_date:{},
    },
  },
  mounted(){    
    if(this.$route.params.id){
      this.getItemDetails()
    }
    this.getInventoryCategoryAPI()
    this.getInventorySupplierAPI()
  },
  methods:{    
    ...mapActions("hospital",["addUpdateInventoryItem","getInventoryItem", "getInventoryCategory", "getInventorySupplier"]),

    submitForm() {
      this.$v.$touch();

      if (!this.typeform.category) {
        this.$toasted.error("Please select category", {duration: 2000,});
      } else if (!this.typeform.category) {
        this.$toasted.error("Please select supplier", {duration: 2000,});
      }

      if (!this.$v.typeform.$invalid) {      
        this.is_btn_spinner = true;
        var bodyFormData = new FormData();
        bodyFormData.append('name', this.typeform.name);
        bodyFormData.append('category_id', this.typeform.category.id);
        bodyFormData.append('supplier_id', this.typeform.supplier.id);
        bodyFormData.append('description', this.typeform.description);
        bodyFormData.append('unit_price', this.typeform.unit_price);
        bodyFormData.append('quantity_in_stock', this.typeform.quantity_in_stock);
        if (this.typeform.unit_of_measure) {
            bodyFormData.append('uom', this.typeform.unit_of_measure.id);
        }
        bodyFormData.append('reorder_level', this.typeform.reorder_level);
        bodyFormData.append('location', this.typeform.location);
        if (this.typeform.condition) {
            bodyFormData.append('condition', this.typeform.condition.id);
        }

        if (this.typeform.expiry_date) {
            bodyFormData.append('expiry_date', moment(this.typeform.expiry_date).format('YYYY-MM-DD'));
        }

        if(this.$route.params.id){
            bodyFormData.append('item_id', this.$route.params.id);
        }
        this.addUpdateInventoryItem(bodyFormData).then((response) => {
            this.is_btn_spinner = false;
            if (response.response_code == 200) {
                this.$toasted.success("City added successfully", {duration: 2000,});
                this.$router.push({ name: 'inventory' });
            } else{
                this.$toasted.error(response.message, {duration: 2000,});
            }
        });
      }
    },

    getItemDetails() {
      let queryParams = {
          name: this.searchtext,
          page: 1,
          item_id : this.$route.params.id
      };
      this.getInventoryItem(queryParams).then((response) => {
        if (response.response_code == 200) {          
            this.typeform = {
                name:response.data.name,
                category:response.data.category,
                supplier:response.data.supplier,
                description:response.data.description,
                unit_price:response.data.unit_price,
                quantity_in_stock:response.data.quantity_in_stock,
                reorder_level:response.data.reorder_level,
                location:response.data.location,
                condition: this.getConditions(response.data.condition),
                unit_of_measure: this.getUOM(response.data.unit_of_measure)
            }

            if (response.data.expiry_date) {
                this.typeform.expiry_date = new Date(response.data.expiry_date)
            }

        } else {
            this.$router.push({ name: 'inventory' });
        }
      });
    },

    disableDatesAfterMax(date) {
      return date <= new Date();
    },

    getInventoryCategoryAPI(){
        this.categories = [];
        let queryParams = {
            name: '',
        };
        this.getInventoryCategory(queryParams).then((response) => {
            if (response.response_code == 200) {
                this.categories = response.data;
            }else{
              this.errorMessage = response.message;
            }
        });
    },
    getInventorySupplierAPI(){
        this.is_API_call_running = true;
        this.suppliers = [];
        let queryParams = {
            name: '',
        };
        this.getInventorySupplier(queryParams).then((response) => {
            if (response.response_code == 200) {
                this.suppliers = response.data;
            }else{
              this.errorMessage = response.message;
            }
        });
    },
    getConditions(text) {
        let condition_text = ''
        for (let i = 0; i < this.condition_list.length;i++) {
            if(text == this.condition_list[i].id) {
                condition_text = this.condition_list[i]
                break
            }
        }
        return condition_text
    },
    getUOM(text) {
        let uom_text = ''
        for (let i = 0; i < this.unit_of_measure.length;i++) {
            if(text == this.unit_of_measure[i].id) {
                uom_text = this.unit_of_measure[i]
                break
            }
        }
        return uom_text
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>