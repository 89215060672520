<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">

      <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="row">
                <h4 class="page-header page-title  mb-md-0">Reports</h4>
            </div>
            <div class="row">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb p-0">
                    <li class="breadcrumb-item"><router-link to="/reports">Report List</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.reportid != ''">{{ this.$route.params.reportid }}</li>
                    <li class="breadcrumb-item active" aria-current="page" v-else>Add Report</li>
                  </ol>
                </nav>
            </div>
          </div>
      </div>

      <div class="row">
        <div class="card-body">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">              
                      <div class="col-sm-12" >
                        <label class="col-form-label">Report Name</label>
                        <input type="text" class="form-control" v-model="$v.typeform.report_name.$model" :class="{ 'is-invalid':  $v.typeform.report_name.$error  }" name="report_name" placeholder="Report Name"/>
                      </div>
                      <div v-if="$v.typeform.report_name.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.report_name.required">Please enter report name</span>
                      </div>
                  </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                        <div class="col-sm-12" >
                            <label class="col-form-label">Report Type</label>
                            <input type="text" class="form-control" v-model="$v.typeform.report_type.$model" :class="{ 'is-invalid':  $v.typeform.report_type.$error  }" name="report_type" placeholder="Report Type"/>
                        </div>
                        <div v-if="$v.typeform.report_type.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.report_type.required">Please enter report type</span>
                        </div>
                    </div>
                  </div>
                    <div class="col-md-3">
                        <div class="form-group row">
                            <div class="col-sm-12">
                            <label class="col-form-label">Report Price</label>
                            <div class="input-group">
                                <div class="input-group-append">
                                  <span class="input-group-text text-primary" v-html="currency_data.currency_symbol"></span>
                                </div>
                                <input type="number" class="form-control" v-model="$v.typeform.report_price.$model" :class="{ 'is-invalid':  $v.typeform.report_price.$error  }" name="medicine_mrp" placeholder="Medicine MRP"/>
                            </div>
                            <div v-if="$v.typeform.report_price.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.report_price.required">Please enter report price</span>
                            </div>
                            </div>
                        </div>
                    </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="col-form-label">Status</label>
                            <multiselect v-model="$v.typeform.report_status.$model" :options="status"
                                         :multiple="false" :close-on-select="true" :clear-on-select="false"
                                         :preserve-search="true" placeholder="Status"
                                         :preselect-first="true" :showLabels="false"></multiselect>
                                <div v-if="$v.typeform.report_status.$error" class="invalid-feedback">
                                    <span v-if="!$v.typeform.report_status.required">Please select status</span>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                <br/><br/>
                <div class="col-md-12">
                    <b-button v-if="is_btn_spinner" variant="primary" disabled>
                        <b-spinner small type="grow"></b-spinner>Loading...
                    </b-button>
                    <button v-if="!is_btn_spinner" type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">Submit</button>
                    <!-- <button class="btn btn-light">Cancel</button> -->
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    <Footer/>
    </div>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import {get_user_settings } from '../../helper/helper';

export default {
    name:'reportdetails',
    title: string.PAGE_TITLE_REPORT_DETAILS,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
    },
    data() {
    return {
        status :['Active','Inactive'],
      moment:moment,
      typeform:{
        report_name:'',
        report_type:'',
        report_status:'',
        report_price:'',
      },
      is_btn_spinner:false,
      currency_data:{},
    }
    },
    validations: {
    typeform: {
      report_name:{required},
      report_type:{required},
      report_status:{required},
      report_price:{required},
    },
  },

  mounted(){
    if (this.$route.params.reportid != ''){
        this.getReportDetails();
    }
    this.currency_data = get_user_settings()
  },

  methods:{
    ...mapActions("hospital",["getReportDetailsData", "editReportData", "addReportData"]),

    getReportDetails(){
      var bodyFormData = new FormData();      
      bodyFormData.append('report_id', parseInt(this.$route.params.reportid));
      this.getReportDetailsData(bodyFormData).then((response) => {
        if (response.response_code == 200) {        
            this.typeform = {
                report_name :response.data.report_name,
                report_type :response.data.report_type,
                report_status : response.data.report_status,
                report_price : response.data.report_price,
            }
        } else{
            this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },
    submitForm(){
        this.$v.$touch();
        if (!this.$v.typeform.$invalid) {
            this.is_btn_spinner = true;
            var bodyFormData = new FormData();
            bodyFormData.append('report_name', this.typeform.report_name);
            bodyFormData.append('report_status', this.typeform.report_status);
            bodyFormData.append('report_type', this.typeform.report_type);
            bodyFormData.append('report_price', this.typeform.report_price);

            if (this.$route.params.reportid != ''){
                bodyFormData.append('report_id', this.$route.params.reportid);
                this.editReportData(bodyFormData).then((response) => {
                  this.is_btn_spinner = false;
                  if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    this.$router.push({ name: 'reportslist' });
                  }else{
                    this.$toasted.error(response.message, {duration: 2000,});
                  }
                });
            } else {
                this.addReportData(bodyFormData).then((response) => {
                  this.is_btn_spinner = false;
                  if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    this.$router.push({ name: 'reportslist' });
                  }else{
                    this.$toasted.error(response.message, {duration: 2000,});
                  }
                });
            }
          }
        },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>