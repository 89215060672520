<template>
  <div v-if="showDialog" class="dialog">
    <div class="dialog-content">
      <h4 v-if="isForUpdate()" class="text-center">Update Transaction</h4>
      <h4 v-else class="text-center">Add Transaction</h4>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Item</label>
            <multiselect
                v-model="item"
                :options="items"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true" placeholder=""
                :preselect-first="false"
                :showLabels="false"
                :allowEmpty="false"
                label="name"
            /></multiselect>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Transaction Type</label>
            <multiselect
                v-model="transaction_type"
                :options="transaction_type_list"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true" placeholder=""
                :preselect-first="false"
                :showLabels="false"
                :allowEmpty="false"
                label="name"
            /></multiselect>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Quantity</label>
            <input type="number" class="form-control" v-model="$v.typeform.quantity.$model" :class="{ 'is-invalid':  $v.typeform.quantity.$error  }" name="quantity" placeholder="Quantity"/>
            <div v-if="$v.typeform.quantity.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.quantity.required">Please enter quantity</span>
            </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Transaction Date</label>
            <div class="form-control">
                <DatePicker class="date-picker"
                    v-model="$v.typeform.transaction_date.$model"
                    format="DD-MM-YYYY"
                    placeholder="Transaction Date"
                    :disabled-date="disableDatesAfterMax"/>
            </div>
          </div>
        </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Notes</label>
            <textarea rows="5" class="form-control" v-model="$v.typeform.reason.$model" :class="{ 'is-invalid':  $v.typeform.reason.$error  }" name="notes" placeholder="Notes"/>
            <div v-if="$v.typeform.reason.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.reason.required">Please enter notes</span>
            </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mt-4 mb-4">
          <button class="btn btn-outline-secondary btn-fw w-100" @click="emitResult(false)">Close</button>
        </div>
        <div class="col-6 mt-4 mb-4">
          <b-button v-if="is_btn_spinner" variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>Loading...
          </b-button>
          <div v-else>
            <button v-if="isForUpdate()" class="btn btn-gradient-primary btn-fw w-100" @click="addUpdateTransactionAPI()">Update</button>
            <button v-else class="btn btn-gradient-primary btn-fw w-100" @click="addUpdateTransactionAPI()">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus';
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment";

export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      moment,
      showDialog: false,
      is_btn_spinner: false,
      data: null,
      typeform:{
        quantity: '',
        transaction_date: '',
        reason: ''
      },
      items: [],
      item: '',
      transaction_type_list: [{"id":"in","name":"In"}, {"id":"out","name":"Out"}],
      transaction_type: '',
    };
  },
  validations: {
      typeform: {
        quantity:{required},
        transaction_date:{required},
        reason:{required},
      }
  },
  created() {
    // Listen for the event to open the dialog
    EventBus.$on('open-transaction-dialog', (payload) => {
      this.data = payload.data;
      this.showDialog = true;
      this.resetData()
      this.setPreviousData()
    });
    this.getInventoryItemAPI()
  },
  methods: {
    ...mapActions("hospital",["addUpdateInventoryStockTransaction", "getInventoryItem"]),
    getInventoryItemAPI() {
        let queryParams = {
            name: '',
        };
        this.getInventoryItem(queryParams).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
                this.items = response.data;
            }else{
              this.errorMessage = response.message;
            }
        });
    },

    addUpdateTransactionAPI() {
       this.$v.$touch();

       if (!this.item) {
        this.$toasted.error("Please select item", {duration: 2000,});
       } else if(!this.transaction_type) {
        this.$toasted.error("Please select transaction type", {duration: 2000,});
       } else if (!this.$v.typeform.$invalid) {
           var bodyFormData = new FormData();
           bodyFormData.append('item_id', this.item.id)
           bodyFormData.append('transaction_type', this.transaction_type.id)
           bodyFormData.append('quantity', this.typeform.quantity)
           bodyFormData.append('transaction_date', moment(String(this.typeform.transaction_date)).format('YYYY-MM-DD'))
           bodyFormData.append('reason', this.typeform.reason)
           if (this.isForUpdate()) {
                bodyFormData.append('stock_id', this.data.id)
           }
           this.is_btn_spinner = true
           this.addUpdateInventoryStockTransaction(bodyFormData).then((response) => {
                this.is_btn_spinner = false
                if (response.response_code == 200) {
                    this.emitResult(true);
                } else {
                    this.$toasted.error(response.message, {duration: 2000,});
                }
           });
       }
    },

    emitResult(actionDone) {
      // Emit the result when closing the dialog
      const result = { status: actionDone};
      EventBus.$emit('dialog-stock-in-out-result', result);
      this.showDialog = false;
    },
    resetData() {
      // Reset dialog fields
      this.item = '';
      this.transaction_type = '';
      this.typeform.quantity = '';
      this.typeform.transaction_date = '';
      this.typeform.reason = '';
    },
    setPreviousData() {
      if (this.data && this.data != ''){
          this.typeform.quantity = this.data.quantity;
          this.typeform.transaction_date = new Date(this.data.transaction_date);
          this.typeform.reason = this.data.reason;
          this.item = this.data.item;
          this.transaction_type = this.getTransactionType(this.data.transaction_type);
      }
    },
    isForUpdate() {
        if (this.data && this.data != ''){
            return true
        } else {
            return false
        }
    },
    disableDatesAfterMax(date) {
      return date > new Date();
    },
    getTransactionType(transaction_type) {
        for (let i = 0; i < this.transaction_type_list.length;i++) {
            if(transaction_type == this.transaction_type_list[i].id) {
                return this.transaction_type_list[i]
            }
        }
        return ''
    }
  }
};
</script>


<style>
/* Full-screen overlay */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dialog content with responsive adjustments */
.dialog-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 70vh; /* Ensure the dialog doesn't exceed viewport height */
  overflow-y: auto;  /* Scroll content if height exceeds viewport */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

/* Media queries for larger screens */
@media (min-width: 576px) {
  .dialog-content {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .dialog-content {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .dialog-content {
    width: 30%;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
