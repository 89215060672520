<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
        <div class="page-header">
          <h4 class="page-title">Requests</h4>
        </div>

      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-4 mb-lg-0 mb-2">
                       <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchrequest" id="searchrequest" autocomplete="off" v-on:keyup="getRequests(1)">
                    </div>

                    <div class="col-lg-5"></div>
                    <div class="col-lg-3 mb-lg-0 mb-2 d-flex justify-content-end">
                            <date-range-picker
                                ref="picker"
                                opens="left"
                                :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                :single-date-picker="false"
                                :showDropdowns="showDropdowns"
                                :show-week-numbers= "false"
                                :time-picker="false"
                                :autoApply="true"
                                v-model="dateRange"
                                :linked-calendars="true"
                                :dateFormat="dd-mmm-yyyy"
                                @finish-selection=getRequests(1)
                                @update=getRequests(1)
                            />
                    </div>
                </div>

              <br>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Subject</th>
                        <th>Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(request,i) in requests" :key="i">
                        <td>{{request.id}}</td>
                        <td>{{request.name}}</td>
                        <td>{{request.email}}</td>
                        <td>{{request.subject}}</td>
                        <td>{{moment.parseZone(request.created_at).format('DD MMM YYYY')}}</td>
                        <td>
                            <button type="button" @click="openDetails(request)" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && requests.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getRequests(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
        <ContactRequestModel ref="contactRequestModelComponent"/>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions } from "vuex";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import string from "../../constant/strings.js"
import Swal from 'sweetalert2'
import ContactRequestModel from "../model/contact_request_model"

export default {
  name:'contact_us_request',
  title: string.PAGE_CONTACT_US_REQUEST,
  components: {
      NavBar,
Footer,
      SideBar,
      DateRangePicker,
      ContactRequestModel,
  },
  data() {
    const now = new Date()
    const startdate = new Date(now.getFullYear(), now.getMonth(), 1)
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  	const maxDate = new Date(today)

    return {
      requests:[],
      moment: moment,
      is_API_call_running:false,
      listPages:0,
      activePage:1,
      searchtext:'',
      myTimer:null,
      errorMessage: 'No Data Found.',
      dateRange:{
        startDate: startdate,
        endDate: today
      },
      MAXDATE:maxDate
    }
  },
  mounted() {
    this.getRequests(this.activePage);
  },
  methods:{
    ...mapActions("hospital",["getContactUsRequestList", "getContactUsRequestDetails"]),

    getRequests(page){
      this.activePage = page;
       clearTimeout(this.myTimer)
        this.myTimer = setTimeout(() => {
                  this.is_API_call_running = true;
                  this.requests = [];
                  var bodyFormData = new FormData();
                  bodyFormData.append('filter_value', this.searchtext);
                  bodyFormData.append('page', page);
                  bodyFormData.append('filter_status', this.filter_status);
                  bodyFormData.append('filter_start_date', moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'));
                  bodyFormData.append('filter_end_date', moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'));
                  this.getContactUsRequestList(bodyFormData).then((response) => {
                    this.is_API_call_running = false;
                    if (response.response_code == 200) {
                      this.requests = response.data;
                      this.listPages = response.no_of_pages;
                    }else{
                      this.errorMessage = response.message;
                    }
                  });
        }, 500)
    },

    previewClick(){
              this.activePage--;
              if(this.activePage >= 1){
                this.getRequests(this.activePage);
              }else{
                this.activePage = 1;
              }
            },

    nextClick(){
        this.activePage++;
        if(this.activePage <= this.listPages){
           this.getRequests(this.activePage);
        }else{
           this.activePage = this.listPages;
        }
    },

    openDetails(request){
        this.$refs.contactRequestModelComponent.clearData();
        this.$refs.contactRequestModelComponent.receiveData(request);
        this.$refs.contactRequestModelComponent.show();
    },
  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
