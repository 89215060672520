<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">

      <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="row">
                <h4 class="page-header page-title  mb-md-0">Statistics</h4>
            </div>
            <div class="row">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb p-0">
                    <li class="breadcrumb-item"><router-link to="/statistics">Statistics</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==1 || this.$route.params.type==2 ||
                                    this.$route.params.type==3 || this.$route.params.type==4 || this.$route.params.type==6">Patients & OPD</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==1">Patients Statistics</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==2">OPDs Statistics</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==4">Appointments Statistics</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==5">Earning Statistics</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==6">IPD Statistics</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==5 && this.$route.params.earning_type == 'All'">All Earnings</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==5 && this.$route.params.earning_type == 1">OPDs Earnings</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==5 && this.$route.params.earning_type == 2">IPDs Earnings</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==5 && this.$route.params.earning_type == 3">Reports Earnings</li>
                    <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.type==5 && this.$route.params.earning_type == 4">Medicines Earnings</li>
                  </ol>
                </nav>
            </div>
          </div>
      </div>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
            <div class="row align-items-center">
              <div class="col-lg-3 mb-lg-0 mb-2">
                <h4 v-if="$route.params.type==1" class="cart-title">Patient Statistics</h4>
                <h4 v-if="$route.params.type==2" class="cart-title">OPDs Statistics</h4>
                <h4 v-if="$route.params.type==4" class="cart-title">Appointments Statistics</h4>
                <h4 v-if="$route.params.type==5" class="cart-title">Earning Statistics</h4>
                <h4 v-if="$route.params.type==6" class="cart-title">IPD Statistics</h4>
              </div>
              <div class="col-lg-2 mb-lg-0 mb-2"></div>
              <div class="col-lg-2 mb-lg-0 mb-2" v-if="$route.params.type != 1">
                  <multiselect class="col-lg-1" v-model="selected_department" :options="departments" :multiple="false"
                      :close-on-select="true" label="department_name"
                      :clear-on-select="false" :preserve-search="true" placeholder="Department"
                      :preselect-first="false" :showLabels="false" @input="onDepartmentSelected(false)"></multiselect>
              </div>
              <div class="col-lg-2 mb-lg-0 mb-2" v-else></div>
              <div class="col-lg-2 mb-lg-0 mb-2" v-if="$route.params.type != 1">
                <multiselect v-model="selected_doctor" :options="doctors" :multiple="false"
                      :close-on-select="true" label="name"
                      :clear-on-select="false" :preserve-search="true" placeholder="Doctor"
                       :preselect-first="false" :showLabels="false" @input="onDoctorSelected(false)"></multiselect>
              </div>
              <div class="col-lg-2 mb-lg-0 mb-2" v-else></div>
              <date-range-picker
                  class="col-lg-2"
                  ref="picker"
                  opens="left"
                  :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                  :single-date-picker="false"
                  :showDropdowns="showDropdowns"
                  :show-week-numbers= "false"
                  :time-picker="false"
                  :autoApply="true"
                  v-model="dateRange"
                  :linked-calendars="true"
                  :dateFormat="dd-mmm-yyyy"
                  @update=getReports(1)
              />
              <div class="col-md-1 text-center"><h3 class="mdi mdi-file-export primary mb-0" @click="getReports(1, true)"></h3></div>
            </div>

            <div class = "row mt-2">
              <div class="col-lg-5"></div>
              <div class="col-lg-2 mb-lg-0 mb-2" v-if="$route.params.type != 1">
                  <multiselect class="col-lg-1" v-model="selected_payment_status" :options="payment_status" :multiple="false"
                      :close-on-select="true" label="name"
                      :clear-on-select="false" :preserve-search="true" placeholder="Payment Status"
                      :preselect-first="true" :showLabels="false" @input="getReports(1)"></multiselect>
              </div>
              <div class="col-lg-2 mb-lg-0 mb-2" v-if="$route.params.type != 1">
                  <multiselect class="col-lg-1" v-model="selected_t_type" :options="transaction_type" :multiple="false"
                      :close-on-select="true" label="name"
                      :clear-on-select="false" :preserve-search="true" placeholder="Transaction Type"
                      :preselect-first="true" :showLabels="false" @input="getReports(1)"></multiselect>
              </div>
              <div class="col-lg-2 mb-lg-0 mb-2" v-if="$route.params.type != 1">
                  <multiselect class="col-lg-1" v-model="selected_paid_to" :options="paid_to" :multiple="false"
                      :close-on-select="true" label="name"
                      :clear-on-select="false" :preserve-search="true" placeholder="Paid To"
                      :preselect-first="true" :showLabels="false" @input="getReports(1)"></multiselect>
              </div>
              <div class="col-lg-1"></div>
            </div>

            <br>
            <div class="table-responsive">
              <table class="table table-hover" v-if="$route.params.type==1">
                <thead>
                  <tr>
                    <th>Patient Number</th>
                    <th>Patient No. Visit</th>
                    <th>Patient Name</th>
                    <th>Patient Email</th>
                    <th>Mobile Number</th>
                    <th>Patient Age</th>
                    <th>Gender</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>Postal Code</th>
                    <th>First Visit On</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(patient,i) in reports" :key="i">
                    <td><router-link v-if="patient.patient_number != null && patient.patient_number > 0" :to="{name:'patientsdetails',params:{'pid':patient.patient_number}}">{{patient.patient_number}}</router-link></td>
                    <td>{{patient.no_of_visit}}</td>
                    <td>{{patient.patient_name}}</td>
                    <td>{{patient.patient_email}}</td>
                    <td>{{patient.patient_mobile_number}}</td>
                    <td>{{patient.patient_age}}</td>
                    <td>{{patient.patient_gender}}</td>
                    <td>{{patient.patient_address}}</td>
                    <td>{{patient.patient_city}}</td>
                    <td>{{patient.patient_postal_code}}</td>
                    <td>{{patient.registered_on}}</td>
                  </tr>
                </tbody>
              </table>

              <table class="table table-hover" v-if="$route.params.type==2">
                  <thead>
                     <tr>
                         <th>OPD Id</th>
                         <th>OPD Date</th>
                         <th>Patient Number</th>
                         <th>Patient Name</th>
                         <th>Patient Email</th>
                         <th>Mobile Number</th>
                         <th>Patient Age</th>
                         <th>Gender</th>
                         <th>Address</th>
                         <th>City</th>
                         <th>Postal Code</th>
                         <th>OPD Fees</th>
                         <th>Diseases</th>
                         <th>Concern Doctor</th>
                         <th>Department</th>
                         <th>Status</th>
                    </tr>
                 </thead>
                 <tbody>
                     <tr v-for="(opd,i) in reports" :key="i">
                       <td><router-link :to="{name:'opddetails',params:{'pid':opd.patient_number, 'is_edit':0}}">{{opd.opd_id}}</router-link></td>
                       <td>{{moment(opd.opd_registered_on).format('DD MMM YYYY')}}</td>
                       <td><router-link :to="{name:'patientsdetails',params:{'pid':opd.patient_number}}">{{opd.patient_number}}</router-link></td>
                       <td>{{opd.patient_name}}</td>
                       <td>{{opd.patient_email}}</td>
                       <td>{{opd.patient_mobile_number}}</td>
                       <td>{{opd.patient_age}}</td>
                       <td>{{opd.patient_gender}}</td>
                       <td>{{opd.patient_address}}</td>
                       <td>{{opd.patient_city}}</td>
                       <td>{{opd.patient_postal_code}}</td>
                       <td><span v-html="currency"></span> {{opd.opd_fees}}</td>
                       <td>{{opd.opd_disease}}</td>
                       <td>{{opd.opd_concern_doctor}}</td>
                       <td>{{opd.department_name}}</td>
                       <td><label v-bind:class="{
                        'badge badge-success': opd.opd_status === 'Waiting',
                        'badge badge-warning': opd.opd_status === 'Under Treatment' || opd.opd_status === 'Waiting For Prescription',
                        'badge badge-info': opd.opd_status === 'Completed',
                        'badge badge-danger': opd.opd_status === 'InActive' || opd.opd_status === 'Waiting For Report',
                        }">{{opd.opd_status}}</label></td>
                    </tr>
                 </tbody>
              </table>

              <table class="table table-hover" v-if="$route.params.type==4">
                  <thead>
                     <tr>
                         <th>Appointment Id</th>
                         <th>Appointment Date</th>
                         <th>Appointment Booked On</th>
                         <th>First Time Visit</th>
                         <th>Department</th>
                         <th>Patient Number</th>
                          <th>Patient Name</th>
                          <th>Patient Email</th>
                          <th>Mobile Number</th>
                          <th>Patient Age</th>
                          <th>Gender</th>
                          <th>Address</th>
                          <th>City</th>
                          <th>Postal Code</th>
                    </tr>
                 </thead>
                 <tbody>
                     <tr v-for="(appointment,i) in reports" :key="i">
                       <td><router-link :to="{name:'appointmentdetails',params:{'apid':appointment.appointment_id, 'is_edit':0}}">{{appointment.appointment_id}}</router-link></td>
                       <td>{{moment(appointment.visiting_date_time).format('DD MMM YYYY')}}</td>
                       <td>{{moment(appointment.appointment_registered_on).format('DD MMM YYYY')}}</td>
                       <td>{{appointment.is_first_time_visit}}</td>
                       <td>{{appointment.department_name}}</td>
                       <td><router-link :to="{name:'patientsdetails',params:{'pid':appointment.patient_number}}">{{appointment.patient_number}}</router-link></td>
                       <td>{{appointment.patient_name}}</td>
                       <td>{{appointment.patient_email}}</td>
                       <td>{{appointment.patient_mobile_number}}</td>
                       <td>{{appointment.patient_age}}</td>
                       <td>{{appointment.patient_gender}}</td>
                       <td>{{appointment.patient_address}}</td>
                       <td>{{appointment.patient_city}}</td>
                       <td>{{appointment.patient_postal_code}}</td>
                    </tr>
                 </tbody>
              </table>

              <table class="table table-hover" v-if="$route.params.type==5">
                  <thead>
                     <tr>
                         <th>Tr. Id</th>
                         <th>Tr. Date</th>
                         <th>Tr. Updated Date</th>
                         <th>Amount</th>
                         <th>Tr. For</th>
                         <th>Tr. Type</th>
                         <th>Status</th>
                         <th>Paid To</th>
                         <th>Patients Number</th>
                         <th>Patients Name</th>
                         <th>Patients Email</th>
                         <th>Patients Mo. No.</th>
                         <th>Patients Age</th>
                         <th>Patients Gender</th>
                         <th>Patients Address</th>
                         <th>Patients City</th>
                         <th>Patients Postal Code</th>
                    </tr>
                 </thead>
                 <tbody>
                     <tr v-for="(tran,i) in reports" :key="i">
                       <td>{{tran.id}}</td>
                       <td>{{tran.created_at}}</td>
                       <td>{{tran.updated_at}}</td>
                       <td><span v-html="currency"></span> {{tran.amount}}</td>
                       <td>{{tran.transaction_for}}</td>
                       <td><label v-bind:class="{
                           'badge badge-success': tran.transaction_type === 'Credit',
                           'badge badge-warning': tran.transaction_type === 'Debit'}
                           ">{{tran.transaction_type}}</label></td>
                       <td><label v-bind:class="{
                          'badge badge-success': tran.payment_status === 'Received',
                          'badge badge-danger': tran.payment_status === 'Hold',
                          'badge badge-info': tran.payment_status === 'Pending',
                          'badge badge-warning': tran.payment_status === 'Waived Off'}
                          ">{{tran.payment_status}}</label></td>
                       <td>{{tran.paid_to}}</td>
                       <td><router-link :to="{name:'patientsdetails',params:{'pid':tran.patient_number}}">{{tran.patient_number}}</router-link></td>
                       <td>{{tran.patient_name}}</td>
                       <td>{{tran.patient_email}}</td>
                       <td>{{tran.patient_mobile_number}}</td>
                       <td>{{tran.patient_age}}</td>
                       <td>{{tran.patient_gender}}</td>
                       <td>{{tran.patient_address}}</td>
                       <td>{{tran.patient_city}}</td>
                       <td>{{tran.patient_postal_code}}</td>
                    </tr>
                 </tbody>
              </table>

              <table class="table table-hover" v-if="$route.params.type==6">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Admission Date</th>
                        <th>Discharge Date</th>
                        <th>Admitted Days</th>
                        <th>Ward</th>
                        <th>Floor No.</th>
                        <th>Room</th>
                        <th>Patient Number</th>
                        <th>Patient Name</th>
                        <th>Patient Email</th>
                        <th>Patient Mo. No.</th>
                        <th>Patient Age</th>
                        <th>Patient Gender</th>
                        <th>Patient Address</th>
                        <th>Patient City</th>
                        <th>Patient Postal Code</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(ipd,i) in reports" :key="i">
                        <td><router-link :to="{name:'ipddetails',params:{'ipdid':ipd.id}}">{{ipd.id}}</router-link></td>
                        <td>{{ipd.admission_date}}</td>
                        <td>{{ipd.discharge_date}}</td>
                        <td>{{ipd.total_admitted_days}}</td>
                        <td>{{ipd.ward}}</td>
                        <td>{{ipd.floor}}</td>
                        <td>{{ipd.room}}({{ipd.bed_number}})</td>
                        <td><router-link :to="{name:'patientsdetails',params:{'pid':ipd.patient_number}}">{{ipd.patient_number}}</router-link></td>
                        <td>{{ipd.patient_name}}</td>
                        <td>{{ipd.patient_email}}</td>
                        <td>{{ipd.patient_mobile_number}}</td>
                        <td>{{ipd.patient_age}}</td>
                        <td>{{ipd.patient_gender}}</td>
                        <td>{{ipd.patient_address}}</td>
                        <td>{{ipd.patient_city}}</td>
                        <td>{{ipd.patient_postal_code}}</td>
                        <td><label v-bind:class="{
                            'badge badge-success': ipd.ipd_status === 'Discharged',
                            'badge badge-warning': ipd.ipd_status === 'Transferred',
                            'badge badge-info': ipd.ipd_status === 'Admitted'}
                            ">{{ipd.ipd_status}}</label></td>
                    </tr>
                </tbody>
              </table>
            </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && reports.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                  <ul id="border-pagination">
                    <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                    <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getReports(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                    <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions } from "vuex";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import string from "../../constant/strings.js"
import Multiselect from 'vue-multiselect';

export default {
  name:'appointment',
    title: string.PAGE_TITLE_STATISTICS,
  components: {
      NavBar,
Footer,
      SideBar,
      DateRangePicker,
      Multiselect,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
	const maxDate = new Date(today)
	
	return {
      reports:[],
      moment: moment,
      is_API_call_running : false,
      listPages:0,
      activePage:1,
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      max: maxDate,
      errorMsg:'',
      errorMessage : 'No Data Found.',
      selected_doctor : '',
      selected_department : '',
      currency : '',
      departments : [],
      doctors : [],
      payment_status : [{"id": "All", "name":"All"}, {"id": "0", "name":"Pending"}, {"id": "1", "name":"Received"}, {"id": "2", "name":"Hold"}, {"id": "3", "name":"Waived Off"}],
      transaction_type : [{"id": "All", "name":"All"}, {"id": "0", "name":"Credit"}, {"id": "1", "name":"Debit"}],
      paid_to : [{"id": "All", "name":"All"}, {"id": "0", "name":"Hospital"}, {"id": "1", "name":"Laboratory"}, {"id": "2", "name":"Medical"}, {"id": "3", "name":"Hospital Account"}],
      selected_payment_status : {"id": "All", "name":"All"},
      selected_t_type : {"id": "All", "name":"All"},
      selected_paid_to : {"id": "All", "name":"All"},
      dateRange:{
        startDate: today,
        endDate: today
      },
    }
  },

  mounted() {
    this.getReports(this.activePage);
    this.getDepartment();
    this.getDoctor(false);
  },
  methods:{
    ...mapActions("hospital",["getStatisticsListData", "exportReportsListData", "getOPDDepartmentListData", "getConcernDoctorListData"]),

    getReports(page, is_export = false){
      if (!is_export) {
          this.reports = [];
          this.activePage = page;
          this.is_API_call_running = true
      }
      var bodyFormData = new FormData();
      bodyFormData.append('filter_date_from', moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'));
      bodyFormData.append('filter_date_to', moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'));
      bodyFormData.append('report_type', this.$route.params.type);
      bodyFormData.append('page', page);
      bodyFormData.append('is_export', is_export);
      bodyFormData.append('earning_type', this.$route.params.earning_type);
      if(this.selected_department){
        bodyFormData.append('department_id', this.selected_department.id);
      } else {
        bodyFormData.append('department_id', '');
      }
      if(this.selected_doctor){
        bodyFormData.append('doctor_id', this.selected_doctor.id);
      } else {
        bodyFormData.append('doctor_id', '');
      }
      if(this.selected_payment_status){
        bodyFormData.append('payment_status', this.selected_payment_status.id);
      } else {
        bodyFormData.append('payment_status', '');
      }
      if(this.selected_t_type){
        bodyFormData.append('transaction_type', this.selected_t_type.id);
      } else {
        bodyFormData.append('transaction_type', '');
      }
      if(this.selected_paid_to){
        bodyFormData.append('paid_to', this.selected_paid_to.id);
      } else {
        bodyFormData.append('paid_to', '');
      }
      bodyFormData.append('page', page);

      if (is_export) {
        this.exportReportsListData(bodyFormData).then((response) => {
        	if (response.response_code == 200) {

        	} else{
        	    this.$toasted.error(response.message, {duration: 2000,});
                this.errorMessage = response.message;
        	}
        });
      } else {
            this.getStatisticsListData(bodyFormData).then((response) => {
              this.is_API_call_running = false;
              if (response.response_code == 200) {
                this.reports = response.data;
                this.listPages = response.no_of_pages;
                this.currency = response.currency.currency_symbol;
              } else if (response.response_code != 104){
                this.is_btn_spinner = false;
                this.$toasted.error(response.message, {duration: 2000,});
      		      this.errorMessage = response.message
              }
            });
      }
    },
	

    previewClick(){
       this.activePage--;
       if(this.activePage >= 1){
            this.getReports(this.activePage);
       }else{
            this.activePage = 1;
       }
    },

    nextClick(){
      this.activePage++;
      if(this.activePage <= this.listPages){
          this.getReports(this.activePage);
      }else{
         this.activePage = this.listPages;
      }
    },

    getDepartment(){
        var bodyFormData = new FormData();
        this.getOPDDepartmentListData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.departments = response.data;
            }
        });
    },
    getDoctor(is_fetch_report){
      this.doctors = []
      this.selected_doctor = ''
      var bodyFormData = new FormData();
      if(this.selected_department){
          bodyFormData.append('department_id', this.selected_department.id);
      } else {
          bodyFormData.append('department_id', '');
      }
      bodyFormData.append('filter_value', '');
      this.getConcernDoctorListData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.doctors = response.data;
          if(is_fetch_report) {
            this.getReports(1)
          }
        } else {
            this.getReports(1)
        }
      });
    },
    onDepartmentSelected(is_removed) {
        this.getDoctor(true)
    },
    onDoctorSelected(is_removed) {
        this.getReports(1)
    }
  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
