<template>
  <div v-if="showDialog" class="dialog">
    <div class="dialog-content">
      <h4 v-if="isForUpdate()" class="text-center">Update Transaction</h4>
      <h4 v-else class="text-center">Add Transaction</h4>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Item</label>
            <multiselect
                v-model="item"
                :options="items"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true" placeholder=""
                :preselect-first="false"
                :showLabels="false"
                :allowEmpty="false"
                label="name"
                @input=updateFields()
            /></multiselect>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Ordered Quantity</label>
            <input type="number" class="form-control"
                    v-model="$v.typeform.quantity_ordered.$model"
                    v-on:keyup="updateTotal()"
                    :class="{ 'is-invalid':  $v.typeform.quantity_ordered.$error  }" name="quantity_ordered" placeholder="Ordered Quantity"/>
            <div v-if="$v.typeform.quantity_ordered.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.quantity_ordered.required">Please enter quantity ordered</span>
            </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Unit Price</label>
            <input type="number" class="form-control"
                    v-model="$v.typeform.unit_price.$model"
                    v-on:keyup="updateTotal()"
                    :class="{ 'is-invalid':  $v.typeform.unit_price.$error  }" name="unit_price" placeholder="Unit Price"/>
            <div v-if="$v.typeform.unit_price.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.unit_price.required">Please enter unit price</span>
            </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Total Price</label>
            <input type="number" class="form-control" v-model="$v.typeform.total_price.$model" :class="{ 'is-invalid':  $v.typeform.total_price.$error  }" name="total_price" placeholder="Total Price" disabled/>
            <div v-if="$v.typeform.total_price.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.total_price.required">Please enter total price</span>
            </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mt-4 mb-4">
          <button class="btn btn-outline-secondary btn-fw w-100" @click="emitResult(false)">Close</button>
        </div>

        <div class="col-6 mt-4 mb-4">
          <b-button v-if="is_btn_spinner" variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>Loading...
          </b-button>
          <div v-else>
            <button v-if="isForUpdate()" class="btn btn-gradient-primary btn-fw w-100" @click="setData()">Update</button>
            <button v-else class="btn btn-gradient-primary btn-fw w-100" @click="setData()">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus';
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment";

export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      moment,
      showDialog: false,
      is_btn_spinner: false,
      data: null,
      typeform:{
        quantity_ordered: '',
        unit_price: '',
        total_price: '',
      },
      items: [],
      item: '',
      index: '',
    };
  },
  validations: {
      typeform: {
        quantity_ordered:{required},
        unit_price:{required},
        total_price:{required},
      }
  },

  created() {
    // Listen for the event to open the dialog
    EventBus.$on('open-purchase-order-dialog', (payload) => {
      this.data = payload.data;
      this.index = payload.index;
      this.showDialog = true;
      this.resetData()
      this.setPreviousData()
    });
    this.getInventoryItemAPI()
  },

  methods: {
    ...mapActions("hospital",["getInventoryItem"]),
    getInventoryItemAPI() {

        let queryParams = {
            name: '',
        };

        this.getInventoryItem(queryParams).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
                this.items = response.data;
            }else{
              this.errorMessage = response.message;
            }
        });
    },

    setData() {
       this.$v.$touch();

       if (!this.item) {
        this.$toasted.error("Please select item", {duration: 2000,});
       } else if (!this.$v.typeform.$invalid) {
            // Emit the result when closing the dialog
            let old_id = ''
            if (this.data && this.data.id) {
                old_id = this.data.id
            }
            const result = {
                status: true,
                index: this.index,
                data: {
                "id": old_id,
                "item": this.item,
                "quantity_ordered": this.typeform.quantity_ordered,
                "unit_price": this.typeform.unit_price,
                "total_price": this.typeform.total_price,
            }};
            EventBus.$emit('dialog-purchase-order-result', result);
            this.showDialog = false;
       }
    },

    emitResult(actionDone) {
      // Emit the result when closing the dialog
      const result = { status: actionDone};
      EventBus.$emit('supplier-dialog-result', result);
      this.showDialog = false;
    },

    resetData() {
      // Reset dialog fields
      this.item = '';
      this.typeform.quantity_ordered = '';
      this.typeform.unit_price = '';
      this.typeform.total_price = '';
    },

    setPreviousData() {
      if (this.data && this.data != ''){
          this.typeform.quantity_ordered = this.data.quantity_ordered;
          this.typeform.unit_price = this.data.unit_price;
          this.typeform.total_price = this.data.total_price;
          this.item = this.data.item;
      }
    },

    isForUpdate() {
        if (this.data && this.data != ''){
            return true
        } else {
            return false
        }
    },
    updateFields() {
        this.typeform.unit_price = this.item.unit_price
    },
    updateTotal() {
        this.typeform.total_price = this.typeform.unit_price * this.typeform.quantity_ordered
    }
  }
};
</script>


<style>
/* Full-screen overlay */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dialog content with responsive adjustments */
.dialog-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 70vh; /* Ensure the dialog doesn't exceed viewport height */
  overflow-y: auto;  /* Scroll content if height exceeds viewport */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

/* Media queries for larger screens */
@media (min-width: 576px) {
  .dialog-content {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .dialog-content {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .dialog-content {
    width: 30%;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
