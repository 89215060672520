<template>
  <div v-if="showDialog" class="dialog">
    <div class="dialog-content">
      <h4 v-if="isForUpdate()" class="text-center">Update Request</h4>
      <h4 v-else class="text-center">Add Request</h4>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Item</label>
            <multiselect
                v-model="item"
                :options="items"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true" placeholder=""
                :preselect-first="false"
                :showLabels="false"
                :allowEmpty="false"
                label="name"
            /></multiselect>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Last Maintenance Date</label>
            <div class="form-control">
                <DatePicker class="date-picker"
                    v-model="$v.typeform.last_maintenance_date.$model"
                    format="DD-MM-YYYY"
                    placeholder="Last Maintenance Dat"/>
            </div>
          </div>
        </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Next Maintenance Date</label>
            <div class="form-control">
                <DatePicker class="date-picker"
                    v-model="$v.typeform.next_maintenance_due.$model"
                    format="DD-MM-YYYY"
                    placeholder="Next Maintenance Date"/>
            </div>
          </div>
        </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Notes</label>
            <textarea rows="5" class="form-control" v-model="$v.typeform.maintenance_notes.$model" :class="{ 'is-invalid':  $v.typeform.maintenance_notes.$error  }" name="notes" placeholder="Notes"/>
            <div v-if="$v.typeform.maintenance_notes.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.maintenance_notes.required">Please enter notes</span>
            </div>
        </div>
      </div>

        <div class="form-group row mb-2">
          <div class="col-sm-12">
              <label class="col-form-label">Status</label>
              <multiselect
                  v-model="status"
                  :options="status_list"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true" placeholder=""
                  :preselect-first="true"
                  :showLabels="false"
                  :allowEmpty="false"
                  label="name"
              /></multiselect>
          </div>
        </div>

      <div class="row">
        <div class="col-6 mt-4 mb-4">
          <button class="btn btn-outline-secondary btn-fw w-100" @click="emitResult(false)">Close</button>
        </div>
        <div class="col-6 mt-4 mb-4">
          <b-button v-if="is_btn_spinner" variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>Loading...
          </b-button>
          <div v-else>
            <button v-if="isForUpdate()" class="btn btn-gradient-primary btn-fw w-100" @click="addUpdateInventoryEquipmentMaintenanceAPI()">Update</button>
            <button v-else class="btn btn-gradient-primary btn-fw w-100" @click="addUpdateInventoryEquipmentMaintenanceAPI()">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus';
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment";

export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      moment,
      showDialog: false,
      is_btn_spinner: false,
      data: null,
      typeform:{
        last_maintenance_date: '',
        next_maintenance_due: '',
        maintenance_notes: ''
      },
      items: [],
      item: '',
      status_list: [{"id":"pending","name":"Pending"}, {"id":"completed","name":"Completed"}],
      status: '',
    };
  },
  validations: {
      typeform: {
        last_maintenance_date:{required},
        next_maintenance_due:{},
        maintenance_notes:{required},
      }
  },
  created() {
    // Listen for the event to open the dialog
    EventBus.$on('open-mr-dialog', (payload) => {
      this.data = payload.data;
      this.showDialog = true;
      this.resetData()
      this.setPreviousData()
    });
    this.getInventoryItemAPI()
  },
  methods: {
    ...mapActions("hospital",["addUpdateInventoryEquipmentMaintenance", "getInventoryItem"]),
    getInventoryItemAPI() {
        let queryParams = {
            name: '',
        };
        this.getInventoryItem(queryParams).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
                this.items = response.data;
            }else{
              this.errorMessage = response.message;
            }
        });
    },

    addUpdateInventoryEquipmentMaintenanceAPI() {
       this.$v.$touch();

       if (!this.item) {
        this.$toasted.error("Please select item", {duration: 2000,});
       } else if(!this.typeform.last_maintenance_date) {
        this.$toasted.error("Please select last maintenance date", {duration: 2000,});
       } else if(!this.status) {
        this.$toasted.error("Please select status", {duration: 2000,});
       } else if (!this.$v.typeform.$invalid) {
           var bodyFormData = new FormData();
           bodyFormData.append('item_id', this.item.id)
           bodyFormData.append('last_maintenance_date', moment(String(this.typeform.last_maintenance_date)).format('YYYY-MM-DD'))
           if (this.typeform.next_maintenance_due){
            bodyFormData.append('next_maintenance_due', moment(String(this.typeform.next_maintenance_due)).format('YYYY-MM-DD'))
           }
           bodyFormData.append('status', this.status.id)
           bodyFormData.append('maintenance_notes', this.typeform.maintenance_notes)
           if (this.isForUpdate()) {
                bodyFormData.append('equipment_maintenance_id', this.data.id)
           }
           this.is_btn_spinner = true
           this.addUpdateInventoryEquipmentMaintenance(bodyFormData).then((response) => {
                this.is_btn_spinner = false
                if (response.response_code == 200) {
                    this.emitResult(true);
                } else {
                    this.$toasted.error(response.message, {duration: 2000,});
                }
           });
       }
    },

    emitResult(actionDone) {
      // Emit the result when closing the dialog
      const result = { status: actionDone};
      EventBus.$emit('dialog-mr-result', result);
      this.showDialog = false;
    },
    resetData() {
      // Reset dialog fields
      this.item = '';
      this.status = '';
      this.typeform.last_maintenance_date = '';
      this.typeform.next_maintenance_due = '';
      this.typeform.maintenance_notes = '';
    },
    setPreviousData() {
      if (this.data && this.data != ''){
          this.typeform.last_maintenance_date = new Date(this.data.last_maintenance_date);
          this.typeform.next_maintenance_due = new Date(this.data.next_maintenance_due);
          this.typeform.maintenance_notes = this.data.maintenance_notes;
          this.item = this.data.item;
          this.status = this.getStatusType(this.data.status);
      }
    },
    isForUpdate() {
        if (this.data && this.data != ''){
            return true
        } else {
            return false
        }
    },
    disableDatesAfterMax(date) {
      return date > new Date();
    },
    getStatusType(status_text) {
        for (let i = 0; i < this.status_list.length;i++) {
            if(status_text == this.status_list[i].id) {
                return this.status_list[i]
            }
        }
        return ''
    }
  }
};
</script>


<style>
/* Full-screen overlay */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dialog content with responsive adjustments */
.dialog-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 70vh; /* Ensure the dialog doesn't exceed viewport height */
  overflow-y: auto;  /* Scroll content if height exceeds viewport */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

/* Media queries for larger screens */
@media (min-width: 576px) {
  .dialog-content {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .dialog-content {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .dialog-content {
    width: 30%;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
