const baseUrl = window.location.protocol + "//" + window.location.hostname + window.location.pathname;
export default {
    appurl: baseUrl,
    base_folder: +window.location.pathname,
//	api_url: 'http://127.0.0.1:8000/api/v1/',
    api_url: 'https://api.softpital.in/api/v1/',
//    api_url: 'https://hethospital.pythonanywhere.com/api/v1/',
}

//Strip Price and Size
//Time ZOne