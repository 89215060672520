<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">

        <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="row">
                  <h4 class="page-header page-title  mb-md-0">Inventories</h4>
              </div>
              <div class="row">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb p-0">
                      <li class="breadcrumb-item"><router-link to="/inventory">Inventories</router-link></li>
                      <li class="breadcrumb-item"><router-link to="/purchase-orders">Purchase Orders</router-link></li>
                      <li class="breadcrumb-item active" aria-current="page" v-if="this.$route.params.id == null || this.$route.params.id == ''">New Purchase Order</li>
                      <li class="breadcrumb-item active" aria-current="page" v-else>{{this.$route.params.id}}</li>
                    </ol>
                  </nav>
              </div>
            </div>
        </div>


      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Purchase Order Details</h4>
              <form class="form-sample">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Order Date</label>
                        <div class="form-control">
                            <DatePicker class="date-picker"
                                v-model="$v.typeform.order_date.$model"
                                format="DD-MM-YYYY"
                                placeholder="Order Date"/>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-md-3">
                    <div class="form-group row">                      
                      <div class="col-sm-12">
                        <label class="col-form-label">Order No</label>
                        <input type="text" class="form-control" v-model="$v.typeform.order_number.$model" :class="{ 'is-invalid':  $v.typeform.order_number.$error  }"  placeholder="Order No."/>
                        <div v-if="$v.typeform.order_number.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.order_number.required">Please enter purchase order number</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Supplier</label>
                        <multiselect v-model="$v.typeform.supplier.$model" :options="suppliers"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Supplier" label="name"
                                     :preselect-first="false" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.supplier.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.supplier.required">Please select supplier</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Total Cost</label>
                        <input type="number" class="form-control" v-model="$v.typeform.total_cost.$model" :class="{ 'is-invalid':  $v.typeform.total_cost.$error  }"  placeholder="Total Cost"/>
                        <div v-if="$v.typeform.total_cost.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.total_cost.required">Please enter total cost</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Status</label>
                        <multiselect v-model="$v.typeform.status.$model" :options="status_list"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Status" label="name"
                                     :preselect-first="false" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.status.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.status.required">Please select status</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </br>
                <div class="row center">
                    <h4 class="card-title col-3">Items</h4>
                    <button type="button" class="col-2 btn btn-outline-secondary btn-icon-text" @click="openPurchaseOrderDialog()"> Add Item </button>
                </div>
                </br>

                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Item </th>
                                <th>Qty.</th>
                                <th>Unit Price (<span v-html="currency_data.currency_symbol"></span>)</th>
                                <th>Total Price</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(item,i) in items" :key="i">
                                <td>{{item.item.name}}</td>
                                <td>{{item.quantity_ordered}}</td>
                                <td><span v-html="currency_data.currency_symbol"></span> {{item.unit_price}}</td>
                                <td><span v-html="currency_data.currency_symbol"></span> {{item.total_price}}</td>
                                <td>
                                    <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon" @click="openPurchaseOrderDialog(item, i)"><i class="mdi mdi-pencil"></i></button>
                                    <button type="button" class="btn btn-outline-secondary btn-rounded btn-icon" @click="deletePurchaseOrderItem(i)" ><i class="mdi mdi-delete"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <b-spinner v-if="is_btn_spinner" label=""></b-spinner>
                    <button v-if="!is_btn_spinner && this.$route.params.id" type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">Edit Item</button>
                    <button v-if="!is_btn_spinner && !this.$route.params.id" type="button" @click="submitForm($route.params.id)" class="btn btn-gradient-primary me-2">Add Item</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
    <PurchaseOrderComponent/>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment";
import PurchaseOrderComponent from './components/component_purchase_order.vue';
import { EventBus } from '@/event-bus';
import {get_user_settings } from '../../helper/helper';

export default {
    name:'ADDItem',
    title: string.PAGE_TITLE_ITEM_DETAILS,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      DatePicker,
      PurchaseOrderComponent,
    },
    data() {
    return {
      moment,
      status_list :[{"id":"pending", "name":"Pending"},{"id":"ordered", "name":"Ordered"},{"id":"received", "name":"Received"},{"id":"cancelled", "name":"Cancelled"}],
      suppliers: [],
      items: [],
      currency_data: '',
      typeform:{
        order_number:'',
        supplier:'',
        total_cost:'',
        status:'',
        order_date:'',
      },
      is_btn_spinner:false
    }
    },
    validations: {
    typeform: {
      order_number:{required},
      supplier:{required},
      total_cost:{required},
      status:{},
      order_date:{},
    },
  },
    created() {
        // Listen for the dialog result
        EventBus.$on('dialog-purchase-order-result', (result) => {
            this.dialogResult = result;
            if(this.dialogResult.status) {
                if (this.dialogResult.index >= 0) {
                    this.items.splice(this.dialogResult.index, 1, this.dialogResult.data);
                } else {
                    this.items.push(this.dialogResult.data)
                }
            }
        });
    },
  mounted(){    
    if(this.$route.params.id){
      this.getPurchaseOrderDetails()
    }
    this.getInventorySupplierAPI()
    this.currency_data = get_user_settings()
  },
  methods:{    
    ...mapActions("hospital",["addUpdateInventoryPurchaseOrder","getInventoryPurchaseOrder", "getInventorySupplier"]),

    submitForm() {
      this.$v.$touch();

      if (!this.typeform.supplier) {
        this.$toasted.error("Please select supplier", {duration: 2000,});
        return
      } else if (this.items.length <=0) {
        this.$toasted.error("Please select purchase items", {duration: 2000,});
        return
      }

      let req_items = []
      this.items.forEach((obj) => {
        req_items.push(
            {"id":obj.id,
                         "item_id":obj.item.id,
                         "quantity":"" + obj.quantity_ordered,
                         "price":obj.unit_price}
        )
      })

      console.log(req_items)

      if (!this.$v.typeform.$invalid) {      
        this.is_btn_spinner = true;
        var bodyFormData = new FormData();
        bodyFormData.append('order_number', this.typeform.order_number);
        bodyFormData.append('supplier_id', this.typeform.supplier.id);
        bodyFormData.append('total_cost', this.typeform.total_cost);
        bodyFormData.append('purchase_order_items', JSON.stringify(req_items));
        if (this.typeform.status) {
            bodyFormData.append('status', this.typeform.status.id);
        }
        if (this.typeform.order_date) {
            bodyFormData.append('order_date', moment(this.typeform.order_date).format('YYYY-MM-DD'));
        }
        if(this.$route.params.id){
            bodyFormData.append('purchase_order_id', this.$route.params.id);
        }
        this.addUpdateInventoryPurchaseOrder(bodyFormData).then((response) => {
            this.is_btn_spinner = false;
            if (response.response_code == 200) {
                this.$toasted.success("City added successfully", {duration: 2000,});
                this.$router.push({ name: 'purchase-orders' });
            } else{
                this.$toasted.error(response.message, {duration: 2000,});
            }
        });
      }
    },

    getPurchaseOrderDetails() {
      let queryParams = {
          name: '',
          page: 1,
          purchase_order_id : this.$route.params.id
      };
      this.getInventoryPurchaseOrder(queryParams).then((response) => {
        if (response.response_code == 200) {          
            this.typeform = {
                order_number:response.data.order_number,
                supplier:response.data.supplier,
                total_cost:response.data.total_cost,
                status: this.getStatus(response.data.status)
            }
            this.items = response.data.items
            if (response.data.order_date) {
                this.typeform.order_date = new Date(response.data.order_date)
            }

        } else {
            this.$router.push({ name: 'inventory' });
        }
      });
    },

    getInventorySupplierAPI(){
        this.is_API_call_running = true;
        this.suppliers = [];
        let queryParams = {
            name: '',
        };
        this.getInventorySupplier(queryParams).then((response) => {
            if (response.response_code == 200) {
                this.suppliers = response.data;
            }else{
              this.errorMessage = response.message;
            }
        });
    },
    getStatus(text) {
        let status_text = ''
        for (let i = 0; i < this.status_list.length;i++) {
            if(text == this.status_list[i].id) {
                status_text = this.status_list[i]
                break
            }
        }
        return status_text
    },
    openPurchaseOrderDialog(item, index) {
        const payload = { data: item, index: index};
        EventBus.$emit('open-purchase-order-dialog', payload); // Trigger the dialog opening
    },
    deletePurchaseOrderItem(index) {
        this.items.splice(index, 1);
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>