<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row align-items-center">
        <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
              <div class="page-header">
                <h4 class="page-title">Users/Admin/Doctor/Compounder List</h4>
              </div>
          </div>
          <div class="col-lg-5 ms-auto">
              <div class="d-md-flex d-grid gap-3 justify-content-md-end">
                <router-link to="/adddoctor" type="button" class="btn btn-gradient-primary btn-fw">Add New</router-link>
              </div>
          </div>
      </div>
      </br>

      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body nw-opd">
                 <div class="row">
                    <div class="col-lg-4 mb-lg-0 mb-2">
                        <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchdoctor" id="searchdoctor" autocomplete="off" v-on:keyup="getDoctorList(1)">
                    </div>
                        <div class="col-lg-1"/>
                        <div class="col-lg-2"/>
                            <date-range-picker
                                class="col-lg-3 mb-lg-0 mb-2"
                                ref="picker"
                                :max-date="MAXDATE"
                                :opens="center"
                                :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                :single-date-picker="false"
                                :showDropdowns="showDropdowns"
                                :show-week-numbers= "false"
                                :time-picker="false"
                                :autoApply="true"
                                v-model="dateRangeRegistration"
                                :linked-calendars="true"
                                :dateFormat="dd-mmm-yyyy"
                                @finish-selection=getDoctorList(1)
                                @update=getDoctorList(1)
                            />
                            <div class="col-lg-2">
                                <multiselect
                                    v-model="filter_status"
                                    :options="status_list"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true" placeholder=""
                                    :preselect-first="true"
                                    :showLabels="false"
                                    :allowEmpty="false"
                                    @input=getDoctorList(1)
                                    /></multiselect>
                            </div>
                      </div>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Users/Admin/<br/>Doctor/Compounder Name</th>
                        <th>Mobile Number</th>
                        <th>Email Address</th>
                        <th>Username</th>
                        <th>Department</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(doctor,i) in doctors" :key="i">
                        <td>{{doctor.id}}</td>
                        <td>{{doctor.name}}</td>
                        <td>{{doctor.mobile}}</td>
                        <td>{{doctor.email}}</td>
                        <td>{{doctor.username}}</td>
                        <td>{{ doctor.department.map(depart => depart.department_name).join(', ') }}</td>
                        <td><label v-bind:class="{'badge badge-success': doctor.status === 'Active',
                                                                                  'badge badge-danger': doctor.status === 'InActive' || doctor.status === 'Inactive'}">{{doctor.status}}</label></td>
                        <td><router-link :to="{name: 'editdoctor',params: { id: doctor.id}}" >
                        <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-tooltip-edit"></i></button></router-link> </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && doctors.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getDoctorList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import string from "../constant/strings.js"

export default {
  name:'Doctor',
  title: string.PAGE_TITLE_USER,
  components: {
      NavBar,
Footer,
      SideBar,
     DateRangePicker,
     Multiselect
  },
  data() {
    const now = new Date()
    const startdate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate())
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)

    return {
      doctors:[],
      is_API_call_running:false,
      listPages:0,
      activePage:1,
      searchtext:'',
      myTimer:null,
      errorMessage : "No Data Found",
      dateRangeRegistration:{
        startDate: startdate,
        endDate: today
      },
      status_list:['All','Active', 'Inactive'],
      filter_status: "All",
      MAXDATE:maxDate,
    }
  },
  mounted() {
    this.getDoctorList(this.activePage);
  },
  methods:{
    ...mapActions("hospital",["getDoctorListData"]),

    getDoctorList(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true
        this.doctors = [];
        var bodyFormData = new FormData();
        bodyFormData.append('filter_value', this.searchtext);
        bodyFormData.append('page', page);
        bodyFormData.append('filter_from_date', moment(String(this.dateRangeRegistration.startDate)).format('YYYY-MM-DD'));
        bodyFormData.append('filter_to_date', moment(String(this.dateRangeRegistration.endDate)).format('YYYY-MM-DD'));
        bodyFormData.append('filter_status', this.filter_status);
        this.getDoctorListData(bodyFormData).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
                this.doctors = response.data;
                this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
        });
      }, 500)
    },
    previewClick(){
          this.activePage--;
          if(this.activePage >= 1){
            this.getDoctorList(this.activePage);
          }else{
            this.activePage = 1;
          }
        },

    nextClick(){
       this.activePage++;
       if(this.activePage <= this.listPages){
            this.getDoctorList(this.activePage);
       }else{
            this.activePage = this.listPages;
       }
    },
  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>