<template>
  <div v-if="showDialog" class="dialog">
    <div class="dialog-content">
      <h4 v-if="isSupplierForUpdate()" class="text-center">Update Supplier</h4>
      <h4 v-else class="text-center">Add Supplier</h4>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Supplier Name</label>
            <input type="text" class="form-control" v-model="$v.typeform.name.$model" :class="{ 'is-invalid':  $v.typeform.name.$error  }" name="supplier_name" placeholder="Supplier Name"/>
            <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.name.required">Please enter supplier name</span>
            </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Contact Person</label>
            <input type="text" class="form-control" v-model="$v.typeform.contact_name.$model" :class="{ 'is-invalid':  $v.typeform.contact_name.$error  }" name="contact_person" placeholder="Contact Person"/>
            <div v-if="$v.typeform.contact_name.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.contact_name.required">Please enter contact person name</span>
            </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Contact Person Phone</label>
            <input type="phone" class="form-control" v-model="$v.typeform.contact_phone.$model" :class="{ 'is-invalid':  $v.typeform.contact_phone.$error  }" name="contact_phone" placeholder="Contact Person Phone"/>
            <div v-if="$v.typeform.contact_phone.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.contact_phone.required">Please enter contact person phone</span>
            </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Contact Person email</label>
            <input type="email" class="form-control" v-model="$v.typeform.contact_email.$model" :class="{ 'is-invalid':  $v.typeform.contact_email.$error  }" name="contact_email" placeholder="Contact Person Email"/>
            <div v-if="$v.typeform.contact_email.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.contact_email.required">Please enter contact person email</span>
            </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Address</label>
            <textarea rows="5" class="form-control" v-model="$v.typeform.address.$model" :class="{ 'is-invalid':  $v.typeform.address.$error  }" name="address" placeholder="Address"/>
            <div v-if="$v.typeform.address.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.address.required">Please enter notes</span>
            </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mt-4 mb-4">
          <button class="btn btn-outline-secondary btn-fw w-100" @click="emitResult(false)">Close</button>
        </div>
        <div class="col-6 mt-4 mb-4">
          <b-button v-if="is_btn_spinner" variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>Loading...
          </b-button>
          <div v-else>
            <button v-if="isSupplierForUpdate()" class="btn btn-gradient-primary btn-fw w-100" @click="addUpdateInventorySupplierAPI()">Update Supplier</button>
            <button v-else class="btn btn-gradient-primary btn-fw w-100" @click="addUpdateInventorySupplierAPI()">Add Supplier</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";

export default {
  data() {
    return {
      showDialog: false,
      is_btn_spinner: false,
      data: null,
      typeform:{
        name: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        address: '',
      }
    };
  },
  validations: {
      typeform: {
        name:{required},
        contact_name:{required},
        contact_phone:{required},
        contact_email:{required},
        address:{required},
      }
  },
  created() {
    // Listen for the event to open the dialog
    EventBus.$on('open-supplier-dialog', (payload) => {
      this.data = payload.data;
      this.showDialog = true;
      this.resetData()
      this.setPreviousData()
    });
  },
  methods: {
    ...mapActions("hospital",["addUpdateInventorySupplier"]),

    addUpdateInventorySupplierAPI() {
       this.$v.$touch();
       if (!this.$v.typeform.$invalid) {

           var bodyFormData = new FormData();
           bodyFormData.append('name', this.typeform.name)
           bodyFormData.append('contact_name', this.typeform.contact_name)
           bodyFormData.append('contact_phone', this.typeform.contact_phone)
           bodyFormData.append('contact_email', this.typeform.contact_email)
           bodyFormData.append('address', this.typeform.address)
           bodyFormData.append('description', this.typeform.description)
           if (this.isSupplierForUpdate()) {
                bodyFormData.append('supplier_id', this.data.id)
           }
           this.is_btn_spinner = true
           this.addUpdateInventorySupplier(bodyFormData).then((response) => {
                this.is_btn_spinner = false
                if (response.response_code == 200) {
                    this.emitResult(true);
                } else {
                    this.$toasted.error(response.message, {duration: 2000,});
                }
           });
       }
    },

    emitResult(actionDone) {
      // Emit the result when closing the dialog
      const result = { status: actionDone};
      EventBus.$emit('supplier-dialog-result', result);
      this.showDialog = false;
    },
    resetData() {
      // Reset dialog fields
      this.typeform.name = '';
      this.typeform.contact_name = '';
      this.typeform.contact_phone = '';
      this.typeform.contact_email = '';
      this.typeform.address = '';
    },
    setPreviousData() {
      if (this.data && this.data != ''){
          this.typeform.name = this.data.name;
          this.typeform.contact_name = this.data.contact_name;
          this.typeform.contact_phone = this.data.contact_phone;
          this.typeform.contact_email = this.data.contact_email;
          this.typeform.address = this.data.address;
      }
    },
    isSupplierForUpdate() {
        if (this.data && this.data != ''){
            return true
        } else {
            return false
        }
    }
  }
};
</script>

<style>
/* Full-screen overlay */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dialog content with responsive adjustments */
.dialog-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 70vh; /* Ensure the dialog doesn't exceed viewport height */
  overflow-y: auto;  /* Scroll content if height exceeds viewport */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

/* Media queries for larger screens */
@media (min-width: 576px) {
  .dialog-content {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .dialog-content {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .dialog-content {
    width: 30%;
  }
}
</style>
