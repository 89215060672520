<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
    <div class="page-header">
      <h4 class="page-title">Changelogs</h4>
    </div>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">                    
                <div class="row">
                    <div class="col-lg-9"></div>
                    <date-range-picker
                        class="col-lg-3  align-self-end mb-lg-0 mb-2"
                        ref="picker"
                        :opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                        :single-date-picker="false"
                        :showDropdowns="showDropdowns"
                        :show-week-numbers= "false"
                        :time-picker="false"
                        :autoApply="true"
                        v-model="dateRange"
                        :linked-calendars="true"
                        :dateFormat="dd-mmm-yyyy"
                        @update=getChangelog(1)
                    />
                </div>

              <br>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Log Id</th>
                        <th>Change By</th>
                        <th>Change Note</th>
                        <th>Change Date & Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(changelog,i) in changelogs" :key="i">
                        <td>{{changelog.log_id}}</td>
                        <td>{{changelog.log_by_user}}</td>
                        <td>{{changelog.log_changes}}</td>
                        <td>{{changelog.log_date | formatDate}}</td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && changelogs.length == 0" >
                 <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/>
                </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                  <ul id="border-pagination">
                    <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                    <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getChangelog(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                    <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions } from "vuex";
import moment from "moment";
import string from "../constant/strings.js"
import DateRangePicker from 'vue2-daterange-picker'

export default {
  name:'changelog',
  title: string.PAGE_TITLE_CHANGELOG,
  components: {
      NavBar,
      SideBar,
      Footer,
      DateRangePicker,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  	const maxDate = new Date(today)

    return {
      changelogs:[],
      moment: moment,
      is_API_call_running: false,
      listPages:0,
      activePage:1,
      errorMessage:'No Data Found',
      dateRange:{
        startDate: today,
        endDate: today
      },
      MAXDATE:maxDate,
    }
  },
  mounted() {
    this.getChangelog(this.activePage);
  },
  methods:{
    ...mapActions("hospital",["getChangelogListData"]),

    getChangelog(page){
        var bodyFormData = new FormData();
        this.changelogs = [];
        this.activePage = page;
        this.is_API_call_running = true;
        bodyFormData.append('page', page);
        bodyFormData.append('filter_from_date', moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'));
        bodyFormData.append('filter_to_date', moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'));
        this.getChangelogListData(bodyFormData).then((response) => {
            this.is_API_call_running = false;
            if (response.response_code == 200) {
              this.changelogs = response.data;
              this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
          });
    },

    previewClick(){
      this.activePage--;
      if(this.activePage >= 1){
        this.getChangelog(this.activePage);
      }else{
        this.activePage = 1;
      }
    },

    nextClick(){
      this.activePage++;
      if(this.activePage <= this.listPages){
        this.getChangelog(this.activePage);
      }else{
        this.activePage = this.listPages;
      }
    },
  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
